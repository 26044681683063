export function receivedItems(state, {items, totalItems}) {
  state.items = items;
  if(typeof state?.items !== 'undefined') {
    state.items.forEach((value, key) => {
      state.items[key]['disableListItem'] = null; //init key
    });
  }
  state.totalItems = totalItems;
  state.isLoading = false;
  state.isRefreshing = false;
}

export function changePage(state, nextPage) {
  state.items = [];
  state.isLoading = true;
  state.totalItems = 0;
  state.currentPage = nextPage;
}

export function updatePage(state) {
  state.isRefreshing = true;
}

export function updatePageDone(state) {
  state.isRefreshing = false;
}

export function changeItemsPerPage(state, itemsPerPage) {
  state.itemsPerPage = itemsPerPage;
  state.isLoading = true;
}

export function updatePageRequest(state, page) {
  state.currentPage = page;
}

export function setFlowCardDisabled(state, {flowId, disableState}) {
  if(typeof state?.items !== 'undefined') {
    state.items.forEach((value, key) => {
      if (value.id === flowId) {
        state.items[key]['disableListItem'] = disableState;
      }
    });
  }
  state.isRefreshing = disableState; //When disabling => refreshing - When (re)enabling => refresh done
}

export function activateFlowCheck(state) {
  state.runningFlow = true;
}

export function deactivateFlowCheck(state) {
  state.runningFlow = false;
}

export function createdFlow(state) {
  state.isLoading = true;
}

export function editedFlow(state) {
  state.isLoading = true;
}

export function deletedFlow(state, flowObj) {
  state.isLoading = true;
  if(typeof state.items !== 'undefined') {
    state.items.forEach((elem, index) => {
      if (elem['@id'] === flowObj['@id']) {
        state.items.splice(index, 1);
      }
    });
  }
}

export function receivedExport(state, flows) {
  state.exportFlow = flows;
  state.isLoading = false;
}

export function clearExport(state) {
  state.exportFlow = null;
}
