<script>
import VueApexCharts from 'vue3-apexcharts'
import CustomChartLegend from "components/Charts/Legend/CustomChartLegend";

export default {
  name: "FlowSeveritiesChart",

  components: {
    VueApexCharts,
    CustomChartLegend
  },
  data() {
    return {
      legendData: [],
      loading: false,
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false
        },
        colors: ['#3363FF', '#332EE8', '#7940FF', '#992EE8', '#DF33FF'],
      },

    }
  },
  created() {
    this.getFlowSeveritiesGroup()
  },

  methods: {
    getFlowSeveritiesGroup() {
      this.loading = true;
      this.$api.validationInfo.getFlowSeveritiesGroup()
        .then(res => {
          if (res.data) {
            const flowSeveritiesGroupData = res.data['hydra:member']
            this.series = []
            this.legendData = [];

            let seriesArray = [];
            flowSeveritiesGroupData.forEach(data => {

              for (const [key, value] of Object.entries(data)) {
                if (key !== 'flowName') {
                  (seriesArray[key] ??= []).push(value);
                }
              }

              this.options.xaxis.categories.push(data['flowName']);
            })

            let counter = 0;
            for (const [key, value] of Object.entries(seriesArray)) {
              this.series.push({name: key, data: value})
              this.legendData.push({color: this.options.colors[counter], description: key})
              counter++;
            }

            this.$refs.flowChart.updateSeries(this.series)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(_ => {
          this.loading = false
        })
    }
  }
}
</script>


<template>
  <div class="q-pa-md flow-severities-chart">

    <div class="column items-center">
      <p class="chart-header">Flow Severities Group</p>
    </div>

    <div class="row justify-center">
      <div class="col-xl-8 self-center">
        <vue-apex-charts
          ref="flowChart"
          type="bar"
          :options="options"
          :series="series"
          class="q-mb-lg validation-graph"
        />
      </div>

      <div class="col-xl-2 self-center">
        <custom-chart-legend :legend-data="legendData" />
        <q-inner-loading
          :showing="loading"
          color="primary"
        >
          <q-spinner
            color="primary"
            size="42px"
          />
          <span class="text-primary text-weight-medium">Loading data...</span>
        </q-inner-loading>
      </div>

    </div>

    <div class="absolute-center" v-if="legendData.length === 0 && !loading">
      <span class="text-primary text-weight-medium">No Data</span>
    </div>
  </div>
</template>

<style lang="scss" >
  .flow-severities-chart {
    min-height: 450px;
  }
</style>
