import { render } from "./FailedMessages.vue?vue&type=template&id=07ba5c5e&scoped=true"
import script from "./FailedMessages.vue?vue&type=script&lang=js"
export * from "./FailedMessages.vue?vue&type=script&lang=js"

import "./FailedMessages.vue?vue&type=style&index=0&id=07ba5c5e&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-07ba5c5e"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInput,QIcon,QCard,QCardSection,QTable,QTd});
