<script>
  import { ucwords } from 'src/utils/stringUtils'
  import VueApexCharts from 'vue3-apexcharts'
  import CustomChartLegend from "components/Charts/Legend/CustomChartLegend";

  export default {
    name: "SeverityLevelsChart",

    components: {
      VueApexCharts,
      CustomChartLegend
    },

    data() {
      return {
        legendData: [],
        loading: false,
        options: {
          chart: {
            type: 'pie',
            height: 350
          },
          legend: {
            show: false
          },
          labels: [],
          colors: ['#3363FF', '#332EE8', '#7940FF', '#992EE8', '#DF33FF'],
        },
        series: []
      }
    },

    created() {
      this.getSeverityLevels()
    },

    methods: {
      getSeverityLevels() {
        this.loading = true;
        this.$api.validationInfo.getSeverityGroup()
          .then(res => {
            if (res.data) {
              const severityGroupData = res.data['hydra:member']
              this.series = []
              this.options.labels = []

              severityGroupData.forEach((data, index) => {
                this.options.labels.push(ucwords(data['severity']))
                this.series.push(data['count'])
                this.legendData.push({color: this.options.colors[index], description: data['severity']})
              })

              this.$refs.severityLevelChart.updateOptions(this.options)
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(_ => {
            this.loading = false
          })
      }
    }
  }
</script>

<template>
  <div class="q-pa-md severity-chart">

    <div class="column items-center">
      <p class="chart-header">Severity Levels</p>
    </div>

    <div class="row justify-center">
      <div class="col-xl-6 self-center">
        <vue-apex-charts
          ref="severityLevelChart"
          width="350px"
          type="pie"
          :options="options"
          :series="series"
          class="q-mb-lg"
        />
      </div>

      <div class="col-xl-2 self-center">
        <custom-chart-legend :legend-data="legendData" />
        <q-inner-loading
          :showing="loading"
          color="primary"
        >
          <q-spinner
            color="primary"
            size="42px"
          />
          <span class="text-primary text-weight-medium">Loading data...</span>
        </q-inner-loading>
      </div>
    </div>

    <div class="absolute-center" v-if="legendData.length === 0 && !loading">
      <span class="text-primary text-weight-medium">No Data</span>
    </div>
  </div>
</template>

<style lang="scss">
  .severity-chart {
    min-height: 450px;
  }
</style>
