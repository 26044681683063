<script>
  import { defineComponent } from 'vue'
  import { useQuasar } from 'quasar'

  export default defineComponent({
    name: 'Alert',
    props: {
      message: {
        /* Allows both, an i18n key or a direct message to be passed and displayed
        It is recommended to use i18n keys, for obvious reasons
        Fixed messages will only be passed by API - an error code for example

        i18n keys are passed as string.
        Example:
        "cronjob.cronjobAlreadyExists"
        (including the quotes)
         */
        required: true
      },
      icon: {},
      color: {
        default: 'negative'
      },
      itemUrl: {}
    },
    setup () {
      const $q = useQuasar();
      return {
        showNotif (position) {
          // This component either receives an already translated string, or an i18n key.
          // It needs to be able to handle both. Translating an already translated value throws a warning, though.
          // Below try-catch allows to handle both cases without having to pass custom prop values,
          // thus maintaining max. maintainability with min. effort.
          try {
            // Translating i18n key
            this.showAlert(this.$t(this.message), position);
          }
          catch(e) {
            // i18n key was already translated. Simply show the string
            this.showAlert(this.message, position);
          }
          this.$store.dispatch('alert/clear');
        }
      }
    },
    methods: {
      goToDetail (itemUrl) {
        this.$router.push(itemUrl)
      },
      showAlert(msg, position) {
        if (this.itemUrl) {
          this.$q.notify({
            group: false,
            message: msg,
            color: this.color,
            icon: this.icon,
            position,
            timeout: Math.random() * 3000 + 3000,
            actions: [
              { label: 'view', color: 'yellow', handler: () => { this.goToDetail(this.itemUrl) } },
            ]
          })
        } else {
          this.$q.notify({
            message: msg,
            color: this.color,
            icon: this.icon,
            position,
            timeout: Math.random() * 3000 + 3000
          })
        }
      }
    },
    mounted() {
      this.showNotif('top-right');
    }
  })
</script>

<template>
</template>

<style lang="scss">
  .q-notifications__list {
    margin-top: 3rem;
  }
</style>
