import { render } from "./FileManager.vue?vue&type=template&id=50c7e152"
import script from "./FileManager.vue?vue&type=script&lang=js"
export * from "./FileManager.vue?vue&type=script&lang=js"

import "./FileManager.vue?vue&type=style&index=0&id=50c7e152&lang=scss"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QBadge,QInput,QList,QItem,QItemSection,QIcon,QTree});qInstall(script, 'directives', {Ripple});
