import { render } from "./FlowItemDetails.vue?vue&type=template&id=798a846c"
import script from "./FlowItemDetails.vue?vue&type=script&lang=js"
export * from "./FlowItemDetails.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon});
