<script>
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'

  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import CreateFilesystemModal from './modal/CreateFilesystemModal'
  import EditFilesystemModal from './modal/EditFilesystemModal'
  import DeleteFilesystemModal from './modal/DeleteFilesystemModal'

  export default defineComponent({
    name: 'FileSystem',

    emits: ["path"],

    components: {
      LoadingIndicator,
      EditFilesystemModal,
      DeleteFilesystemModal,
      CreateFilesystemModal
    },

    data () {
      return {
        showCreateFilesystemModal: false,
        showEditFilesystemModal: false,
        selectedFilesystem: null,
        showDeleteFileSystemModal: false,
        // TODO, cs, 02.02.2022 - Prepare multi file handling
        path: typeof this.$route.params.path !== "undefined" ? "/" + this.$route.params.path: "/",
        selectedFile: null,
        selectedFileIndex: null
      }
    },
    computed: {
      ...mapGetters('fileSystem', [
        'initialized',
        'isLoading',
        'isRefreshing',
        'filesystems'
      ])
    },
    watch: {
      initialized: function () {
        if(this.initialized && typeof this.$route.params.path !== "undefined") {
          this.updateFilesystem(this.path);
        }
      }
    },
    mounted() {
      this.$store.dispatch('fileSystem/fileSystemLoad');
    },
    methods: {
      updateFilesystem(filesystem) {
        this.selectedFile = null;
        this.selectedFileIndex = null;
        this.$store.dispatch('fileManager/filemanagerLoad', filesystem);
        this.path = filesystem;
        this.$emit('path', filesystem)
      },

      openEditFilesystemModal(filesystem) {
        this.showEditFilesystemModal = true
        this.selectedFilesystem = filesystem
      },

      handleUpdatedFilesystem() {
        this.showEditFilesystemModal = false
        this.selectedFilesystem = null
      },

      openDeleteFilesystemModal(filesystem) {
        this.showDeleteFileSystemModal = true
        this.selectedFilesystem = filesystem
      },

      handleDeleted() {
        this.showDeleteFileSystemModal = false
        this.selectedFilesystem = null
      },

      editFilesystem(filesystem) {
        this.$store.dispatch('fileSystem/editFilesystem', filesystem);
      },
      deleteFilesystem(filesystem) {
        this.$store.dispatch('fileSystem/deleteFilesystem', filesystem);
      }
    }
  })
</script>

<template>
  <loading-indicator v-if="isLoading" wrapper />
  <div class="q-pt-md q-gutter-sm" v-else>
    <q-btn
      flat
      dense
      icon-right="add"
      :label="$t('browser.addFilesystem')"
      :title="$t('browser.addFilesystem')"
      :disabled="isRefreshing"
      data-cy="addFilesystemBtn"
      class="app-action-btn q-px-sm q-mr-sm justify-end"
      @click.capture.stop="showCreateFilesystemModal = true"
    />
    <q-tree
      class="app-browser-filetree"
      :nodes="filesystems"
      node-key="label"
      default-expand-all
    >
      <template v-slot:default-header="prop">
        <div class="app-browser-filesystem-item-wrapper full-width flex justify-end items-center"
             :class="{ 'active': prop.node.node['@id'].replace('api/filesystems/', '') === '/' + path.split('/')[1] }"
             @click="updateFilesystem(prop.node.node['@id'].replace('api/filesystems/', ''))"
        >
          <div class="app-browser-filesystem-item flex col-grow">{{ prop.node.node.identifier }}</div>
          <div>
            <q-btn flat dense
                   class="q-pa-md q-pa-sm-sm q-pa-md-xs"
                   icon-right="settings"
                   :title="$t('browser.editFilesystem')"
                   @click.capture.stop="openEditFilesystemModal(prop.node.node)" :disable="isRefreshing"
                   v-bind:data-cy="'filesystemConfigure' + prop.node.node.identifier"
            />
            <q-btn flat dense
                   class="q-pa-sm q-pa-sm-sm q-pa-md-xs"
                   icon-right="delete"
                   :title="$t('browser.deleteFilesystem')"
                   @click.capture.stop="openDeleteFilesystemModal(prop.node.node['@id'])"
                   :disable="isRefreshing"
                   v-bind:data-cy="'filesystemDelete' + prop.node.node.identifier"
            />
          </div>
        </div>
      </template>
    </q-tree>
  </div>

  <create-filesystem-modal
    v-model="showCreateFilesystemModal"
    @created="showCreateFilesystemModal = false"
  />

  <edit-filesystem-modal
    v-model="showEditFilesystemModal"
    :filesystem="selectedFilesystem"
    @updated="handleUpdatedFilesystem"
  />

  <delete-filesystem-modal
    v-model="showDeleteFileSystemModal"
    :filesystem="selectedFilesystem"
    @deleted="handleDeleted"
  />
</template>

<style lang="scss">
  .app-browser-filesystem-item-wrapper {
    cursor: pointer;
    transition: .33s;
    .app-browser-filesystem-item {
      padding: .25rem .5rem;
    }
  }
  .q-tree.app-browser-filetree > .q-tree__node--child {
    > .q-tree__node-header {
      padding-left: unset;
    }
  }

  body.body--light {
    .app-browser-filesystem-item-wrapper {
      &.active {
        background: mix($dark, $background, 10%);
      }
      &:hover {
        background: mix($dark, $background, 15%);
      }
    }
  }
  body.body--dark {
    .app-browser-filesystem-item-wrapper {
      &.active {
        background: mix($light, $dark-page, 10%);
      }
      &:hover {
        background: mix($light, $dark-page, 15%);
      }
    }
  }
</style>
