import { flowService } from 'src/services'

export function pageLoad({ dispatch, commit, state }, {pageNumber, itemsPerPage}) {
  commit('changePage', pageNumber);
  commit('changeItemsPerPage', itemsPerPage);
  // Axios API Request
  flowService.getFlows(state.currentPage, itemsPerPage, function(data) {
    if(data.status === 401) dispatch('authentication/expired', data, {root:true});
    else {
      if(data?.['hydra:member']?.length === 0 && data?.['hydra:totalItems'] > 0) {
        // NO DATA ON THIS PAGE ANYMORE. GET THE LAST AVAILABLE ONE
        // This case can happen when being on one of the last pages, and decide to show more items per page
        let lastAvailablePage = Math.ceil(data?.['hydra:totalItems'] / itemsPerPage);
        if(lastAvailablePage < 1) lastAvailablePage = 1;
        dispatch('pageLoad', {pageNumber: lastAvailablePage, itemsPerPage: itemsPerPage });
        commit('updatePageRequest', lastAvailablePage);
      } else {
        commit('receivedItems', {
          items: data['hydra:member'],
          totalItems: data['hydra:totalItems']
        })
      }
    }
  })
}

export function pageUpdate({ dispatch, commit, state }) {
  commit('updatePage');
  // Axios API Request
  flowService.getFlows(state.currentPage, state.itemsPerPage, function(data) {
    let flowRunning = false;
    if(data?.['hydra:member']) {
      data?.['hydra:member'].forEach(flow => {
        if(flow.isRunning === true) flowRunning = true;
      });
      if(flowRunning === false) commit('deactivateFlowCheck');
      commit('receivedItems', {
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems']
      })
    }
  })
}

export function startFlow({dispatch, commit, state}, flowId) {
  commit('activateFlowCheck');
  commit('setFlowCardDisabled', {flowId: flowId, disable: true});
  flowService.startFlow(flowId, function(data) {
    if(data.success !== 0) {
      dispatch('alert/success', "flow.flowStartedSuccess", { root: true });
      commit('activateFlowCheck');
      commit('setFlowCardDisabled', {flowId: flowId, disable: false});
      dispatch('pageUpdate')
    } else {
      commit('setFlowCardDisabled', {flowId: flowId, disable: false});
      dispatch('alert/error', data.msg, { root: true });
    }
  })
}

export function createdFlow({dispatch, commit, state}) {
  commit('createdFlow');
}

export function editedFlow({dispatch, commit, state}) {
  commit('editedFlow');
}

export function deletedFlow({dispatch, commit, state}, flowObj) {
  commit('deletedFlow', flowObj);
}

export function exportFlows({dispatch, commit, state}, flowId) {
  flowService.exportFlow(flowId, function(data) {
    if(data['@type'] === "hydra:Error") {
      this.$router.push({
        name: 'ERROR',
        params: { n: true }
      })
    } else {
      commit('receivedExport', data)
    }
  })
}

export function clearExport({commit}) {
  commit('clearExport')
}
