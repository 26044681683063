import { render } from "./FlowItem.vue?vue&type=template&id=af13c004"
import script from "./FlowItem.vue?vue&type=script&lang=js"
export * from "./FlowItem.vue?vue&type=script&lang=js"

import "./FlowItem.vue?vue&type=style&index=0&id=af13c004&lang=scss"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QItemSection,QItemLabel,QCardActions,QBtn,QBtnDropdown,QList,QIcon,QSeparator});qInstall(script, 'directives', {ClosePopup});
