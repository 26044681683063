<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'FilterPreset',

    props: {
        snippet: {
          type: String,
          required: true
        },
        label: {
          type: String,
          required: true
        },
        callback: {
          type: Function,
          required: true
        }
    },
    methods: {
      addPreset(p) {
        this.callback(p);
      }
    }
  })
</script>

<template>
  <q-btn 
    :label="this.label"
    @click="this.addPreset(this.snippet)"
    flat
    dense
    class="app-action-btn q-px-sm q-mr-sm q-mb-sm"
  />
</template>
