import { render } from "./CustomChartLegend.vue?vue&type=template&id=38ffc365"
import script from "./CustomChartLegend.vue?vue&type=script&lang=js"
export * from "./CustomChartLegend.vue?vue&type=script&lang=js"

import "./CustomChartLegend.vue?vue&type=style&index=0&id=38ffc365&lang=scss"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTd,QBadge,QTooltip});
