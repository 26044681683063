<script>
import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Logo',
    setup () {
      return {
        src: "~assets/app-teaser.svg"
      }
    },
  })
</script>

<template>
  <div class="app-teaser-col q-pa-md gt-sm q-mx-md">
    <img alt="" src="~assets/app-teaser.svg">
  </div>
</template>
