import { render } from "./EntitySelection.vue?vue&type=template&id=2ebab55c"
import script from "./EntitySelection.vue?vue&type=script&lang=js"
export * from "./EntitySelection.vue?vue&type=script&lang=js"

import "./EntitySelection.vue?vue&type=style&index=0&id=2ebab55c&lang=scss"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QList,QExpansionItem,QItem,QIcon,QSpinner,QTooltip,QCard,QCardSection,QInnerLoading});
