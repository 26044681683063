<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ItemsPerPage',
    props: {
      paginationOptions: {
        type: Array,
        required: false,
        default: [5,10,15,25,50]
      },
      modelValue: {
        type: Number,
        default: 10
      },
      label: {
        type: String,
        default: 'general.itemsPerPage'
      }
    },
    data () {
      return {
        localValue: this.modelValue
      }
    },
    computed: {
      requestedItemsPerPage: {
        get() {
          return this.localValue;
        },
        set(val) {
          this.localValue = val;
          this.$emit('update:modelValue', val);
        }
      }
    }
  })
</script>

<template>
  <div class="q-pt-sm full-width flex justify-end">
    <q-select class="app-items-per-page-select"
              :label="$t(this.label)"
              behavior="menu"
              dense borderless
              v-model.number="requestedItemsPerPage"
              :options="paginationOptions"
    />
  </div>
</template>

<style lang="scss">
.app-items-per-page-select {
  background-color: $background2;
  width: 10rem;
}

body.body--dark {
  .app-items-per-page-select {
    background-color: $dark;
  }
}
</style>
