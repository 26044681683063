import { render } from "./FlowExecutionBadges.vue?vue&type=template&id=0a38744e"
import script from "./FlowExecutionBadges.vue?vue&type=script&lang=js"
export * from "./FlowExecutionBadges.vue?vue&type=script&lang=js"

import "./FlowExecutionBadges.vue?vue&type=style&index=0&id=0a38744e&lang=scss"
script.render = render

export default script
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBadge});
