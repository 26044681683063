import client from '../helpers/authenticated-axios'

export const userManagementService = {
  getUsers(page, itemsPerPage, callback) {
    client().get('/users?page=' + page + '&itemsPerPage=' + itemsPerPage).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
  addUser(formData, callback) {
    client().post('/users', {
      "displayName": formData.name,
      "email": formData.email,
      "roles": formData.roles,
      "password": formData.password,
      shouldEmail: formData.shouldEmail
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },
  updateUser(updatedUser, formData, callback) {
    let fD = {
      "displayName": formData.name,
      "email": formData.email,
      "roles": formData.roles,
      "apiToken": formData.apiToken
    };
    if(formData.password !== "") fD.password = formData.password;
    client().put('/users' + '/' + updatedUser['@id'].split("/").pop(), fD).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },
  deleteUser(deletedUser, callback) {
    client().delete('/users' + '/' + deletedUser['@id'].split("/").pop()).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  }
}
