import { render } from "./DiskSpaceChart.vue?vue&type=template&id=0cf36f12"
import script from "./DiskSpaceChart.vue?vue&type=script&lang=js"
export * from "./DiskSpaceChart.vue?vue&type=script&lang=js"

import "./DiskSpaceChart.vue?vue&type=style&index=0&id=0cf36f12&lang=scss"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QIcon,QItem,QItemSection,QItemLabel,QInnerLoading,QSpinner,QCardSection,QBtn});
