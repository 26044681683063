import { resolveUserLocale, setDarkMode } from 'src/utils'
import { i18n } from 'boot/i18n'
import { isEmpty } from 'lodash'

export function SET_PREFERENCES(state, preferences) {
  // check if we some preferences for this user
  if (!isEmpty(preferences)) {
    state.userPreferences = { ...state.userPreferences, ...preferences };

    i18n.global.locale = resolveUserLocale()
    setDarkMode(state.userPreferences.theme)
  }
}

/**
 * Updates a preference in the state
 *
 * @constructor
 *
 * @param state
 * @param data
 */
export function UPDATE_PREF_IN_STATE(state, data) {
  if (data.key === 'enableEmailNotification') {
    state.userPreferences[data.key] = !!Number(data.value)

    return
  }

  state.userPreferences[data.key] = data.value
}
