<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Pagination',
    props: {
      pages: {
        type: Number,
        required: true
      },
      modelValue: {
        type: Number,
        default: 1
      },
    },
    data () {
      return {
        localValue: this.modelValue
      }
    },
    computed: {
      requestedPage: {
        get() {
          return this.localValue;
        },
        set(val) {
          this.localValue = val;
          this.$emit('update:modelValue', val);
        }
      }
    }
  })
</script>

<template>
  <div v-if="pages > 1" class="q-px-lg full-width flex flex-center">
    <q-pagination
        :max="pages"
        v-model:modelValue="requestedPage"
        input
    >
    </q-pagination>
  </div>
</template>
