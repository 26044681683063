<script>
  import { defineComponent, ref } from 'vue'
  import { mapGetters } from 'vuex'
  import { useQuasar } from 'quasar'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import Alert from 'components/Alert.vue'
  import AddFileModal from './modal/AddFileModal'
  import DeleteFileModal from './modal/DeleteFileModal'
  import ViewFileModal from './modal/ViewFileModal'

  export default defineComponent({
    name: 'FileManager',
    components: {
      AddFileModal,
      ViewFileModal,
      DeleteFileModal,
      LoadingIndicator,
      Alert
    },
    props: {
      path: {
        type: String,
        default: "/",
        required: true
      },
      filesystemName: {
        type: String,
        required: true
      }
    },
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q
      }
    },
    data () {
      return {
        showAddFileModal: false,
        viewedFile: null,
        showViewFileModal: false,
        showDeleteFileModal: false,
        nameNewFolder: false,
        newFolderInputModel: ref(''),
        changeName: false,
        changeNameInputModel: ref(''),
        selectedFile: [],
        selectedFileIndex: [],
        copyCount: 0,
        cutCount: 0
      }
    },
    computed: {
      /*...mapGetters('fileSystem', [
        'filesystems'
      ]),*/
      ...mapGetters('fileManager', [
        'isLoading',
        'isRefreshing',
        'filemanager'
      ]),
      alert () {
        return this.$store.state.alert;
      }
    },
    watch: {
      $route (to, from){
        // clear alert on location change
        this.$store.dispatch('alert/clear');
      },
      initialized: function () {
        if(this.initialized && typeof this.$route.params.path !== "undefined") {
          this.updateFilesystem(this.path);
        }
      },
      filesystemName: {
        handler: function () {
          this.updateCutCopyCounter();
        },
        deep: true
      }
    },
    mounted() {
      this.$store.dispatch('fileManager/filemanagerLoad', this.path);
      this.updateCutCopyCounter();
    },
    methods: {
      updateCutCopyCounter() {
        this.copyCount = 0;
        this.cutCount = 0;
        const items = JSON.parse(localStorage.getItem("pasteItems"));

        if (items?.filesystem === this.filesystemName) {
          const length = items.data.length;
          if (items.action === 'copy') {
            this.copyCount = length;
            return;
          }
          this.cutCount = length;
        }
      },
      base64Decode(str) {
        try {
          return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
        } catch(e) {
          this.$store.dispatch('alert/error', e,{ root: true });
        }
      },
      base64Encode(str) {
        try {
          return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
              function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
              })
          );
        }
        catch(e) {
            this.$store.dispatch('alert/error', e,{ root: true });
        }
      },
      resetSelection() {
        this.selectedFile = [];
        this.selectedFileIndex = [];
      },
      handleNameInputShortcuts(e, method, input) {
        if (e === void 0) {
          return
        }
        if (e.key === "Escape") {
          model = false;
        }
        if (e.key === "Enter") {
          this[method](input);
        }
      },
      addFolder(name) {
        if(name !== '') {
          this.nameNewFolder = false;
          this.changeName = false;
          this.$store.dispatch('fileManager/addFolder', {
            path: this.path,
            folder: name
          });
          this.newFolderInputModel = '';
        }
      },
      addFile() {
        this.nameNewFolder = false;
        this.changeName = false;
        this.showAddFileModal = true
      },
      renameItem (name) {
        if(name !== '') {
          this.nameNewFolder = false;
          this.changeName = false;
          this.$store.dispatch('fileManager/renameFile', {
            path: this.path,
            oldName: this.selectedFile[this.selectedFile.length - 1].basename,
            newName: name
          });
          this.resetSelection();
          this.changeNameInputModel = '';
        }
      },
      pasteItem () {
        let itemData = JSON.parse(localStorage.getItem("pasteItems"))
        let files = itemData.data;
        let action = itemData.action;

        this.copyCount = 0;
        this.cutCount = 0;
        localStorage.removeItem("pasteItems");

        if (action === 'copy') {
          this.$store.dispatch('fileManager/copyFile', {
            path: this.path,
            file: files
          });
          return;
        }

        this.$store.dispatch('fileManager/moveFile', {
          path: this.path,
          file: files,
        });
      },
      copyItem () {
        this.nameNewFolder = false;
        this.changeName = false;
        this.cutCount = 0;
        this.copyCount = this.selectedFile.length
        localStorage.setItem("pasteItems", JSON.stringify({ filesystem: this.filesystemName, action: "copy", data: this.selectedFile }));
        this.resetSelection();
      },
      backToParentFolder() {
        this.updateFilemanager(this.path.substring(0, this.path.lastIndexOf('/')));
      },
      moveItem () {
        this.nameNewFolder = false;
        this.changeName = false;
        this.copyCount = 0;
        this.cutCount = this.selectedFile.length
        localStorage.setItem("pasteItems", JSON.stringify({ filesystem: this.filesystemName, action: "move", data: this.selectedFile }));
        this.resetSelection();
      },
      handleContext (item) {
        if (item.type === "dir") {
          // %2F = URLEncoded Slash
          this.resetSelection();
          this.updateFilemanager(this.path + "/" + item.path.substring(item.path.lastIndexOf('/') + 1));

          return
        }

        this.showViewFileModal = true
        this.viewedFile = item
      },

      handleFileViewerClose() {
        this.showViewFileModal = false
        this.viewedFile = null
      },

      updateFilemanager(newPath) {
        this.resetSelection();
        this.$store.dispatch('fileManager/filemanagerLoad', newPath);
        //this.path = filesystem;
        // TODO: emit path update here?
        this.$emit('path', newPath)
      },
      selectFile(file, index) {
        let i = this.selectedFileIndex.indexOf(index);
        if (i === -1) {
          this.selectedFile.push(file);
          this.selectedFileIndex.push(index);
        } else {
          this.selectedFile.splice(i, 1);
          this.selectedFileIndex.splice(i, 1);
        }
      },

      handleDeleted() {
        this.showDeleteFileModal = false
        this.resetSelection()
      }
    }
  })
</script>

<template>
  <loading-indicator v-if="isLoading" wrapper />
  <div class="q-pt-md q-gutter-sm" v-else>
    <div class="q-mx-md q-mb-md">{{ $t('browser.editFilesDesc') }}</div>
    <div class="q-mx-md flex q-gutter-xs">
      <!-- this.$refs.nameNewFolderInput.focus(); -->
      <q-btn flat dense
             icon-right="create_new_folder"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :label="$t('browser.addFolder')"
             :title="$t('browser.addFolder')"
             @click.capture.stop='this.nameNewFolder = !this.nameNewFolder, this.changeName = false'
             :disabled="isRefreshing"
             data-cy="addFolder"
      />
      <q-btn flat dense
             icon-right="note_add"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :label="$t('browser.addFile')"
             :title="$t('browser.addFile')"
             @click.capture.stop='addFile()'
             :disabled="isRefreshing"
             data-cy="addFile"
      />
      <q-btn flat dense icon-right="file_copy"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :label="$t('browser.copy')" :title="$t('browser.copy')"
             @click.capture.stop='copyItem()'
             :disabled="selectedFileIndex === null || isRefreshing"
             data-cy="copyFile"
      >
        <q-badge v-if="copyCount > 0" class="z-top" color="#332EE8" :label="copyCount" rounded floating></q-badge>
      </q-btn>
      <q-btn flat dense icon-right="drive_file_rename_outline"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :label="$t('browser.rename')" :title="$t('browser.rename')"
             @click.capture.stop='this.changeName = !this.changeName, this.nameNewFolder = false'
             :disabled="selectedFileIndex === null || isRefreshing"
             data-cy="renameFile"
      />
      <q-btn flat dense icon-right="content_cut"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :label="$t('browser.cut')" :title="$t('browser.cut')"
             @click.capture.stop='moveItem()'
             :disabled="selectedFileIndex === null || isRefreshing"
             data-cy="cutFile"
      >
        <q-badge v-if="cutCount > 0" class="z-top" color="#332EE8" :label="cutCount" rounded floating></q-badge>
      </q-btn>
      <q-btn flat dense icon-right="delete"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :label="$t('browser.delete')" :title="$t('browser.delete')"
             @click.capture.stop="showDeleteFileModal = true"
             :disabled="selectedFileIndex === null || isRefreshing"
             data-cy="deleteFile"
      />
      <q-btn flat dense icon-right="content_paste"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :label="$t('browser.paste')" :title="$t('browser.paste')"
             @click.capture.stop='pasteItem()'
             :disabled="selectedFileIndex === null || isRefreshing || (copyCount+cutCount) === 0"
             data-cy="pasteFile"
      />
    </div>
    <div class="q-mx-md q-mt-md flex">
      <q-btn flat dense round icon="arrow_back"
             class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
             :title="$t('browser.backToParentFolder')"
             @click.capture.stop='backToParentFolder()'
             :disabled="isRefreshing"
             v-if="this.path.match((/\//g) || []).length > 1"
             data-cy="backToParentFolder"
      />
    </div>

    <div>
      <q-input dense outlined autofocus
               v-if="nameNewFolder === true"
               v-model="newFolderInputModel"
               :placeholder="$t('browser.nameInput.newFolder')"
               @keydown="handleNameInputShortcuts($event, 'addFolder', newFolderInputModel)"
               class="q-mx-sm"
               data-cy="folderNameInput"
      >
        <template v-slot:after>
          <q-btn round dense flat icon="cancel" @click.capture.stop='this.nameNewFolder = false' data-cy="folderNameInputCancel" />
          <q-btn round dense flat icon="send" @click.capture.stop='this.addFolder(newFolderInputModel)' data-cy="folderNameInputSubmit" />
        </template>
      </q-input>

      <q-input dense outlined autofocus
               v-if="changeName === true"
               v-model="changeNameInputModel"
               :placeholder="$t('browser.nameInput.changeName')"
               @keydown="handleNameInputShortcuts($event, 'renameItem', changeNameInputModel)"
               class="q-mx-sm"
               data-cy="renameInput"
      >
        <template v-slot:after>
          <q-btn round dense flat icon="cancel" @click.capture.stop='this.changeName = false' data-cy="renameInputCancel" />
          <q-btn round dense flat icon="send" @click.capture.stop='this.renameItem(changeNameInputModel)' data-cy="renameInputSubmit" />
        </template>
      </q-input>

    </div>

    <div class="q-ma-none q-pa-sm">
      <q-list class="app-filemanager row">
        <div class="q-ma-md" v-if="filemanager && filemanager.length === 0">{{ $t('browser.emptyFolder') }}</div>
        <!--<div v-else-if="filterModel !== '' && filteredMatches === 0">{{ $t('browser.filterNoMatch') }}</div>-->
        <template v-else v-for="(file, index) in filemanager">
          <!--v-if="filterModel === '' || file.basename.includes(filterModel)"-->
          <q-item v-if="typeof file.type !== 'undefined'"
                  :class="{
                  'active': this.selectedFileIndex !== null && this.selectedFileIndex.indexOf(index) !== -1,
                  'renaming': this.changeName === true && this.selectedFileIndex[this.selectedFileIndex.length - 1] === index
                  }"
                  clickable v-ripple
                  :disable="isRefreshing"
                  class="col-12 col-sm-6 col-lg-4 col-xl-3"
                  @click.capture.stop="selectFile(file, index)"
                  @dblclick.capture.stop="handleContext(file, index)"
          >
            <template v-slot:default>
              <q-item-section class="flex">
                <div class="flex items-center">
                  <q-icon class="q-mr-xs" :name="file.type === 'dir' ? 'folder' : 'insert_drive_file'" />
                  <span>{{ file.basename }}</span><br>
                </div>
              </q-item-section>
            </template>
          </q-item>
        </template>
      </q-list>
    </div>

    <add-file-modal
      v-model="showAddFileModal"
      :path="path"
      :base64-encode="base64Encode"
      @created="showAddFileModal = false"
    />

    <delete-file-modal
      v-model="showDeleteFileModal"
      :path="path"
      :file="selectedFile"
      @deleted="handleDeleted"
    />

    <view-file-modal
      v-model="showViewFileModal"
      :file="viewedFile"
      :path="path"
      :base64-decode="base64Decode"
      :base64-encode="base64Encode"
      @hide="handleFileViewerClose"
      @saved="handleFileViewerClose"
    />
  </div>
</template>

<style lang="scss">
  .app-browser-filesystem-item-wrapper {
    cursor: pointer;
    transition: .33s;
    .app-browser-filesystem-item {
      padding: .25rem .5rem;
    }
  }
  .q-tree.app-browser-filetree > .q-tree__node--child {
    > .q-tree__node-header {
      padding-left: unset;
    }
  }

  .app-filemanager .q-item {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    &.active {
      i.q-icon {
        color: $primary;
      }
    }
    &.renaming {
      border: 2px dashed black;
    }
  }

  .app-filemanager {
    .q-focusable:focus > .q-focus-helper, body.desktop .q-manual-focusable--focused > .q-focus-helper, body.desktop .q-hoverable:hover > .q-focus-helper,
    .q-focus-helper:before {
      background: unset !important;
    }
  }

  body.body--light {
    .app-browser-filesystem-item-wrapper,
    .app-filemanager .q-item {
      &.active {
        background: mix($dark, $background, 10%);
      }
      &:hover {
        background: mix($dark, $background, 15%);
      }
    }
  }
  body.body--dark {
    .app-browser-filesystem-item-wrapper,
    .app-filemanager .q-item {
      &.active {
        background: mix($light, $dark-page, 10%);
      }
      &:hover {
        background: mix($light, $dark-page, 15%);
      }
    }
  }
</style>
