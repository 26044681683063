import { render } from "./Dashboard.vue?vue&type=template&id=02c7eb02"
import script from "./Dashboard.vue?vue&type=script&lang=js"
export * from "./Dashboard.vue?vue&type=script&lang=js"

import "./Dashboard.vue?vue&type=style&index=0&id=02c7eb02&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCard});
