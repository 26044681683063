<script>
import {defineComponent, ref} from 'vue'
import {mapActions, mapState} from 'vuex'
import EntitySelection from 'components/EntitySelection.vue'
import EntityListing from './components/EntityListing.vue'
import JsonEditor from 'components/JsonEditor.vue'
import FilterPreset from 'src/components/FilterPreset.vue'

export default defineComponent({
    name: 'Entity',

    components: {
      JsonEditor,
      FilterPreset,
      EntityListing,
      EntitySelection
    },

    setup () {
      // Workaround for json editor line number wrap issue. See UI-233.
      /* With q-resize-observer, we (re)calculate the size based on the app-entity-query-editor-wrapper, and take this
       value to update the json editor max width of listing rows accordingly */
      const parentContainer = ref(null)
      return {
        parentContainer,
        onResize (size) {
          parentContainer.value = size
        }
      }
    },

    data () {
      return {
        hasQuery: false,
        entityData: null,
        loading: false,
        hasFilter: null,
        filters: {
          page: 1,
          query: ""
        },
        requestSource: null
      }
    },

    computed: {
      ...mapState('entities', ['currentEntityName', 'entityList']),

      entityName() {
        return this.$route.params.entitylist
      },

      entityTotalItems() {
        return this.entityData && this.entityData['hydra:totalItems'] ? this.entityData['hydra:totalItems'] : 0
      },

      hasEntityItems() {
        return this.entityData && this.entityData['hydra:member']
      }
    },

    created() {
      this.initialize()

      this.$eventBus.listen('ENTITY_CHANGE', (entity) => {
        this.entityChanged(entity)
      })
    },

    provide() {
      return {
        rowDeleted: this.rowDeleted
      }
    },

    methods: {
      ...mapActions('entities', ['updateCurrentEntityName']),

      initialize() {
        this.updateCurrentEntityName(this.entityName)

        // get the possible page coming from the query param
        this.filters.page = Number(this.getQueryParam('p') || this.getQueryParam('page') || this.filters.page)
        this.filters.query = this.getQueryParam('filter') || ""

        if (this.currentEntityName) {
          this.getEntityData()
        }
      },

      getQueryParam(queryName) {
        return this.$route.query[queryName]
      },

      entityChanged() {
        this.filters.page = 1
        this.filters.query = '';
        this.getEntityData()
      },

      async getEntityData() {
        if (this.requestSource) {
          this.requestSource.cancel({message: 'Entity request change!'})
        }

        const cancelToken = this.$axios.CancelToken
        this.requestSource = cancelToken.source()

        const query = {
          page: this.filters.page,
          ...this.filters.query && { filter: this.filters.query }
        }

        try {
          this.loading = true
          this.hasFilter = !!this.filters.query

          const { data } = await this.$api.entityAPI.getEntityData(this.currentEntityName, query, this.requestSource.token)

          this.loading = false

          if (data['@id']) {
            this.entityData = data
          }
        } catch (error) {
          if (!error?.cancelled) this.loading = false
        }
      },

      rowDeleted(row) {
        if (this.hasEntityItems) {
          const index = this.entityData['hydra:member'].findIndex(item => item.uuid === row.uuid)

          if (index > -1) {
            this.entityData['hydra:member'].splice(index, 1)
          }
        }
      },

      setQuery(query) {
        this.filters.query = query;

        this.hasQuery = true
      },

      clearQuery() {
        this.filters.query = "";
        this.$refs.filter.clear();

        this.hasQuery = false;
        this.doSearch()
      },

      doSearch() {
        if(this.filters.query !== '') {
          this.$router.push({query: {filter: this.filters.query } });
        } else {
          this.$router.push(this.$route.path);
        }
        this.filters.page = 1;
        this.getEntityData()
      },

      pageChange(page) {
        this.filters.page = page

        this.getEntityData()
      },

      triggerQueryByHotkey(event) {
        if (!this.filters.query) return

        if ((event.key === 's' || event.key === 'Enter') && (event.ctrlKey || event.metaKey)) {
          event.preventDefault();
          this.doSearch()
        }
      },

      updateEntity() {
        this.hasFilter = !!this.filters.query;
      }
    },

  mounted() {
    this.updateEntity();
  },

    watch:{
      entityList: {
        handler() {
          if (!this.currentEntityName && !this.entityData) {
            const entityId = this.entityList[0].uuid
            this.$router.push('/documents/' + entityId);
            this.updateCurrentEntityName(entityId)
            this.filters.page = 1
            this.getEntityData()
          }
        },
        deep: true
      }
    },

    beforeUnmount() {
      this.$eventBus.off('ENTITY_CHANGE')
    }
  })
</script>

<template>
  <q-page class="block" style="padding-bottom: 30px">
    <div class="row">
      <div
        class="app-entity-page-container col-12 relative-position"
        :class="{'col-sm-8 col-md-9 col-lg-10': !($q.screen.xs || $q.screen.sm),}"
      >
        <div v-if="entityName">
          <div class="full-width flex inline justify-between items-center q-mb-sm">
            <h1>{{ $t('entities.title', {entity: currentEntityName}) }}</h1>
            <div class="relative-position">
              <span class="q-mr-md">{{ $t('entities.totalItems', {totalItems: entityTotalItems}) }}</span>
              <q-btn
                flat
                dense
                icon-right="refresh"
                :label="$t('entities.refresh')"
                :disabled="loading"
                :loading="loading"
                class="app-action-btn q-pr-sm justify-end"
                @click.capture.stop="getEntityData"
              />
            </div>
          </div>

          <div class="app-entity-query-editor-wrapper row q-mb-md q-pa-sm" ref="wrapper">
            <q-resize-observer @resize="onResize" />
            <json-editor
              v-model:modelValue="filters.query"
              preset-set
              @update-preset="updateEntity"
              ref="filter"
              class="app-entity-query-editor"
              style="margin: 0"
              wrapped
              max-height="200px"
              @keydown.capture.stop="triggerQueryByHotkey($event)"
            >
              <template #bottom-toolbar>
                <div class="app-entity-query-controls row justify-between full-width">
                  <div class="app-entity-preselect-filter-wrapper">
                    <filter-preset label="by ID" :snippet="JSON.stringify({'_id': 'placeholder'})" :callback="setQuery" />
                    <filter-preset label="by Identifier" :snippet="JSON.stringify({'identifier': 'placeholder'})" :callback="setQuery" />
                    <filter-preset label="since date" :snippet="JSON.stringify({'createdAt': {'$gte': new Date()}})" :callback="setQuery" />
                    <filter-preset label="between two dates" :snippet="JSON.stringify({'createdAt': {'$gte': new Date(), '$lte': new Date()}})" :callback="setQuery" />
                  </div>

                  <div class="app-entity-query-control-wrapper q-ml-auto q-mt-md q-mt-lg-none">
                    <q-btn
                      v-if="this.filters.query"
                      flat
                      icon-right="clear"
                      :label="$t('entities.clearQuery')"
                      class="app-action-btn app-primary q-mr-sm"
                      @click.capture.stop='clearQuery'
                    />

                    <q-btn
                      flat
                      :label="$t('entities.submitQuery')"
                      :disable="!filters.query"
                      class="app-action-btn app-gray"
                      @click.capture.stop='doSearch'
                    />
                  </div>
                </div>
              </template>
            </json-editor>
          </div>

          <entity-listing
            ref="entityListing"
            v-if="hasEntityItems"
            :entity-data="entityData"
            :initial-page="filters.page"
            :has-filter="hasFilter"
            :total-items="entityTotalItems"
            @page-change="pageChange"
            :parent-width="parentContainer.width - 24 + 'px'"
          />
        </div>
        <div v-if="!hasEntityItems" class="q-mt-md">
          <h1>{{ $t('entities.titleEmpty') }}</h1>
          <div class="text-center">{{ $t('entities.noEntities') }}</div>
        </div>

        <q-inner-loading :showing="loading">
          <q-spinner
            color="primary"
            size="3em"
            :thickness="3"
          />
        </q-inner-loading>
      </div>
      <div v-if="!($q.screen.xs || $q.screen.sm)" class="app-entity-list-container-wrapper col-12 col-sm-4 col-md-3 col-lg-2">
        <div class="app-entity-list-container">
          <div class="app-entity-list-container-inner">
            <entity-selection
              redirect
              @entity-change="entityChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<style lang="scss">
  .app-entity-query-editor-wrapper {
    background-color: $background2;
    .app-entity-query-editor {
      margin: 0;
    }
  }

  .app-entity-page-container {
    order: 2;
    @media (min-width: $breakpoint-xs) {
      order: unset;
    }
    padding: 1rem .25rem;
    @media (min-width: $breakpoint-xs) {
      padding: 1rem;
    }
  }
  .app-entity-list-container-wrapper {
    order: 1;
    @media (min-width: $breakpoint-xs) {
      order: unset;
    }
    .app-entity-list-container {
      margin: 1.5rem;
      @media (min-width: $breakpoint-xs) {
        position: sticky;
        position: -webkit-sticky;
        top: 4rem;
      }
      .app-entity-list-container-inner {
        padding-left: 1rem;
        border-left: $layout-border;
        h2 {
          font-size: .8rem;
          font-weight: 600;
        }
      }
    }
  }
</style>
