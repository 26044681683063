import { render } from "./LoginForm.vue?vue&type=template&id=016690ac"
import script from "./LoginForm.vue?vue&type=script&lang=js"
export * from "./LoginForm.vue?vue&type=script&lang=js"

import "./LoginForm.vue?vue&type=style&index=0&id=016690ac&lang=scss"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QTooltip,QBtn,QField});
