<script>
  import { defineComponent, ref } from 'vue'
  import { mapGetters } from 'vuex'
  import LoadingIndicator from 'components/LoadingIndicator'
  import DateDisplay from 'components/DateDisplay.vue'
  import {useQuasar} from "quasar";

  export default defineComponent({
    name: "Heartbeat",
    components: {
      DateDisplay,
      LoadingIndicator
    },
    props: {
      col: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q
      }
    },
    computed: {
      ...mapGetters('heartbeats', [
        'isLoading',
        'isRefreshing',
        'heartbeat'
      ]),
      lastHeartbeat() {
        return this.heartbeat ? this.heartbeat[this.heartbeat.length - 1] : 0;
      },
      heartbeatTimeCalc() {
        let heartbeatDelay = this.heartbeat[this.heartbeat.length - 1].heartbeat_delay;
        let seconds = Number(heartbeatDelay)

        let d = Math.floor(seconds / (3600 * 24))
        let h = Math.floor((seconds % (3600 * 24)) / 3600)
        let m = Math.floor((seconds % 3600) / 60)
        let s = Math.floor(seconds % 60)

        let dDisplay = d > 0 ? d + this.$t('general.time.short.days') : ''
        let hDisplay = h > 0 ? h + this.$t('general.time.short.hours') : ''
        let mDisplay = m > 0 ? m + this.$t('general.time.short.minutes') : ''
        let sDisplay = s > 0 ? s + this.$t('general.time.short.seconds') : ''

        if('' === dDisplay && '' === hDisplay && '' === mDisplay && '' === sDisplay) {
          return this.$t('dashboard.heartbeat.delayFallback')
        } else {
          return dDisplay + ' ' + hDisplay + ' ' + mDisplay + ' ' + sDisplay
        }
      },
      exceeding() {
        // Delay is below INTERVAL
        if (this.lastHeartbeat.heartbeat_delay <= this.lastHeartbeat.heartbeat_interval) return -1;
        // Delay is below INTERVAL + GRACE_PERIOD
        if (this.lastHeartbeat.heartbeat_delay < (this.lastHeartbeat.heartbeat_interval + this.lastHeartbeat.heartbeat_grace)) return 0;
        // Delay exceeds INTERVAL + GRACE_PERIOD
        if (this.lastHeartbeat.heartbeat_delay > (this.lastHeartbeat.heartbeat_grace + this.lastHeartbeat.heartbeat_grace)) return 1;
      }
    },
    mounted () {
      this.$store.dispatch('heartbeats/getHeartbeat')
    },
    methods: {
      refreshHeartbeat() {
        this.$store.dispatch('heartbeats/getHeartbeat');
      },
      startHeartbeat() {
        this.$store.dispatch('heartbeats/startHeartbeat');
      }
    }
  });
</script>
<template>
  <div class="flex justify-between relative-position" :class="col">
    <loading-indicator v-if="isLoading" wrapper />
    <q-card v-else class="app-heartbeat full-width" :class="col, {
                'app-heartbeat-positive text-positive': exceeding === -1,
                'app-heartbeat-warning text-warning': exceeding  === 0,
                'app-heartbeat-negative text-negative': exceeding === 1,
                'app-heartbeat-none': lastHeartbeat === 0,
                }" flat :disabled="isRefreshing ? true : null">
      <h2 class="app-heartbeat-headline q-px-sm">{{ $t('dashboard.heartbeat.headline') }}</h2>
      <q-card-section class="flex justify-between q-py-none" horizontal>
        <q-card-section class="app-heartbeat-value row justify-between items-center full-width" data-cy="heartbeatValue">
          <div class="col flex inline items-center justify-start">
            <div class="q-mr-sm">
              <q-tooltip class="bg-negative text-white text-body2" v-if="exceeding > 0">
                {{ $t('dashboard.heartbeat.warning') }}
              </q-tooltip>
              <div class="app-heartbeat-delay-info flex-sm items-center" v-if="!isLoading">
                <q-icon class="q-mr-sm" name="img:assets/icons/heartbeat.svg" />
                <span v-if="lastHeartbeat !== 0">{{ $t('dashboard.heartbeat.delay', {delay: heartbeatTimeCalc}) }}</span>
                <span v-if="lastHeartbeat === 0">{{ $t('dashboard.heartbeat.noHeartbeat') }}</span>
                <q-icon v-if="exceeding > 0" class="inline q-pl-sm text-negative" name="warning" size="1.25rem" />
              </div>
            </div>
            <!--<div>
              {{ $t('dashboard.heartbeat.heartbeat') }}: <date-display :time="(lastHeartbeat.heartbeat_current * 1000).toString()" fixed /><br>
              {{ $t('dashboard.heartbeat.timestamp') }}: <date-display :time="(lastHeartbeat.timestamp_current  * 1000).toString()" fixed /><br>
            </div>-->
          </div>
          <div class="col flex inline items-center justify-end">
            <q-btn v-if="exceeding >= 0 || lastHeartbeat === 0"
                   flat dense icon-right="restart_alt"
                   class="q-mr-sm q-px-sm justify-end text-danger"
                   :class="{
                    'text-warning': exceeding === 0,
                    'text-negative': exceeding === 1}"
                   :label="$t('dashboard.heartbeat.start')"
                   @click.capture.stop="startHeartbeat()"
                   :disabled="isRefreshing"
                   data-cy="heartbeatStartButton"
            />
            <div v-if="exceeding < 0" class="q-mr-md app-text-weight-semibold">
              <q-icon size="sm" name="done_outline" class="q-mr-sm" />
              {{ $t('dashboard.heartbeat.fine') }}
            </div>

            <q-separator vertical />

            <q-btn flat dense icon-right="refresh" class="q-ml-sm q-pr-sm justify-end"
                   :label="$q.platform.is.mobile ? $t('dashboard.heartbeat.refresh') : null"
                   :title="$t('dashboard.heartbeat.refresh')"
                   @click.capture.stop="refreshHeartbeat()"
                   :disabled="isRefreshing"
                   data-cy="heartbeatRefreshButton"
            />
          </div>
        </q-card-section>
      </q-card-section>
    </q-card>
  </div>
</template>

<style lang="scss">
  .app-heartbeat-card {
    .q-separator {
      background: $dark;
    }
    .app-heartbeat-positive {
      .app-heartbeat-delay-info {
        .q-icon:first-child {
          filter: invert(49%) sepia(100%) saturate(371%) hue-rotate(83deg) brightness(96%) contrast(96%);
        }
      }
    }
    .app-heartbeat-warning {
      .app-heartbeat-delay-info {
        .q-icon:first-child {
          filter: invert(68%) sepia(73%) saturate(460%) hue-rotate(356deg) brightness(85%) contrast(84%);
        }
      }
    }
    .app-heartbeat-negative {
      .app-heartbeat-delay-info {
        .q-icon:first-child {
          filter: invert(12%) sepia(61%) saturate(4771%) hue-rotate(334deg) brightness(107%) contrast(143%);
        }
      }
    }
  }
  .app-heartbeat-headline {
    font-weight: bold;
    line-height: 0;
    margin-top: 1rem;
    font-size: 1rem;
  }
  .app-heartbeat-positive {
    background-color: $positive-bg;
    background-image: url("/assets/icons/heartbeat-bg.svg");
    background-repeat: no-repeat no-repeat;
    background-position: left top;
    background-size: contain;
  }
  .app-heartbeat-warning {
    background-color: $warning-bg;

  }
  .app-heartbeat-negative {
    background-color: $negative-bg;

  }
  .app-heartbeat-none {
    .app-heartbeat-delay-info {
      color: $dark;
    }
  }

  body.body--dark {
    .app-heartbeat-card {
      .q-separator {
        background: lighten($dark, 20%);
      }
    }
    .app-heartbeat-positive {
      background-color: $positive-bg-dark;
    }
    .app-heartbeat-warning {
      background-color: $warning-bg-dark;
    }
    .app-heartbeat-negative {
      background-color: $negative-bg-dark;
    }
    .app-heartbeat-none {
      .app-heartbeat-delay-info {
        color: $light;

        .q-icon:first-child {
          filter: invert(1);
        }
      }
    }
  }
</style>
