import { render } from "./Detail.vue?vue&type=template&id=10d8b6e4"
import script from "./Detail.vue?vue&type=script&lang=js"
export * from "./Detail.vue?vue&type=script&lang=js"

import "./Detail.vue?vue&type=style&index=0&id=10d8b6e4&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn,QSelect,QIcon,QTabs,QRouteTab,QBadge,QTabPanels,QTabPanel,QPageContainer,QTab});
