<script>
  import { defineComponent } from 'vue'
  import { debounce } from 'quasar'
  import { useClickToCopy } from 'src/composables/useClickToCopy'

  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

  export default defineComponent({
    name: 'JsonEditor',

    components: {
      PrismEditor
    },

    props: {
      modelValue: {
        type: String,
        default: ""
      },

      wrapped: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      contrast: {
        type: Boolean,
        default: false
      },

      maxHeight: {
        type: String, // String, because this is passed with unit declaration
        required: false
      },
      maxWidth: {
        type: String, // Dito
        required: false
      },
      showToolbar: {
        type: Boolean,
        required: false,
        default: true
      },

      toolbarPosition: {
        type: String,
        required: false,
        default: 'right'
      },

      toolbarConfig: {
        type: Array,
        required: false
      },

      noValidation: {
        type: Boolean,
        default: false
      }
    },

    emits: [
      'is-valid-json',
      'update:modelValue'
    ],

    data () {
      return {
        jsonCode: null,
        isValidJson: true
      }
    },

    computed: {
      toolbarButtons() {
        if (this.toolbarConfig?.length) {
          return this.toolbarConfig
        }
        return [
          'copy',
          'clear'
        ]
      }
    },

    methods: {
      clickToCopy: useClickToCopy(),

      initialize() {
        this.validateInput()

        if (!this.isValidJson && !this.noValidation) {
          this.jsonCode = JSON.stringify(this.modelValue, null, 2)
        }
      },

      highlighter(code) {
        return highlight(code, languages.js); // languages.<insert language> to return html with markup
      },

      clear() {
        this.jsonCode = null;
        this.isValidJson = true;
        this.$emit('update:modelValue', '');
      },

      validateInput() {
        if (!this.jsonCode) return

        try {
          JSON.parse(this.jsonCode)
          this.isValidJson = true
        } catch {
          this.isValidJson = false
        }

        this.$emit('is-valid-json', this.isValidJson)
      },

      codeChange: debounce(function() {
        if (!this.jsonCode) {
          this.isValidJson = true
          this.$emit('is-valid-json', this.isValidJson);

          return
        }

        this.validateInput()

        if (this.isValidJson || this.noValidation) {
          this.$emit('update:modelValue', this.jsonCode);
        }
      }, 300),

      copyEditorValue(event) {
        this.clickToCopy(event, {
          value: this.jsonCode
        })
      }
    },

    created() {
      this.initialize();
    },

    watch: {
      modelValue: {
        handler() {
          this.jsonCode = this.modelValue ? this.modelValue : ""
          this.initialize()
        },

        immediate: true
      }
    }
  })
</script>

<template>
  <div class="fit flex app-entity-query-editor relative-position">
    <!-- Top Toolbar -->
    <div
      v-if="showToolbar"
      class="q-editor-toolbar flex full-width q-mb-md items-center"
      :class="{
        'justify-end': toolbarPosition === 'right'
      }">
      <slot name="toolbar-left"></slot>
       <span
         v-if="toolbarButtons.includes('copy')"
         class="q-px-sm">
         <q-btn
           flat
           round
           padding="none"
           icon="content_copy"
           :disable="!jsonCode"
           @click="copyEditorValue"
         />
        <q-tooltip v-if="jsonCode">{{ $t('general.copyEditorContent') }}</q-tooltip>
      </span>

      <span
        v-if="toolbarButtons.includes('clear')"
        class="q-px-sm">
         <q-btn
           flat
           round
           padding="none"
           icon="highlight_off"
           :disable="!jsonCode || disabled"
           @click.capture.stop="clear"
         />
        <q-tooltip v-if="jsonCode">{{ $t('general.clearEditor') }}</q-tooltip>
      </span>

      <slot name="toolbar-right"></slot>
    </div>
    <!-- Top Toolbar End -->

    <!--  Editor  -->
    <div class="q-mb-lg full-width">
      <prism-editor
        v-model:modelValue="jsonCode"
        v-bind="$attrs"
        :highlight="highlighter"
        line-numbers
        :readonly="disabled"
        data-cy="jsonEditor"
        class="prism-editor"
        :class="{
          'q-py-lg': !wrapped,
          'disabled': disabled,
          'contrast': contrast,
          'border-danger': !isValidJson && !noValidation,
          'app-prism-line-number-fix': maxWidth
        }"
        :style="{ 'max-height': maxHeight, 'max-width': maxWidth }"
        @blur="validateInput"
        @input="codeChange"
      />

      <div
        v-if="!isValidJson && !noValidation"
        class="text-red text-weight-bold q-ml-xs absolute">
        {{ $t('general.invalidJson') }}
      </div>
    </div>
    <!--  Editor End  -->

    <div class="full-width">
      <slot name="bottom-toolbar"></slot>
    </div>
  </div>
</template>

<style lang="scss">
  .prism-editor {
    border: 1px solid transparent;
    background: $background;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    min-height: 7.5rem;
    padding: 5px;
    &.contrast {
      background: $background2;
      color: $dark;
    }
    &:hover {
      border: 1px solid $secondary;
    }
    &:focus-within {
      box-shadow: 0 0 3px $secondary;
    }
  }
  .prism-editor__textarea:focus {
    outline: none;
  }
  .prism-editor-wrapper {
    .prism-editor__container {
      min-height: 80px;
    }
  }
  body.body--light {
    .prism-editor {
      background: $background;
      color: $dark;
      &.contrast, .contrast {
        background: $background2;
      }
    }
    textarea.prism-editor__textarea::selection {
      background: #888;
    }
  }
  .q-editor-toolbar .q-btn.disabled {
    opacity: .3 !important;
  }

  // --------------------
  // Line number workaround fix - see https://github.com/koca/vue-prism-editor/issues/117#issuecomment-1053727586
  .app-prism-line-number-fix {
    .prism-editor__textarea {
      width: 999999px !important;
    }
    .prism-editor__editor {
      white-space: pre !important;
    }
    .prism-editor__container {
      overflow-x: hidden !important;
    }
    .mobile.touch { // Scroll fix for mobile
      .prism-editor-wrapper .prism-editor__container {
        overflow-x: auto !important;
      }
    }
  }
  // --------------------

  body.body--dark {
    .prism-editor {
      color: $light;
      caret-color: white;
      background: $dark-page;
      &.contrast, .contrast {
        background: #000;
      }
    }
    textarea.prism-editor__textarea::selection {
      background: #444;
    }
  }
</style>
