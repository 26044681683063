<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { configureFakeBackend } from 'src/helpers'
import { getUserPreferencesFromLocalStorage, setDarkMode } from 'src/utils'

configureFakeBackend();

export default defineComponent({
  name: 'App',

  methods: {
    ...mapActions('preferences', ['updatePrefInState']),

    setSiteFromUserPreferences() {
      const userSettings = getUserPreferencesFromLocalStorage()

      if (userSettings) {
        for (const pref in userSettings) {
          this.updatePrefInState({
            key: pref,
            value: userSettings[pref]
          })

          if (pref === 'local') this.$i18n.locale = userSettings[pref]
          if (pref === 'theme') setDarkMode(userSettings[pref])
        }
      }
    }
  },

  mounted() {
    this.setSiteFromUserPreferences()
  }
})
</script>

<template>
  <router-view />
</template>
