import { render } from "./PasswordReset.vue?vue&type=template&id=dcce0878"
import script from "./PasswordReset.vue?vue&type=script&lang=js"
export * from "./PasswordReset.vue?vue&type=script&lang=js"

import "./PasswordReset.vue?vue&type=style&index=0&id=dcce0878&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInput,QIcon,QTooltip,QBtn,QField});
