const baseNames = {
  INFO: 'info',
  ALERT: 'alert',
  WARNING: 'warning',
  DANGER: 'danger'
}

const severities = {
  ...baseNames,
  colors: {
    [baseNames.INFO]: 'info',
    [baseNames.ALERT]: 'red-4',
    [baseNames.WARNING]: 'warning',
    [baseNames.DANGER]: 'negative'
  }
}

export default severities
