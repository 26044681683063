<script>
  import { LOCALES } from 'src/meta/preferences'

  export default {
    name: 'LocalePicker',

    props: {
      modelValue: {
        type: String,
        required: false
      }
    },

    emits: ['update:model-value'],

    data() {
      return {
        value: this.modelValue,
        LOCALES
      }
    },

    computed: {
      selectedLocales() {
        return this.$t(LOCALES.find(locale => locale.value === this.value).label)
      }
    },

    methods: {
      updateValue() {
        this.$emit('update:model-value', this.value)
      }
    },

    watch: {
      modelValue(newState) {
        this.value = newState
      }
    }
  }
</script>

<template>
  <q-select
    v-model="value"
    dense
    outlined
    emit-value
    map-options
    :display-value="selectedLocales"
    :options="LOCALES"
    :label="$t('preferences.languageSelect')"
    class="app-preferences-language-input q-mt-md"
    @update:model-value="updateValue"
  >
    <template #option="{ opt, itemProps }">
      <q-item v-bind="itemProps">
        <q-item-section>
          <q-item-label>
            <span>{{ $t(opt.label) }}</span>
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
