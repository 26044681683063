<script>
  import { defineComponent, ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    name: 'LanguageSelect',
    setup () {
      const { locale } = useI18n({ useScope: 'global' });

      return {
        locale,
        localeOptions: [
          { value: 'en-US', label: 'English' }, // @Todo CS, 22.12.2021: how to get i18n label here? Throws error.
          { value: 'de-DE', label: 'German' }
        ]
      }
    }
  })
</script>

<template>
  <q-select
          v-model="locale"
          :options="localeOptions"
          :label="$t('menu.selectLanguage')"
          dense
          borderless
          emit-value
          map-options
          options-dense
          style="min-width: 150px"
  />
</template>
