import { render } from "./Entity.vue?vue&type=template&id=41aa9c56"
import script from "./Entity.vue?vue&type=script&lang=js"
export * from "./Entity.vue?vue&type=script&lang=js"

import "./Entity.vue?vue&type=style&index=0&id=41aa9c56&lang=scss"
script.render = render

export default script
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QResizeObserver,QPage,QBtn,QInnerLoading,QSpinner});
