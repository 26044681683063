import { render } from "./FlowSeveritiesChart.vue?vue&type=template&id=c9f80550"
import script from "./FlowSeveritiesChart.vue?vue&type=script&lang=js"
export * from "./FlowSeveritiesChart.vue?vue&type=script&lang=js"

import "./FlowSeveritiesChart.vue?vue&type=style&index=0&id=c9f80550&lang=scss"
script.render = render

export default script
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading,QSpinner});
