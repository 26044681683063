import { render } from "./EntityListingRow.vue?vue&type=template&id=35c2d2ef&scoped=true"
import script from "./EntityListingRow.vue?vue&type=script&lang=js"
export * from "./EntityListingRow.vue?vue&type=script&lang=js"

import "./EntityListingRow.vue?vue&type=style&index=0&id=35c2d2ef&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-35c2d2ef"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog});
