export default function () {
  return {
    getMessage: function(state) {
      return state.message;
    },
    getType: function(state) {
      return state.type;
    },
    getColor: function(state) {
      return state.color;
    },
    getItemUrl: function(state) {
      return state.itemUrl;
    }
  }
}
