<script>
  import { defineComponent, onBeforeUnmount, ref } from 'vue'
  import { useQuasar } from 'quasar'
  import { initialPageHelper, itemsPerPageHelper } from "src/helpers/pagination-helper";
  import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'
  import FlowActionBar from './components/FlowActionBar.vue'
  import FlowItem from './components/FlowItem.vue'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import ItemsPerPage from 'components/ItemsPerPage.vue'
  import Pagination from 'components/Pagination.vue'
  import {flowService} from "src/services";

  export default defineComponent({
    name: 'Overview',

    components: {
      FlowActionBar,
      FlowItem,
      LoadingIndicator,
      ItemsPerPage,
      Pagination
    },

    setup () {
      const $q = useQuasar();
      const autoRefreshLooping = ref(null)
      const runningFlow = ref(null)
      const totalItems = ref(0)
      const items = ref([])
      const isLoading = ref(true)
      const requestedPage = ref(null)
      const requestedItemsPerPage = ref(null)
      const getFlowCardDisabled = ref(null)

      return {
        items,
        isLoading,
        totalItems,
        runningFlow,
        requestedPage,
        qInstance: $q,
        autoRefreshLooping,
        getFlowCardDisabled,
        requestedItemsPerPage
      }
    },

    data() {
      onBeforeUnmount(() => {
        clearLooping(this.autoRefreshLooping);
      });
      const cancelToken = this.$axios.CancelToken
      return {
        requestSource: cancelToken.source()
      }
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.requestedItemsPerPage);
      }
    },

    methods: {
      async pageLoad(softReload = false) {
        if (softReload !== true) {
          this.isLoading = true;
          await this.$router.push({query: {p: this.requestedPage, i: this.requestedItemsPerPage}})
        }
        const { data } = await flowService.getFlows(this.requestedPage, this.requestedItemsPerPage)

        if (data) {
          if (data['hydra:member']?.length > 0) {

            // No break loop check necessary here

            this.items = data['hydra:member'];
            this.totalItems = data['hydra:totalItems'];
            this.isLoading = false;
          } else {
            // There's no data available on the current page anymore. Reload, and jump to the last one with data.
            if(data['hydra:totalItems'] > 0 && this.requestedPage !== 1) {
              this.totalItems = data['hydra:totalItems'];
              this.requestedPage = this.totalPages;
              await this.pageLoad();
            } else {
              // There are no items at all. Nothing to do here.
              // Need to empty the list Array when deleting the last available item though
              this.items = [];
              this.isLoading = false;
            }
          }
        }
      },

      async pageUpdate(payload = null, softReload = false) {
        if (payload?.type === "create") {
          this.requestedPage = this.totalPages;
          if (this.requestedPage === 0) this.requestedPage = 1; // Fix when creating first item
          if (!softReload) {
            if (this.totalItems > 0 && (this.totalItems % this.requestedItemsPerPage === 0)) {
              this.changePage(this.totalPages + 1);
              await this.$router.push({query: {p: this.requestedPage}})
            }
            this.changePage(this.requestedPage);
          }
        }

        try {
          const { data } = await flowService.getFlows(this.requestedPage, this.requestedItemsPerPage, this.requestSource.token)
          if (data) {
            let foundRunningFlow = false;
            if (typeof data['hydra:member'] !== "undefined") {
              data['hydra:member'].forEach(flow => {
                if(flow.isRunning === true) foundRunningFlow = true;
              });
            }
            this.runningFlow = foundRunningFlow;
            this.totalItems = data['hydra:totalItems'];
            this.pageLoad(softReload);
            if(this.runningFlow) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageUpdate(null, true));
          }
        } catch (error) {}
      },
      changePage (newPage) {
        this.requestedPage = newPage;
      }
    },

    mounted() {
      this.requestedPage = initialPageHelper(this.$route.query);
      this.requestedItemsPerPage = itemsPerPageHelper(this.$route.query);
      this.pageUpdate();
    },

    beforeRouteLeave() {
      // once we change our route
      if (this.requestSource) {
        this.requestSource.cancel({ message: 'Cancel Flow fetch request!' })
      }

      clearTimeout(this.autoRefreshLooping)
    }
  })
</script>

<template>
  <q-page class="block">
    <div>
      <div class="q-pa-md">
        <h1>{{ $t('flow.overview.headline') }}</h1>
        <p>{{ $t('flow.overview.desc') }}</p>

        <div class="app-flowcard-container q-py-md row items-start q-gutter row relative-position">
          <loading-indicator v-if="isLoading" centered />
          <template v-if="!isLoading || items.length !== 0">

            <flow-action-bar
                v-if="!isLoading || items.length !== 0"
                :items="items"
                :is-loading="isLoading"
                @refresh="pageUpdate"
            />
            <flow-item
                v-for="flowItem in items"
                :flow="flowItem"
                :key="flowItem.id"
                :is-loading="isLoading"
                :class="{'disabled': isLoading}"
                @refresh="pageUpdate(null, true)"
                :rows-per-page-options="[this.requestedItemsPerPage]"
            />

            <items-per-page
                v-if="totalItems > 0"
                v-model:modelValue="requestedItemsPerPage"
                @update:modelValue="pageLoad(false)"
                label="flow.overview.itemsPerPage"
            />

            <pagination
                :pages="totalPages"
                v-model="requestedPage"
                @update:modelValue="pageLoad(false)"
                :key="'page' + requestedPage"
            /> <!-- key required for component to update -->

          </template>

        </div>
      </div>
    </div>
  </q-page>
</template>
