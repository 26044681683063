<script>
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import LoadingIndicator from 'components/LoadingIndicator'

  export default defineComponent({
    name: 'PasswordRecoverRequest',
    components: {
      LoadingIndicator
    },
    data () {
      return {
        mail: '',
        submitted: false
      }
    },
    computed: {
      ...mapGetters('authentication', [
        'pwRequestPending'
      ]),
    },
    methods: {
      handleSubmit: function() {
        this.submitted = true;
        this.$store.dispatch('authentication/requestReset', this.mail);
      }
    }
  })
</script>

<template>
  <q-page class="app-page-password-recover psw-req justify-center">
    <div>
      <form @submit.prevent="handleSubmit">
        <div class="q-pa-md">
          <h1>{{ $t('publicPages.passwordRecover.headline') }}</h1>
          <p>{{ $t('publicPages.passwordRecover.desc') }}</p>
        </div>
        <!---->
        <div class="q-px-md q-pb-md">
          <div class="q-gutter">
            <q-input required v-model="mail" :label="$t('publicPages.passwordRecover.inputLabel')" style="max-width: 300px;">
              <template v-slot:prepend>
                <q-icon name="mail" />
              </template>
            </q-input>
          </div>
        </div>
        <div class="q-pa-md">
          <div class="flex">
            <q-btn flat class="app-action-btn" :class="{ 'disabled': pwRequestPending}" :disabled="pwRequestPending"
                   :title="pwRequestPending ? $t('publicPages.passwordRecover.submitSending') : $t('publicPages.passwordRecover.submit')"
                   :label="$t('publicPages.passwordRecover.submit')" type="submit"
            />
            <loading-indicator v-if="pwRequestPending" />
          </div>
          <div class="q-mt-md">
            <span class="app-submit-send-info" :class="{ 'show': pwRequestPending}">{{ $t('publicPages.passwordRecover.submitSending') }}</span>
          </div>
        </div>
      </form>
    </div>
    <div class="q-pa-md gt-sm">
      <img alt="" src="~assets/app-teaser.svg">
    </div>
  </q-page>
</template>

<style lang="scss">
  .app-page-password-recover {
    .q-field__control:before, .q-field__control:after {
      left: 0;
    }
  }
  .app-submit-send-info {
    display: inline-block;
    opacity: 0;
    transition: .5s;
    transform: translateY(-10px);

    &.show {
      opacity: 1;
      transform: unset;
    }
  }
  .psw-req {
    .q-field__control:before,
    .q-field__control:after {
      left: 0;
    }
    .app-submit-send-info {
      display: inline-block;
      opacity: 0;
      transition: .5s;
      transform: translateY(-10px);
      &.show {
        opacity: 1;
        transform: unset;
      }
    }
  }
</style>
