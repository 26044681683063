import { render } from "./Preferences.vue?vue&type=template&id=e642226e"
import script from "./Preferences.vue?vue&type=script&lang=js"
export * from "./Preferences.vue?vue&type=script&lang=js"

import "./Preferences.vue?vue&type=style&index=0&id=e642226e&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QToggle,QSlideTransition,QCard,QCardSection,QCheckbox,QInnerLoading});
