<script>
  import MainMenu from './menu/Menu.vue'
  import { mapGetters } from 'vuex'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import Toolbar from './menu/Toolbar.vue'
  import { defineComponent, ref } from 'vue'
  import { useQuasar } from 'quasar'
  import { useI18n } from 'vue-i18n'
  import Alert from 'components/Alert.vue'

  export default defineComponent({
    name: 'MainLayout',

    components: {
      MainMenu,
      Toolbar,
      LoadingIndicator,
      Alert
    },

    setup () {
      const $q = useQuasar();
      /*watch(() => $q.dark.isActive, val => {
        console.log(val ? 'On dark mode' : 'On light mode')
      });*/
      const menuDrawer = ref(false);
      return {
        menuDrawer,
        toggleMenuDrawer () {
          menuDrawer.value = !menuDrawer.value
        }
      }
    },

    data () {
      const { locale } = useI18n({useScope: 'global'});
      return {
        locale
      }
    },

    created() {
      this.notifyRedirectUnauthorized()
    },

    computed: {
      ...mapGetters('authentication', [
        'userInit'
      ]),

      isLoggedIn() {
        return (this.$store.state.authentication.user !== null) && (this.$store.state.authentication.status.loggingIn !== true)
      },

      build() {
        try {
          return process.env.BUILD;
        } catch(e) {
          return null;
        }
      },

      alert () {
        return this.$store.state.alert
      }
    },

    methods: {
      notifyRedirectUnauthorized() {
        const isUnauthorized = JSON.parse(window.localStorage.getItem('isUnauthorized'))
        const isLogin = this.$route.name === 'Login'

        if (isUnauthorized && isLogin) {
          this.$q.notify({
            message: 'Oops! Your session may have been expired. Please login.',
            multiLine: true,
            color: 'negative'
          })

          window.localStorage.removeItem('isUnauthorized')
        }
      },

      logout (e) {
        this.$store.dispatch('authentication/logout').then(() => {
          this.$router.push('/login')
        })
      },

      footerOffset (offset) {
        // "offset" is a Number (pixels) that refers to the total
        // height of header + footer that occupies on screen,
        // based on the QLayout "view" prop configuration
        // this is actually what the default style-fn does in Quasar
        return { minHeight: offset ? `calc(100vh - ${offset}px)` : `calc(100vh - 3rem)` }
      }
    },

    watch: {
      $route (to, from){
        // clear alert on location change
        this.$store.dispatch('alert/clear');
      }
    },
  })
</script>

<template>
  <q-layout view="lHh Lpr lFf" :class="{ 'app-logged-in': isLoggedIn}">
    <q-header class="print-hide">
      <q-toolbar class="app-toolbar">
        <toolbar class="q-mb-sm" @drawer-toggle="this.toggleMenuDrawer" />
      </q-toolbar>
    </q-header>
    <q-drawer
      v-model="menuDrawer"
      show-if-above
      :width="245"
    >
      <!-- <menu> tag is browser native and thus reserved. That's why component has a prefix -->
      <main-menu />
    </q-drawer>

    <q-page-container>
      <alert v-if="alert.message" :message="alert.message" icon="error" :class="`alert ${alert.type}`" :color="alert.color" :item-url="alert.itemUrl" />
      <router-view :style-fn="footerOffset" />
      <div class="app-footer q-pt-sm q-pb-sm">
        <div class="flex justify-center self-end">
          {{ $t('footer.copyright', {year: new Date().getFullYear()}) }}
          <span v-if="build" class="app-build">&nbsp;&nbsp;
            {{ $t('footer.build', {build: build}) }}
          </span>
        </div>
        <div class="app-imprint flex justify-center">
          <a :href="$t('footer.imprintLink')" target="_blank">
            {{ $t('footer.imprint') }}
          </a>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss">
  .app-build {
    color: $subtext;
  }
  .app-toolbar {
    background-color: $background;
  }
  body.body--dark {
    .app-toolbar {
      background-color: $dark-page;
    }
  }
</style>
