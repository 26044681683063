<script>
    import { defineComponent } from 'vue'
    import { useQuasar } from 'quasar'
    import DateDisplay from 'components/DateDisplay.vue'
    import StatusBadge from "components/StatusBadge.vue";

    export default defineComponent({
    name: 'FlowItemDetails',
    props: ['flow'],
    components: {
      StatusBadge,
            DateDisplay
        },
        setup () {
            const $q = useQuasar();
            return {
                qInstance: $q
            }
        },
      methods: {
        goToFlowExecution (e, flowExectionId)
        {
          e.stopPropagation()
          this.$router.push('/flowexecutions/detail/' + flowExectionId)
        }
      }
    })
</script>
<template>
    <div v-if="flow.lastFlowExecutionCreatedAt && flow.lastFlowExecutioUpdatedAt" class="row full-width">
    <div class="col-12 col-sm-4 col-md-auto q-mr-md-xl q-mt-md q-mt-md-none">
    <q-btn
        v-if="flow.lastFlowExecutionId"
        flat dense
        class="app-jump-to-execution app-text-weight-semibold text-caption"
        :label="$t('flow.jumpToExecution')"
        icon-right="img:assets/icons/link.svg"
        :title="$t('flow.jumpToExecution', {flowName: flow.name})"
        @click="goToFlowExecution($event, flow.lastFlowExecutionId)"
    />
    <!--<span>{{ $t('flow.neverExecuted') }}</span>-->
    </div>
    <div class="col-6 col-sm-4 col-md-auto q-mt-md q-mt-md-none q-mr-md-xl">
    <span>
        <q-icon class="app-icon-flow-start" name="img:assets/icons/start.svg" />
        <date-display v-if="flow.lastFlowExecutionCreatedAt" :time="flow.lastFlowExecutionCreatedAt" label="flow.createdAt" />
        <span v-else>-</span>
    </span>
    </div>
    <div class="col-6 col-sm-4 col-md-auto q-mt-md q-mt-md-none q-mr-md-xl">
    <span>
        <q-icon class="app-icon-flow-stop" name="img:assets/icons/stop.svg" />
        <date-display v-if="flow.lastFlowExecutioUpdatedAt" :time="flow.lastFlowExecutioUpdatedAt" label="flow.updatedAt" />
        <span v-else>-</span>
    </span>
    </div>
      <div class="col-6 col-sm-4 col-md-auto q-mt-md q-mt-md-none q-mr-md-xl">
          <q-icon class="app-icon-flow-stop" name="timer" />
          <date-display
              :time="flow.lastFlowExecutionCreatedAt"
              :time2="flow.lastFlowExecutioUpdatedAt"
              label="flow.duration"
          />
      </div>
    <div class="col-6 col-sm-4 col-md-auto q-mt-md q-mt-md-none">
      <status-badge :running="flow.isRunning" :status="flow.hasFinishedSuccessfully" />
    </div>
</div>
</template>
