import { render } from "./DeleteJobDispatcherMappingModal.vue?vue&type=template&id=c3c31fbe"
import script from "./DeleteJobDispatcherMappingModal.vue?vue&type=script&lang=js"
export * from "./DeleteJobDispatcherMappingModal.vue?vue&type=script&lang=js"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QAvatar,QSpace,QBtn,QCardActions});qInstall(script, 'directives', {ClosePopup});
