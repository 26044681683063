<script>
import {defineComponent, onBeforeUnmount, ref} from 'vue'
import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'
import { flowService } from "src/services";

import LoadingIndicator from 'components/LoadingIndicator.vue'
import StatisticCard from 'components/StatisticCard.vue'
import DateDisplay from 'components/DateDisplay.vue'
import JdmItem from './components/JdmItem/JdmItem.vue'
import JsonEditor from 'components/JsonEditor.vue'
import CreateJobDispatcherMappingModal from './components/JobDispatcherMappingModal/CreateJobDispatcherMappingModal'
import ExportFlowModal from '../Overview/components/FlowModal/ExportFlowModal'
import EndFlowModal from '../Overview/components/FlowModal/EndFlowModal'
import FlowModal from '../Overview/components/FlowModal/FlowModal'
import FlowItemDetails from '../Overview/components/FlowItemDetails'

export default defineComponent({
  name: 'Detail',

  components: {
    FlowModal,
    EndFlowModal,
    LoadingIndicator,
    StatisticCard,
    DateDisplay,
    JdmItem,
    ExportFlowModal,
    JsonEditor,
    FlowItemDetails,
    CreateJobDispatcherMappingModal
  },

  setup () {
    return {
      // ref documentation: https://quasar.dev/start/how-to-use-vue#handling-vue-properties
      tab: ref('configuration'),
      textareaInput: ref('')
    }
  },

  data () {
    onBeforeUnmount(() => {
      clearLooping(this.autoRefreshLooping);
    });
    return {
      showJdmModal: false,
      showFlowModal: false,
      showEndFlowModal: false,
      showExportFlowModal: false,
      tabOptions: ['configuration', 'input', 'transformations', 'output'],
      configurationModel: this.configuration,
      configurationKey: 0,
      currentFlow: null,
      getMappingsOfProcessStep: (processStep) => {
        if (this.currentFlow && ('jobDispatcherMappings' in this.currentFlow)) {
          return this.currentFlow.jobDispatcherMappings.filter(jdm => jdm.processStepIdentifier === processStep)
        }
        return []
      },
      autoRefreshLooping: null,
      runningFlow: null,
      configuration: null,
      isLoading: true
    }
  },

  computed: {
    getInputMappings() {
      return this.getMappingsOfProcessStep('input')
    },

    getTransformationMappings() {
      return this.getMappingsOfProcessStep('transformations')
    },

    getOutputMappings() {
      return this.getMappingsOfProcessStep('output')
    },

    disabledIfNotJdmTab() {
      return this.tab === 'configuration';
    }
  },

  methods: {
    pageLoad(payload = null, softReload = false) {
      if(!softReload) this.isLoading = true;
      const getData = () => {
        flowService.getFlowDetails(this.$route.params.id, (data) => {
          let flowRunning = false;
          if(data['@type'] === "hydra:Error") {
            this.$router.push({
              name: 'ERROR',
              params: { n: true }
            })
          } else {
            if(data.isRunning === true) flowRunning = true;
            else {
              // Break loop check once flow is finished
              clearLooping(this.autoRefreshLooping);
            }
            this.currentFlow = data;
            this.configuration = data.config;
          }
          this.runningFlow = flowRunning;
          if(this.runningFlow === true) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageLoad(null, true));
          this.isLoading = false;
        })
      }
      if(typeof payload?.deleted !== "undefined") {
        flowService.deleteJobDispatcherMapping(payload.jdmId, (data) => {
          this.isLoading = false;
          if(data?.data['@type'] === "hydra:Error") {
            this.$store.dispatch('alert/error', 'flow.overview.detail.modal.jdmDeletionFailed');
          } else {
            getData();
            this.$store.dispatch('alert/success', 'flow.overview.detail.modal.jdmDeletionSuccess');
          }
        });
      } else {
        getData();
      }
    },
    startFlow() {
      flowService.startFlow(this.currentFlow.id, (data) => {
        if(data.success !== 0) {
          this.$store.dispatch('alert/success', "flow.flowStartedSuccess", { root: true });
          this.pageLoad();
        } else {
          this.$store.dispatch('alert/error', data.msg, { root: true });
        }
      })
    },

    handleFlowEnded() {
      this.showEndFlowModal = false
      this.pageLoad()
    },

    createJdmModal() {
      if (!this.disabledIfNotJdmTab) {
        this.showJdmModal = true
      } else {
        this.$store.dispatch('alert/info', "flow.overview.detail.createJdmSelectTabError", { root: true });
      }
    },

    handleJdmCreated() {
      this.showJdmModal = false
      this.pageLoad()
    },

    updateFlowConfig() {
      try {
        const parsedConfig = JSON.parse(this.configurationModel, null, 2);
        this.isLoading = true;
        flowService.updateFlowConfig({ flowId: this.$route.params.id, config: parsedConfig}, (data) => {
          if (data.id) {
            this.$store.dispatch('alert/success', "flow.overview.detail.configuration.updateSuccess", { root: true });
          } else {
            this.$store.dispatch('alert/error', "flow.overview.detail.configuration.updateFail", { root: true });
          }
          this.isLoading = false;
        })
      } catch(e) {
        this.$store.dispatch('alert/error', "flow.overview.detail.configuration.updateFailJsonSyntax");
      }
    },
    updateConfigurationEditor() {
      this.configurationModel = (typeof this.configuration !== "undefined" && this.configuration !== null) ? JSON.stringify(this.configuration, null, 2) : "{}";
      this.configurationKey += 1; // Necessary for editor model view update - automatically triggers on key change
    },

    handleUpdated() {
      this.showFlowModal = false
      this.pageLoad()
    }
  },
  mounted() {
    this.pageLoad();
  },
  watch: {
    configuration: function() {
      this.updateConfigurationEditor();
    }
  }
})
</script>

<template>
  <q-page class="block app-flow-detail">
    <div class="app-container-wrapper">
      <div class="app-headline-container q-pa-md flex row" v-if="currentFlow !== null">
        <div class="full-width flex items-baseline">
          <h1>{{ currentFlow.name }}</h1>

          <q-btn
              flat dense
              class="app-jump-to-execution bg-transparent app-text-weight-semibold text-caption inline-block q-ml-sm q-px-sm"
              icon="img:assets/icons/edit.svg"
              :title="$t('flow.overview.detail.renameFlow')"
              @click="showFlowModal = true"
          >
              <span class="q-ml-sm">{{$t('flow.overview.detail.renameFlow')}}</span>
          </q-btn>
          <div class="row justify-between items-center full-width q-mt-md q-mt-md-sm last-execution-info" v-if="currentFlow.lastFlowExecutionId">
            <flow-item-details :flow="currentFlow"></flow-item-details>
          </div>
        </div>
      </div>
    </div>

    <div class="app-tab-select-select q-mx-md">
      <q-select outlined v-model="tab" :options="tabOptions" :label="$t('flow.overview.detail.tabSelect')">
        <template v-slot:prepend>
          <q-icon name="tab" />
        </template>
      </q-select>
    </div>

    <div class="flex justify-between items-center q-pr-md q-pl-sm reverse-wrap" v-if="currentFlow !== null">

      <div class="app-tab-select-tabs-wrapper flex">
        <div class="app-tab-select-tabs inline justify-between items-center">
          <q-tabs inline-label
            :class="{ 'disabled': isLoading }"
            v-model="tab" align="left" dense
            active-color="primary" indicator-color="primary" narrow-indicator
            class="app-wrap-overwrite q-mr-lg q-mb-sm"
          >
            <q-route-tab name="configuration" class="col-12 col-sm-auto q-pl-sm" :label="$t('flow.overview.detail.configuration.title')" :ripple="false" to="./configuration" exact />
            <q-route-tab name="input" class="col-12 col-sm-auto" :label="$t('flow.overview.detail.input.title')" :ripple="false" to="./input" data-cy="tabInput" exact>
              <q-badge class="q-ml-sm">{{ getInputMappings.length }}</q-badge>
            </q-route-tab>
            <q-route-tab name="transformations" class="col-12 col-sm-auto" :label="$t('flow.overview.detail.transformation.title')" :ripple="false"  to="./transformations" data-cy="tabTransformations" exact>
              <q-badge class="q-ml-sm">{{ getTransformationMappings.length }}</q-badge>
            </q-route-tab>
            <q-route-tab name="output" class="col-12 col-sm-auto" :label="$t('flow.overview.detail.output.title')" :ripple="false" to="./output" data-cy="tabOutput" exact>
              <q-badge class="q-ml-sm">{{ getOutputMappings.length }}</q-badge>
            </q-route-tab>
          </q-tabs>
        </div>
      </div>

      <div class="controls q-my-md q-my-lg-none q-mb-xs-lg">

        <q-btn v-if="!this.currentFlow.isRunning"
          flat dense
          class="app-action-btn q-pa-sm q-mr-sm"
          :label="$t('flow.overview.detail.start')"
          :disable="isLoading"
          @click="!this.currentFlow.isRunning ? startFlow() : null"
          v-bind:data-cy="'buttonQuickStartFlow' + this.currentFlow.id"
        ><q-icon name="arrow_right_alt" class="q-ml-sm" />
        </q-btn>

        <q-btn v-if="this.currentFlow.isRunning"
          flat dense
          class="app-action-btn q-pa-sm q-mr-sm"
          :label="$t('flow.overview.detail.end')"
          :disable="isLoading"
          @click="showEndFlowModal = true"
          v-bind:data-cy="'buttonQuickEndFlow' + this.currentFlow.id"
        ><q-icon name="do_disturb" class="q-ml-sm" />
        </q-btn>

        <q-btn flat dense rounded
          class="app-action-btn app-export-flow-btn q-pa-sm q-mr-sm"
          icon-right="file_upload"
          :label="$t('flow.overview.detail.export')"
          :title="$t('flow.overview.detail.export')"
          @click.capture.stop="showExportFlowModal = true"
        />

        <q-btn flat dense rounded
          class="app-action-btn app-add-jdm-btn app-highlighted-btn q-pa-sm"
          icon-right="add"
          :label="$t('flow.overview.detail.addJdm')"
          :title="!disabledIfNotJdmTab ? $t('flow.overview.detail.addJdm') : $t('flow.overview.detail.addJdmDisabled')"
          @click.capture.stop='createJdmModal'
          :disabled="disabledIfNotJdmTab"
        />
      </div>
    </div>

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="configuration" class="q-pa-md q-pb-lg">
        <div class="relative-position flex justify-center items-center">
          <loading-indicator v-if="isLoading" />
          <json-editor contrast wrapped
            class="q-mt-md"
            v-model="configurationModel"
            :key="'flowConfig' + configurationKey"
            v-if="!isLoading && currentFlow !== null"
            :disabled="isLoading"
          />
          <div v-if="currentFlow !== null" class="absolute-bottom-right">
            <q-btn flat dense
              :label="$t('flow.overview.detail.configuration.button')"
              :title="$t('flow.overview.detail.configuration.button')"
              class="app-action-btn q-pa-sm q-mr-xs q-mb-md"
              @click.capture.stop='updateFlowConfig()'
              :disabled="isLoading || currentFlow === null"
            />
          </div>
        </div>

      </q-tab-panel>

      <q-tab-panel name="input" class="q-pa-md">
        <div v-if="(!isLoading && getInputMappings.length <= 0)">
          <p class="q-my-sm text-light">{{ $t('flow.overview.detail.input.empty') }}</p>
          <q-btn flat dense icon-right="add" class="q-pr-sm justify-end app-action-btn"
            :label="$t('flow.overview.detail.addJdm')"
            :title="$t('flow.overview.detail.addJdm')"
            @click.capture.stop='createJdmModal'
            :disabled="disabledIfNotJdmTab || isLoading"
            data-cy="buttonAddJdm"
          />
        </div>
        <jdm-item
          v-if="!(!isLoading && getInputMappings.length <= 0)"
          v-for="mapping in getInputMappings"
          :jdm="mapping"
          :key="mapping.id"
          :flow="currentFlow"
          @reload="pageLoad($event)"
        />
      </q-tab-panel>

      <q-tab-panel name="transformations" class="q-pa-md">
        <div v-if="(!isLoading && getTransformationMappings.length <= 0)">
          <p class="q-my-sm text-light">{{ $t('flow.overview.detail.transformation.empty') }}</p>
          <q-btn flat dense icon-right="add" class="q-pr-sm justify-end app-action-btn"
            :label="$t('flow.overview.detail.addJdm')" :title="$t('flow.overview.detail.addJdm')"
            @click.capture.stop='createJdmModal'
            :disabled="disabledIfNotJdmTab || isLoading"
            data-cy="buttonAddJdm"
          />
        </div>
        <jdm-item v-if="!(!isLoading && getTransformationMappings.length <= 0)"
          v-for="mapping in getTransformationMappings"
          :jdm="mapping"
          :key="mapping.id"
          :flow="currentFlow"
          @reload="pageLoad($event)"
        />
      </q-tab-panel>

      <q-tab-panel name="output" class="q-pa-md">
        <div v-if="(!isLoading && getOutputMappings.length <= 0)">
          <p class="q-my-sm text-light">{{ $t('flow.overview.detail.output.empty') }}</p>
          <q-btn flat dense icon-right="add" class="q-pr-sm justify-end app-action-btn"
            :label="$t('flow.overview.detail.addJdm')" :title="$t('flow.overview.detail.addJdm')"
            @click.capture.stop='createJdmModal'
            :disabled="disabledIfNotJdmTab || isLoading"
            data-cy="buttonAddJdm"
          />
        </div>
        <jdm-item
          v-if="!(!isLoading && getOutputMappings.length <= 0)"
          v-for="mapping in getOutputMappings"
          :jdm="mapping"
          :key="mapping.id"
          :flow="currentFlow"
          @reload="pageLoad($event)"
        />
      </q-tab-panel>
    </q-tab-panels>

    <export-flow-modal
      v-model="showExportFlowModal"
      :flow="$route.params.id"
    />

    <flow-modal
      v-model="showFlowModal"
      :flow="currentFlow"
      @updated="handleUpdated"
    />

    <end-flow-modal
      v-model="showEndFlowModal"
      page="flow"
      :flow-id="currentFlow?.lastFlowExecutionId"
      :flow-name="currentFlow?.name"
      @success="handleFlowEnded"
    />

    <create-job-dispatcher-mapping-modal
      v-model="showJdmModal"
      :flow="currentFlow"
      :process-step="tab"
      @created="handleJdmCreated"
    />
  </q-page>
</template>

<style lang="scss">
.q-page-container .q-page .app-dashcard-container .app-dashcard.app-flow-execution-card {
  margin-left: unset;
}
/* No wrap prop for tabs available. Workaround until better solution is found */
.q-tabs.app-wrap-overwrite {
  .q-tabs__content.no-wrap {
    flex-wrap: wrap;
  }
}
.q-tab {
  margin-top: .25rem;
  margin-bottom: .25rem;
}
.app-tab-select-tabs-wrapper {
  justify-content: end;
  @media (min-width: $breakpoint-xs) {
    justify-content: space-between;
  }
}
.app-flow-execution-card {
  padding: unset;
}
.app-tab-select-select {
  @media (min-width: $breakpoint-xs) {
    display: none;
  }
}
.app-tab-select-tabs {
  display: none;
  margin-bottom: 1rem;
  @media (min-width: $breakpoint-xs) {
    display: flex;
    margin-bottom: unset;
  }
}
.app-flow-detail .controls .app-action-btn {
  margin-top: 1rem;
  @media (min-width: $breakpoint-xs) {
    margin-top: unset;
  }
}
.app-tab-select-tabs {
  .q-tab:hover {
    text-decoration: none;
  }
}
h1>.app-jump-to-execution {
  top: .25rem;
  width: 1.5rem;

  .q-icon {
    height: 1rem
  }
}
body.body--dark {
  .app-jump-to-execution {
    color: $light;
    .q-icon {
      filter: invert(1);
    }
  }
}
@media (max-width: $breakpoint-xs) {
  .last-execution-info {
    justify-content: flex-start;

    &>div { margin-right: 1rem; }
  }
}
</style>
