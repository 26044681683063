import { render } from "./Heartbeat.vue?vue&type=template&id=baf720ce"
import script from "./Heartbeat.vue?vue&type=script&lang=js"
export * from "./Heartbeat.vue?vue&type=script&lang=js"

import "./Heartbeat.vue?vue&type=style&index=0&id=baf720ce&lang=scss"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QTooltip,QIcon,QBtn,QSeparator});
