import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

/* @TODO rework using quasar notify component, use global error store, commit error to "sibling" state */
export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state
}
