<script>
import SeverityLevelsChart from 'components/Charts/Validation/SeverityLevelsChart.vue'
import CommonMessagesChart from 'components/Charts/Validation/CommonMessagesChart'
import FlowSeveritiesChart from 'components/Charts/Validation/FlowSeveritiesChart'
import MostSeenEntitiesChart from 'components/Charts/Validation/MostSeenEntitiesChart'
import AlertEntitiesChart from 'components/Charts/Validation/AlertEntitiesChart'

export default {
  name: "ValidationStats",

  components: {
    SeverityLevelsChart,
    CommonMessagesChart,
    FlowSeveritiesChart,
    MostSeenEntitiesChart,
    AlertEntitiesChart
  }
}
</script>

<template>
  <div class="q-pa-md">
    <h1 class="q-mb-lg">Validation Stats</h1>

    <div class="row">
      <div class="col-12 col-lg-6 col-md-12">
        <q-card flat class="severity-levels-card full-height">
          <q-card-section>
            <severity-levels-chart />
          </q-card-section>
        </q-card>
      </div>

      <div class="col-12 col-lg-6 col-md-12">
        <q-card flat class="flow-severities-card full-height">
          <q-card-section>
            <flow-severities-chart />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row q-mt-md">
      <div class="col">
        <q-card flat>
          <q-card-section>
            <alert-entities-chart />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row q-mt-md">
      <div class="col">
        <q-card flat>
          <q-card-section>
            <common-messages-chart />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row q-mb-xl q-mt-md">
      <div class="col">
          <q-card flat>
            <q-card-section>
              <most-seen-entities-chart />
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
</template>

<style lang="scss">
  .flow-severities-card  {
    body.screen--xs, body.screen--sm &, body.screen--md {
      margin-top: 16px;
    }
  }
  .severity-levels-card {
    body.screen--lg, body.screen--xl {
      margin-right: 16px;
    }
  }
  .chart-header {
    font-size: 16px;
    font-weight: 900;
    opacity: 1;
  }
  .validation-graph {
    body.screen--xs, body.screen--sm {
      width: 350px;
    }
    body.screen--md, body.screen--lg {
      width: 500px;
    }
  }
  .chart-filter {
    body.screen--xs, body.screen--sm {
      position: relative;
    }
    body.screen--md {
      margin-top: 15px;
      margin-right: 15px;
    }
    body.screen--lg, body.screen--xl {
      margin-top: 20px;
      margin-right: 20px;
    }
  }
</style>
