import { render } from "./CreateJobDispatcherMappingModal.vue?vue&type=template&id=0d26243d"
import script from "./CreateJobDispatcherMappingModal.vue?vue&type=script&lang=js"
export * from "./CreateJobDispatcherMappingModal.vue?vue&type=script&lang=js"

import "./CreateJobDispatcherMappingModal.vue?vue&type=style&index=0&id=0d26243d&lang=scss"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QSelect,QItem,QItemSection,QBtn});qInstall(script, 'directives', {ClosePopup});
