<script>
  import VueApexCharts from 'vue3-apexcharts'
  import CustomChartLegend from "components/Charts/Legend/CustomChartLegend";
  import SelectInputFilter from "components/Charts/Filter/SelectInputFilter";
  import {ucwords} from "src/utils/stringUtils";

  export default {
    name: "AlertEntitiesChart",

    components: {
      VueApexCharts,
      CustomChartLegend,
      SelectInputFilter
    },

    data() {
      return {
        legendData: [],
        entityTypes: [],
        filters: {
          documentClass: null,
        },
        loading: false,
        options: {
          chart: {
            type: 'pie',
            height: 350
          },
          legend: {
            show: false
          },
          labels: [],
          colors: ['#7940FF', '#332EE8'],
        },
        series: []
      }
    },

    created() {
      this.getSeverityLevels({ documentClass: "Product" } )
      this.getEntityListFilter()
    },

    methods: {

      getSeverityLevels(params = null) {
        this.loading = true;
        this.$api.validationInfo.getAlertEntity(params)
          .then(res => {
            if (res.data) {
              const alertEntityData = res.data['hydra:member']
              this.series = []
              this.options.labels = []
              this.legendData = [];

              let data = alertEntityData[0];


              this.options.labels.push('Alert entities')
              this.series.push(data.alertEntities)
              this.options.labels.push('Alert-free entities')
              this.series.push(data.alertFreeEntities)

              this.$refs.severityLevelChart.updateOptions(this.options)

              this.legendData.push({color: this.options.colors[0], description: 'Entities with Alert Validations'})
              this.legendData.push({color: this.options.colors[1], description: 'Alert-free entities'})
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(_ => {
            this.loading = false
          })
      },

      getEntityListFilter() {
        this.loading = true;

        this.$api.entityAPI.list()
          .then(res => {
            const entityListData = res.data['hydra:member']
            entityListData.forEach(data => {
              this.entityTypes.push(data.collectionName)
            })
          })
          .catch(err => {
            console.error(err)
          })
          .finally(_ => {
            this.loading = false;
          })
      },

      updateEntityType(newType) {
        this.filters.documentClass = newType;
      }

    },

    watch: {
      filters: {
        handler: function (newFilters) {
          this.getSeverityLevels({ documentClass: newFilters.documentClass });
        },
        deep: true
      }
    }
  }
</script>

<template>
  <div class="q-pa-md alert-entities-chart">

    <div class="column items-center">
      <p class="chart-header">Alert Entities</p>

      <div class="chart-filter">
        <select-input-filter v-on:updateModel="updateEntityType" type="single" :options="entityTypes" label="Entity Type" default-value="Product" />
      </div>
    </div>

    <div class="row items-center justify-center">
      <div class="col-xl-4">
        <vue-apex-charts
          ref="severityLevelChart"
          width="350px"
          type="pie"
          :options="options"
          :series="series"
          class="q-mb-lg validation-graph"
        />
      </div>

      <div class="col-xl-4">
        <custom-chart-legend :legend-data="legendData" />
        <q-inner-loading
          :showing="loading"
          color="primary"
        >
          <q-spinner
            color="primary"
            size="42px"
          />
          <span class="text-primary text-weight-medium">Loading data...</span>
        </q-inner-loading>
      </div>
    </div>

    <div class="absolute-center" v-if="legendData.length === 0 && !loading">
      <span class="text-primary text-weight-medium">No Data</span>
    </div>
  </div>
</template>

<style lang="scss">
  .alert-entities-chart {
    min-height: 450px;

    .validation-graph {
      justify-content: right !important;
      align-items: self-end !important;
    }

    .apexcharts-canvas {
      margin: 0 0 0 auto;
    }

  }
</style>
