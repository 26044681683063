<script>
import { defineComponent } from 'vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import DateDisplay from 'components/DateDisplay.vue'
import StatusBadge from "components/StatusBadge.vue";

export default defineComponent({
  name: 'FlowMeta',
  props: {
    currentFlow: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    LoadingIndicator,
    DateDisplay,
    StatusBadge
  }
})
</script>

<template>
  <loading-indicator v-if="isLoading" wrapper />
  <div v-if="!isLoading" class="row full-width q-mt-sm row">
  <div class="row q-gutter-x-xl">
    <div>
      <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.createdAt') }}:</span>
      <template v-if="currentFlow.created_at"><date-display :time="currentFlow.created_at" /></template>
      <template v-else> - </template><br>
    </div>
    <div>
      <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.flow') }}:</span>
      #{{ currentFlow.id }}: {{ currentFlow.flowName }}
    </div>
    <div>
      <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.processStep') }}:</span> {{ currentFlow.currentProcessStepIdentifier }}
    </div>
    <div>
      <span class="app-text-weight-semibold q-mr-sm">{{ $t('flow.executions.duration') }}:</span>
      <date-display :time="currentFlow.created_at" :time2="currentFlow.updated_at" />
    </div>

    <status-badge :running="currentFlow.active" :status="currentFlow.success" />
  </div>
  </div>
</template>

<style lang="scss">
.app-flow-status-action-bar {
  margin-left: -24px !important; // Address outer gutter offset
  margin-right: -24px !important;
}
</style>
