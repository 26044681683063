<script>
import VueApexCharts from 'vue3-apexcharts'
import CustomChartLegend from "components/Charts/Legend/CustomChartLegend.vue"
import SelectInputFilter from 'components/Charts/Filter/SelectInputFilter.vue'

export default {
  name: "DiskSpaceChart",

  components: {
    VueApexCharts,
    CustomChartLegend,
    SelectInputFilter
  },

  props: {
    col: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      legendData: [],
      loading: false,
      options: {
        chart: {
          type: 'pie',
          height: 350
        },
        legend: {
          show: false
        },
        labels: [],
        colors: ['#3363FF', '#332EE8', '#7940FF', '#992EE8', '#DF33FF'],
      },
      series: [],
      sizes: ["GB", "MB", "KB", "B"],
      filters: {
        format: "GB",
        filesystem: null
      },
    }
  },

  created() {
    this.getDiskSpaceInfo()
    this.getFileSystems()
  },

  methods: {
    getFileSystems() {
      this.loading = true;
      this.$api.filesystem.listFileSystem()
        .then(res => {
          if (res.data) {
            const filesystems = res.data["hydra:member"];
            filesystems.forEach(data => {
                this.filesystems?.push(data.identifier);
            });
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },

    getDiskSpaceInfo() {
      this.series = []
      this.options.labels = []
      this.legendData = []
      this.loading = true;

      this.$api.filesystem.getDiskSpace({ "format": this.filters.format })
        .then(res => {
          if (res.data) {
            const diskSpace = res.data
            if (!res.data.success) {
              this.$store.dispatch('alert/error', res.data.msg, { root: true });
              return;
            }

            const free_space = this.$t('dashboard.diskSpace.freeSpace');
            const used_space = this.$t('dashboard.diskSpace.usedSpace');

            this.options.labels.push(free_space + ' (' + this.filters.format + ')');
            this.series.push(diskSpace.data.disk_free_space);
            this.legendData.push({color: this.options.colors[0], description: free_space + ' (' + this.filters.format + ')'});

            this.options.labels.push(used_space + ' (' + this.filters.format + ')');
            this.series.push(diskSpace.data.disk_used_space);
            this.legendData.push({color: this.options.colors[1], description: used_space + ' (' + this.filters.format + ')'});

            this.$refs.diskSpaceChart.updateOptions(this.options);
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },

    updateSize(newFormat) {
      this.filters.format = newFormat
      this.getDiskSpaceInfo()
    },

    refreshDiskSpace() {
      this.getDiskSpaceInfo()
    }
  }
}
</script>

<template>

  <div class="flex justify-between q-pt-md relative-position" :class="col">
    <q-card class="app-disk-space full-width" flat>
      <q-icon size="3rem" name="storage" class="app-card-bg-icon"></q-icon>
      <q-item>
        <q-item-section>
          <q-item-label class="app-text-weight-semibold">{{ $t('dashboard.diskSpace.headline') }}</q-item-label>
        </q-item-section>
      </q-item>

      <div class="chart-filter chart-filter flex justify-center">
        <select-input-filter v-on:updateModel="updateSize" type="single" :options="sizes" label="Size Format" default-value="GB" data-cy="diskSpaceFilter" />
      </div>

      <div class="row justify-center reverse-wrap">
        <div class="flex justify-end items-end">
          <vue-apex-charts
            ref="diskSpaceChart"
            width="350px"
            type="pie"
            :options="options"
            :series="series"
            class="q-mb-lg"
          />
        </div>

        <div class="flex justify-start items-center">
          <custom-chart-legend :legend-data="legendData" />
          <q-inner-loading
            :showing="loading"
            color="primary"
          >
            <q-spinner
              color="primary"
              size="42px"
            />
            <span class="text-primary text-weight-medium">Loading data...</span>
          </q-inner-loading>
        </div>
      </div>

      <div class="absolute-center" v-if="legendData.length === 0 && !loading">
        <span class="text-primary text-weight-medium">No Data</span>
      </div>

      <q-card-section class="app-message-queue-action col-auto q-pl-none q-mb-sm q-mx-sm">
        <div class="flex justify-end">
          <q-btn flat dense no-caps icon-right="refresh" class="q-pr-sm justify-end app-action-btn"
                 :label="$t('dashboard.diskSpace.refresh')"
                 data-cy="diskSpaceRefreshButton"
                 @click.capture.stop="refreshDiskSpace()"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<style lang="scss">
  .app-disk-space-card {
    .custom-chart-legend {
      background: none !important;
    }
  }
</style>
