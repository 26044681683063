import client from '../helpers/authenticated-axios'

export const flowExecutionService = {
  getFlowExecutions(page, itemsPerPage, flow, callback) {
    if (flow) {
      client().get('/flow_executions?order[created_at]=desc&page=' + page + '&itemsPerPage=' + itemsPerPage + '&flow=' + flow).then((response) => {
        callback(response.data)
      }).catch((error) => {
        callback(error.response);
      });
    } else {
      client().get('/flow_executions?order[created_at]=desc&page=' + page + '&itemsPerPage=' + itemsPerPage).then((response) => {
        callback(response.data)
      }).catch((error) => {
        callback(error.response);
      });
    }
  },
  getFlowDetails(flowId, callback) {
    client().get('/flow_executions/' + flowId).then((response) => {
      callback(response.data)
    });
  }
}
