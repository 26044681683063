<script>
  import {defineComponent} from 'vue'
  import { useQuasar } from 'quasar'
  import { initialPageHelper, itemsPerPageHelper } from 'src/helpers/pagination-helper'
  import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'
  import { flowExecutionService } from 'src/services'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import DateDisplay from 'components/DateDisplay.vue'
  import ItemsPerPage from 'components/ItemsPerPage.vue'
  import Pagination from 'components/Pagination.vue'
  import StatusBadge from "components/StatusBadge.vue";

  export default defineComponent({
    name: 'FlowExecutionOverview',
    components: {
      StatusBadge,
      LoadingIndicator,
      DateDisplay,
      ItemsPerPage,
      Pagination,
    },
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q
      }
    },
    data () {
      return {
        totalItems: 0,
        items: [],
        isLoading: true,
        requestedPage: null,
        requestedItemsPerPage: null,
        flow: this.$router.currentRoute._value.params.flowid,
        columns: [
          {
            name: 'id',
            required: true,
            label: this.getTranslation("id"),
            align: 'left',
            field: row => row.id
          },
          {
            name: 'success',
            required: false,
            label: this.getTranslation("success"),
            align: 'left'
            //field: row => this.renderSuccessCol(row.success, row.active)
          },
          {
            name: 'processStep',
            required: true,
            label: !(this.$q.screen.xs || this.$q.screen.sm) ? this.getTranslation("processStep") : this.getTranslation("processStepShort"),
            align: 'left',
            field: row => row.currentProcessStepIdentifier
          },
          {
            name: 'name',
            required: true,
            label: this.getTranslation("name"),
            align: 'left',
            field: row => '#' + row.flowId + ': ' + row.flowName
          },
          {
            name: 'created',
            required: false,
            label: this.getTranslation("createdAt"),
            align: 'left'
            // field content is defined via slot in template
          },
          {
            name: 'time',
            required: false,
            label: this.getTranslation("time"),
            align: 'left'
            // field content is defined via slot in template
          },
          {
            name: 'link',
            required: false,
            label: ""
            // field content is defined via slot in template
          }
        ],
        form:{
          code:""
        },

        autoRefreshLooping: null,
        runningFlow: null
      }
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.requestedItemsPerPage);
      }
    },
    mounted() {
      this.requestedPage = initialPageHelper(this.$route.query);
      this.requestedItemsPerPage = itemsPerPageHelper(this.$route.query);
      this.pageLoad();
    },
    beforeUnmount() {
      clearLooping(this.autoRefreshLooping);
    },
    methods: {
      getTranslation(key) {
        return this.$t('flow.executions.thLabel.' + key);
      },
      pageLoad(softReload = false) {
        if(!softReload) {
          this.isLoading = true;
          this.$router.push({query: {p: this.requestedPage, i: this.requestedItemsPerPage } })
        }
        flowExecutionService.getFlowExecutions(this.requestedPage, this.requestedItemsPerPage, this.flow, (data) => {
          let foundRunningFlow = false;
          // Update page
          if(data['hydra:member']?.length > 0 ) {
            data['hydra:member'].forEach(member => {
              if(member.active === true) foundRunningFlow = true;
            });

            if(data.isRunning === true) foundRunningFlow = true;
            else {
              // Break loop check once flow is finished
              clearLooping(this.autoRefreshLooping);
            }

            this.items = data['hydra:member'];
            this.totalItems = data['hydra:totalItems'];
            this.isLoading = false;
          } else {
            // There are no elements available on current page anymore. Reload, jump to last one with data.
            if(data['hydra:totalItems'] > 0 && this.requestedPage !== 1) {
              this.items = data['hydra:totalItems'];
              this.requestedPage = this.totalPages;
              this.pageLoad();
            } else {
              // There are no elements at all. Nothing to do here.
              // Need to empty the list Array when deleting the last available item though
              this.items = [];
              this.isLoading = false;
            }
          }
          this.runningFlow = foundRunningFlow;
          if(this.runningFlow) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageLoad(true));
        })
      },
      goToDetail (evt, row, newTab) {
        const url = '/flowexecutions/detail/' + row.id
        if(newTab) window.open('#' + url, '_blank').focus();
        else this.$router.push(url);
      }
    }
  })

</script>

<template>
  <q-page class="block">
    <div class="q-pa-md relative-position">
      <h1>{{ $t('flow.executions.headline') }}</h1>
      <p>{{ $t('flow.executions.desc') }}</p>

      <loading-indicator v-if="isLoading" wrapper />

      <template v-if="!isLoading && items.length > 0">
        <q-card flat>
        <q-card-section>
        <div class="row q-pa-sm">
          <q-table class="app-flow-execution-table col-12 transparent"
                   :rows="items" :row-key="items.id"
                   :columns="this.columns"
                   color="primary"
                   :hide-bottom=true
                   :hide-pagination=true
                   v-if="!isLoading && items.length > 0"
                   :rows-per-page-options="[this.requestedItemsPerPage]"
                   flat>
            <template v-slot:body="props">
              <q-tr :props="props"
                    class="cursor-pointer"
                    @click="goToDetail(null, props.row)"
                    @click.middle="goToDetail(null, props.row, true)"
              >
                <q-td
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"
                >
                  <template v-if="col.name === 'success'">
                    <status-badge :running="props.row.active" :status="props.row.success" />
                  </template>
                  <template v-else-if="col.name === 'created'">
                    <date-display :time="props.row.created_at" />
                  </template>
                  <template v-else-if="col.name === 'time'">
                    <date-display :time="props.row.updated_at" />
                  </template>
                  <template v-else-if="col.name === 'link'">
                    <q-btn
                        icon-right="img:assets/icons/link.svg"
                        flat dense
                        class="app-to-detail-btn"
                        :label="$q.platform.is.mobile ? $t('flow.executions.gotoDetail') : null"
                        :title="$t('flow.executions.gotoDetail')"
                        :disabled="isLoading"
                        @click.capture.stop="goToDetail(null, props.row)"
                    />
                  </template>
                  <template v-else>
                    <template v-if="col.name === 'id'">#</template>
                    {{ col.value }}
                  </template>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
        </q-card-section>
        </q-card>

        <items-per-page
            v-model:modelValue="requestedItemsPerPage"
            @update:modelValue="pageLoad(false)"
            label="flow.executions.itemsPerPage"
        />

        <pagination
            :pages="totalPages"
            v-model:modelValue="requestedPage"
            @update:modelValue="pageLoad(false)"
        />

      </template>
      <template v-if="!isLoading && items.length <= 0">
        <p class="text-center text-light">{{ $t('flow.executions.noExecutions') }}</p>
        <div class="column col-12">
          <div class="q-mx-auto">
            <q-btn flat dense
                   class="app-action-btn"
                   :label="$t('flow.executions.noExecutionsButton')"
                   :title="$t('flow.executions.noExecutionsButton')"
                   to="/flow"
            />
          </div>
        </div>
      </template>
    </div>
  </q-page>
</template>

<style lang="scss">
  .app-flow-execution-table {
    td:first-child {
      max-width: 1rem;
      background-color: $background2;
    }
    th:first-child {
      background-color: $background;
    }
    th:first-child, td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }
    .app-to-detail-btn {
      img {
        font-size: 13px;
        margin: 0 .25rem;
      }
      span {
        font-size: 13px;
      }
    }
    @media (max-width: $breakpoint-sm) { // Attention: max-width, since definition only required for small screen. Default on desktop is good.
      th, td {
        padding: 0 .3rem;
      }
    }
  }

  body.body--dark {
    .app-flow-execution-table {
      td:first-child {
        max-width: 1rem;
        background-color: $dark-page;
      }
      th:first-child, td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
      }
      th:first-child {
        background-color: $dark-page;
      }
      .app-to-detail-btn {
        .q-icon {
          -webkit-filter: invert(1);
                  filter: invert(1);
        }
        .span {
          color: white
        }
      }
    }
  }
</style>
