<script>
  import { defineComponent, ref } from 'vue'
  import { mapGetters } from 'vuex'
  import FileSystem from './components/FileSystem'
  import FileManager from './components/FileManager'

  export default defineComponent({
    name: 'Browser',
    components: {
      FileSystem,
      FileManager
    },
    setup () {

    },
    data () {
      return {
        path: typeof this.$route.params.path !== "undefined" ? "/" + this.$route.params.path: "/",
        filesystemName: ""
      };
    },
    computed: {
      ...mapGetters('fileSystem', [
        'isLoading',
        'isRefreshing'
      ])
    },
    methods: {
      updatePath(path) {
        this.path = path;
      },
      updateFilesystem(filesystem) {
        this.filesystemName = filesystem;
        this.updatePath(filesystem);
      }
    }
  })
</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">
      <h1>{{ $t('browser.headline') }}</h1>
      <p>{{ $t('browser.desc') }}</p>

      <!--<div class="flex justify-center items-center" v-if="isLoading" style="min-height: 100px;"><loading-indicator /></div>-->
      <div>
        <div class="col-12 row flex full-width">
          <div class="col q-pr-md">
            <q-input
                class="app-browser-path"
                :disable="true"
                dense outlined
                v-model="path"
            />
          </div>
          <!--<div class="col-3 flex row">
            <div class="col q-pr-md">
              <q-input
                      v-model="filterModel"
                      @update:model-value="getFilterMatches()"
                      :placeholder="$t('browser.filter')"
                      debounce="500"
                      dense
              >
                <template v-slot:prepend>
                  <q-icon name="search" />
                </template>
                <template v-slot:append>
                  <q-icon name="close" v-if="filterModel !== ''" @click="filterModel = ''" class="cursor-pointer" />
                </template>
              </q-input>
            </div>
            &lt;!&ndash;<div class="col-auto"><q-btn flat class="bg-primary text-white" :icon="'settings'" :ripple="false" round /></div>&ndash;&gt;
          </div>-->
        </div>
        <div class="app-browser flex row q-mt-md">
          <div class="app-browser-filetree-wrapper col-12 col-sm-3">
            <file-system @path="updateFilesystem" />
          </div>
          <div class="col-auto q-ma-sm flex"><q-separator vertical /></div>
          <div class="app-browser-files col">
            <file-manager
                    :path="this.path"
                    :filesystem-name="filesystemName"
                    v-if="path !== '/'"
                    @path="updatePath"
            />
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<style lang="scss">
 .app-browser-path {
   background-color: $background2;
 }
 body.body--dark {
   .app-browser-path {
     background-color: $dark-page;
   }
 }
</style>
