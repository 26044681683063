<script>
  import { defineComponent, ref } from 'vue'
  import JsonEditor from 'components/JsonEditor.vue'
  import LoadingIndicator from 'components/LoadingIndicator'
  import JobDispatcherMappingModal from '../JobDispatcherMappingModal/JobDispatcherMappingModal'

  export default defineComponent({
    name: 'JdmItem',

    components: {
      LoadingIndicator,
      JsonEditor,
      JobDispatcherMappingModal
    },

    props: ['jdm', 'flow'],

    emits: ['reload'],

    data: function () {
      return {
        showJdmEditModal: false,
        triggerModal: '',
        editJdmModal: ref(false),
        localJdmDispatchCondition: JSON.stringify(this.jdm.dispatchCondition, null, 2),
        localJdmModuleConfig: JSON.stringify(this.jdm.configuration, null, 2),
      }
    },
    methods:{
      handleJdmUpdated(data) {
        this.showJdmEditModal = false

        this.$emit('reload', data)
      },

      highlighter(code) {
        return highlight(code, languages.js); // languages.<insert language> to return html with markup
      }
    },
    computed: {
      localDispatchConditionFormatted: {
        get: function() {
          return this.localJdmDispatchCondition;
        },
        set: function(val) {
          this.localJdmDispatchCondition = val;
        }
      },
      localModuleConfigFormatted: {
        get: function() {
          return this.localJdmModuleConfig;
        },
        set: function(val) {
            this.localJdmModuleConfig = val;
        }
      }
    }
  })
</script>

<template>
  <div
    class="app-jdm-item q-mb-md flex row q-pa-sm app-bg2"
    @click="typeof $props.jdm.id !== 'undefined' ? showJdmEditModal = true : null"
    data-cy="buttonEditJdmModal"
  >
    <div class="col-auto q-px-sm flex center text-h6">{{ jdm.id }}</div>
    <div class="col q-px-sm">
      <div class="app-text-weight-semibold app-text-break-all">{{ $props.jdm.messageFqcn }}</div>
      <div>{{ $t('flow.overview.detail.modal.dispatchCondition') }}: {{ $props.jdm.dispatchCondition }}</div>
    </div>

    <job-dispatcher-mapping-modal
      v-model="showJdmEditModal"
      :job-dispatcher-mapping="jdm"
      :flow="flow"
      @updated="handleJdmUpdated"
    />
  </div>
</template>

<style lang="scss">
  .app-jdm-item {
    background: $background2;
    border: $layout-border;
    border-radius: $generic-border-radius;
    &:hover {
      border: 1px solid $secondary;
      cursor: pointer;
      z-index: 1;
    }
  }
  body.body--dark {
    .app-jdm-item {
      background: $dark;
      border: 1px solid $separator-dark-color
    }
  }
</style>
