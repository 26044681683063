import { render } from "./ViewFileModal.vue?vue&type=template&id=17825e6c"
import script from "./ViewFileModal.vue?vue&type=script&lang=js"
export * from "./ViewFileModal.vue?vue&type=script&lang=js"

import "./ViewFileModal.vue?vue&type=style&index=0&id=17825e6c&lang=scss"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QImg,QBtn});qInstall(script, 'directives', {ClosePopup});
