<script>
import VueApexCharts from 'vue3-apexcharts'
import CustomChartLegend from "components/Charts/Legend/CustomChartLegend";
import SelectInputFilter from "components/Charts/Filter/SelectInputFilter";

export default {
  name: "MostSeenEntitiesChart",

  components: {
    VueApexCharts,
    CustomChartLegend,
    SelectInputFilter
  },
  data() {
    return {
      legendData: [],
      filters: {
        documentClass: [],
      },
      entityTypes: [],
      loading: false,
      options: {
        legend: {
          show: true,
          position: 'left',
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            show: false
          }
        },
        colors: ['#3363FF', '#332EE8', '#7940FF', '#992EE8', '#DF33FF'],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        }
      },
      series: [
        {
          data: []
        }
      ],
    }
  },

  created() {
    this.getMostSeenEntities()
    this.getEntityListFilter()
  },

  methods: {
    getMostSeenEntities(params = null) {
      this.loading = true;
      this.$api.validationInfo.getMostSeenEntities(params)
        .then(res => {
          if (res.data) {
            const mostSeenEntitiesData = res.data['hydra:member']
            this.series[0].data = []
            this.legendData = [];

            mostSeenEntitiesData.forEach((data, index) => {
              this.series[0].data.push({x: " "+data['count'], y: data['count']});
              this.legendData.push({
                color: this.options.colors[index],
                description: data['count'] + ": " + data['documentId'],
                url: '#/documents/' + data['collection'] + '?page=1&filter={"_id":"' + data['documentId'] + '"}'
              })
            })

          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },

    getEntityListFilter() {
      this.loading = true;

      this.$api.entityAPI.list()
        .then(res => {
          const entityListData = res.data['hydra:member']
          entityListData.forEach(data => {
            this.entityTypes.push(data.collectionName)
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false;
        })
    },

    updateEntityType(newEntities) {
      this.filters.documentClass = newEntities;
    }
  },

  watch: {
    filters: {
      handler: function (newFilters) {
        this.getMostSeenEntities({ documentClass: newFilters.documentClass });
      },
      deep: true
    }
  }
}
</script>


<template>
  <div class="q-pa-md most-seen-entities-chart">

    <div class="column items-center">
      <p class="chart-header">Entities with most Validation Messages</p>

      <div class="chart-filter">
        <select-input-filter v-on:updateModel="updateEntityType" type="multiple"  :options="entityTypes" label="Entity Type" />
      </div>
    </div>

    <div class="row justify-center items-center">
      <div class="col-xl-4 self-center">
        <vue-apex-charts
          ref="mostSeenChart"
          type="treemap"
          :options="options"
          :series="series"
          class="validation-graph q-pl-sm"
        />
      </div>

      <div class="col-xl-4 self-center">
        <custom-chart-legend :legend-data="legendData" />
        <q-inner-loading
          :showing="loading"
          color="primary"
        >
          <q-spinner
            color="primary"
            size="42px"
          />
          <span class="text-primary text-weight-medium">Loading data...</span>
        </q-inner-loading>
      </div>
    </div>

    <div class="absolute-center" v-if="legendData.length === 0 && !loading">
      <span class="text-primary text-weight-medium">No Data</span>
    </div>
  </div>
</template>

<style lang="scss">
  .most-seen-entities-chart {
    min-height: 400px;
  }
</style>
