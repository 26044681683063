export function filemanagerLoad(state, data) {
  state.filemanager = data
  state.isLoading = false;
  state.isRefreshing = false;
}

export function blockManager(state) {
  state.isRefreshing = true;
}

export function freePage(state) {
  state.isRefreshing = false;
}

export function fileLoad(state, data) {
  state.fileContent = data;
  state.isRefreshing = false;
}

export function fileClear(state) {
  state.fileContent = null;
}
