import validationInfo from 'src/services/Api/validationInfo'
import entityAPI from 'src/services/Api/entityAPI'
import failedMessages from 'src/services/Api/failedMessages'
import filesystem from 'src/services/Api/filesystem'

// register collections
export default {
  validationInfo,
  entityAPI,
  failedMessages,
  filesystem
}
