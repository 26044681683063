<script>
import { ref } from 'vue'
import { ucwords } from 'src/utils/stringUtils'
import severityLevels from 'src/meta/severityLevels'
import SelectInputFilter from "components/Charts/Filter/SelectInputFilter";

export default {
  name: "ValidationInfoIndex",

  components: {
    SelectInputFilter
  },

  setup() {
    const columns = [
      {
        name: 'index',
        field: 'index',
        label: '#',
        align: 'left',
        style: 'width: 20px',
        classes: 'ellipsis'
      },
      {
        name: 'severityLevel',
        field: (row) => ucwords(row.severityLevel),
        label: 'Severity Level',
        align: 'left',
        classes: 'ellipsis'
      },
      {
        name: 'document',
        label: 'Document',
        align: 'left',
        style: 'white-space: pre',
        classes: 'ellipsis'
      },
      {
        name: 'translatedTechnicalIdentifier',
        field: (row) => {
          return row.translatedTechnicalIdentifier + '\n' + `Field: ${row.fieldPath}`
        },
        label: 'Message',
        align: 'left',
        style: 'white-space: pre',
        classes: 'ellipsis'
      }
    ]

    const pagination = ref({
      page: 1,
      rowsPerPage: 25,
      rowsNumber: 0,
      paginator: null
    });

    return {
      columns,
      pagination,
      severityLevels
    }
  },

  data() {
    return {
      filterToggle: {
        severityLevels: [],
        documentClass: [],
        search: ''
      },
      loading: false,
      validationInfo: [],
      entityTypes: [],
    }
  },

  created() {
    this.getEntityListFilter()
    this.getValidationList({
      page: this.pagination.page,
      itemsPerPage: this.pagination.rowsPerPage
    })
  },

  computed: {
    filter() {
      // console.log(this.filterToggle);

      return {
        severityLevels: this.filterToggle.severityLevels,
        search: this.filterToggle.search,
        documentClass: this.filterToggle.documentClass
      }
    }
  },

  methods: {
    updateEntityType(newType) {
      this.filterToggle.documentClass = newType;
    },

    getEntityListFilter() {
      this.loading = true;

      this.$api.entityAPI.list()
        .then(res => {
          const entityListData = res.data['hydra:member']
          entityListData.forEach(data => {
            this.entityTypes.push(data.collectionName)
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false;
        })
    },

    viewDocumentInfo(row) {
      let urlFragment = row.documentClass.split('\\').slice(-1) + '?page=1&filter={"_id":"' + row.documentId + '"}';
      this.$router.push('/documents/' + urlFragment)
    },
    getValidationList(params = {}) {
      this.loading = true
      this.$api.validationInfo.list(params)
        .then(res => {
          if (res.data) {
            this.validationInfo = res.data['hydra:member']
            this.pagination.rowsNumber = res.data['hydra:totalItems']
            this.pagination.paginator = res.data['hydra:view']
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },

    handlePaginationUpdate(pagination) {
      this.pagination = pagination
    },

    handleTableRequest(properties) {
      if (properties.pagination) {
        this.pagination.page = properties.pagination.page
        this.pagination.rowsPerPage = properties.pagination.rowsPerPage
        this.pagination.rowsNumber = properties.pagination.rowsNumber
        let params = {
          page: this.pagination.page,
          itemsPerPage: this.pagination.rowsPerPage,
          severityLevel: this.filterToggle.severityLevels,
          documentId: this.filterToggle.search,
          documentClass: this.filterToggle.documentClass,
        };

        this.getValidationList(params)
      }
    },
  }
}
</script>

<template>
  <q-page class="block q-pa-md validation-list">
    <div class="q-mb-md">
      <h1>{{ $t('validation.headline') }}</h1>
      <p>{{ $t('validation.desc') }}</p>
    </div>
    <q-card flat>
    <q-card-section>
    <q-table
      flat
      dense
      virtual-scroll
      row-key="id"
      color="primary"
      :rows="validationInfo"
      :columns="columns"
      :pagination="pagination"
      :rows-per-page-options="[5, 10, 15, 25, 50]"
      :loading="loading"
      :filter="filter"
      class="col-12 validation-table q-pa-sm"
      @update:pagination="handlePaginationUpdate"
      @request="handleTableRequest"
    >

      <template v-slot:top>
        <div style="width: 100%" class="row flex justify-start items-center">
          <div class="severity-filter row justify-between q-pb-sm q-mr-lg">
            <q-toggle v-for="(severityColor, index) of severityLevels.colors" v-model="filterToggle.severityLevels" :val="index">
              <q-badge
                outline
                :color="severityColor"
                :label="index"
              />
            </q-toggle>
          </div>
          <div class="row">
            <div class="entity-filter q-pb-sm col-12 col-sm-auto q-mr-md">
              <select-input-filter
                  class="flex justify-center items-center validation-table-filter"
                  v-on:updateModel="updateEntityType" type="multiple"
                  :options="entityTypes"
                  label="Document Type"
              />
            </div>
            <div class="search-filter col-12 col-sm-auto q-pb-sm">
              <q-input dense
                       debounce="400"
                       color="primary"
                       v-model="filterToggle.search"
                       placeholder="Document ID"
                       style="min-width: 150px !important;">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
          </div>
        </div>
      </template>

      <template #body-cell-index="scope">
        <q-td class="font-500 ellipsis" :props="scope">
          {{ scope.rowIndex + 1 }}
        </q-td>
      </template>

      <template #body-cell-severityLevel="scope">
        <q-td class="font-500 ellipsis" :props="scope">
          <q-badge
            outline
            :color="severityLevels.colors[scope.row.severityLevel]"
            :label="scope.value"
          />
        </q-td>
      </template>

      <template #body-cell-document="scope">
        <q-td class="font-500 ellipsis cursor-pointer" :props="scope" @click="viewDocumentInfo(scope.row)">
          {{ scope.row.documentClass.split('\\').slice(-1) + '\n' + scope.row.documentId }}
        </q-td>
      </template>

      <template #loading>
        <q-inner-loading
          showing
          color="primary"
        />
      </template>
    </q-table>
    </q-card-section>
    </q-card>
  </q-page>
</template>

<style lang="scss">
.validation-table {
  .severity-filter {
    height: auto;
    .q-toggle {
      min-width: 7rem;
    }
    body.screen--xs & {
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap;
      width: auto;
    }
    .entity-filter {
      height: auto;
      body.screen--xs & {
        width: 100%;
        margin-left: 0;
      }
    }
    .search-filter {
      height: auto;
      body.screen--xs & {
        width: 100%;
        margin-left: 0;
      }
    }
    .validation-table-filter {
      background: none !important;
    }
  }
}
</style>
