<script>
  import { defineComponent } from 'vue'
  import { date } from 'quasar'

  export default defineComponent({
    name: 'DateDisplay',
    props: {
      time: {
        type: String,
        required: true
      },
      time2: {
        type: String,
        required: false
      },
      fixed: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: false
      }
    },
    computed: {
      duration() {
        return this.msToTime(Math.abs(new Date(this.$props.time2).getTime() - new Date(this.$props.time).getTime()))
      }
    },
    methods: {
      msToTime(ms) {
        if(typeof ms === "undefined" || ms === null || ms === 0 || isNaN(ms)) return "-";
        let seconds = (ms / 1000).toFixed(0);
        let minutes = (ms / (1000 * 60)).toFixed(0);
        let hours = (ms / (1000 * 60 * 60)).toFixed(0);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(0);
        if (seconds < 60) return seconds + " " + this.$t('general.time.short.seconds');
        else if (minutes < 60) return minutes + " " + this.$t('general.time.short.minutes');
        else if (hours < 24) return hours + " " + this.$t('general.time.short.hours');
        else return days + " " + this.$t('general.time.short.days');
      },
      timeDiff(fixed) {
        const t = new Date(this.$props.time).toISOString();
        const now = new Date().toISOString();
        if(typeof this.$props.time2 !== "undefined") {
          return this.duration;
        }
        if(fixed) {
          return date.formatDate(t, 'DD.MM.YYYY HH:mm:ss')
        } else {
          if (date.getDateDiff(now, t, 'seconds') < 60) {
            return this.$t('general.time.momentAgo');
          } else
          if (date.getDateDiff(now, t, 'minutes') < 60) {
            return (date.getDateDiff(now, t, 'minutes') === 1)
                ? this.$t('general.time.singular.minutesAgo', {time: date.getDateDiff(now, t, 'minutes')})
                : this.$t('general.time.plural.minutesAgo', {time: date.getDateDiff(now, t, 'minutes')})
                ;
          } else {
            if (date.getDateDiff(now, t, 'hours') < 24) {
              return (date.getDateDiff(now, t, 'hours') === 1)
                  ? this.$t('general.time.singular.hoursAgo', {time: date.getDateDiff(now, t, 'hours')})
                  : this.$t('general.time.plural.hoursAgo', {time: date.getDateDiff(now, t, 'hours')})
            } else {
              return date.formatDate(t, 'DD.MM.YYYY HH:mm:ss')
            }
          }
        }
      }
    }
  })
</script>

<template>
  <span :title="this.$props.label ? $t(this.$props.label, {time: timeDiff(true)}) : timeDiff(true)">
    {{ timeDiff(this.$props.fixed) }}

    <q-tooltip
      anchor="top middle" self="bottom middle"
      v-if='$q.platform.is.mobile'
      class="app-tooltip-mobile"
    >
      {{ this.$props.label ? $t(this.$props.label, {time: timeDiff(true)}) : timeDiff(true) }}
    </q-tooltip>
  </span>
</template>
