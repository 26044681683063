import { authService } from 'src/services';

export function login({ dispatch, commit }, { username, password, redirect }) {
  commit('loginRequest', { username });

  authService.login(username, password)
    .then(
      user => {
        commit('loginSuccess', user);
        commit('preferences/SET_PREFERENCES', user.user_preference, { root: true });
        /* TODO: return promise instead, routing is app concern */
        if(typeof redirect !== "undefined" && redirect !== null && redirect !== "" && redirect !== "/" && !redirect.includes("login")) {
          this.$router.push({path: redirect})
        } else {
          this.$router.push({path: '/dashboard'})
        }
        commit('userInit');
      },
      error => {
        commit('loginFailure', error);
        dispatch('alert/error', error, { root: true });
      }
    );
}

export function expired({ commit }) {
  authService.expired();
  commit('logout');
}

export function logout({ commit }) {
  authService.logout();
  commit('logout');
}

export function requestReset({ dispatch, commit }, mail) {
  commit('pwRequestPending');
  authService.requestReset(mail, (res) => {
    commit('pwRequestDone');
    if(res.status && res.status === 500) {
      //dispatch('alert/error', "Failed sending password recovery mail. Error code " + res.status + ". Mail correct?\nPlease contact the administrator if your mail is correct and this problem persists.", { root: true });
      dispatch('alert/error', "publicPages.passwordReset.requestFail", { root: true });
    }
    else {
      dispatch('alert/success', "publicPages.passwordReset.requestSuccess", { root: true });
    }
  })
}

export function recover({ dispatch, commit }, {  password, passwordConfirm, userId, hash }) {
  if(password !== passwordConfirm) {
    dispatch('alert/error', "publicPages.passwordRecover.pwConfirmFail", { root: true });
  } else if(password.length < 8) {
    dispatch('alert/error', "publicPages.passwordRecover.pwTooShort", { root: true });
  } else {
    authService.setPassword(userId, hash, password, (res) => {
      if(res.status && (res.status === 500 || res.status === 401)) {
        //dispatch('alert/error', "Failed to set new password. Error code " + res.status + ". \nPlease contact the administrator if this problem persists.", { root: true });
        dispatch('alert/error', "publicPages.passwordRecover.requestFail", { root: true });
      }
      else {
        dispatch('alert/success', "publicPages.passwordRecover.requestSuccess", { root: true });
        this.$router.push({path: '/login'})
      }
    })
  }
}
