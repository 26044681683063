import { render } from "./Menu.vue?vue&type=template&id=2c557e52"
import script from "./Menu.vue?vue&type=script&lang=js"
export * from "./Menu.vue?vue&type=script&lang=js"

import "./Menu.vue?vue&type=style&index=0&id=2c557e52&lang=scss"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItemLabel,QImg,QIcon,QDrawer,QItem});
