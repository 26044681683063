import { render } from "./MenuLink.vue?vue&type=template&id=f0aae760"
import script from "./MenuLink.vue?vue&type=script&lang=js"
export * from "./MenuLink.vue?vue&type=script&lang=js"

import "./MenuLink.vue?vue&type=style&index=0&id=f0aae760&lang=scss"
script.render = render

export default script
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QIcon,QItemLabel});
