<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'FlowExecutionBadges',
    props: {
      prop: {
        type: Object,
        required: true
      },
      getNodeProgress: {
        type: Function,
        required: true
      },
      getNodeItems: {
        type: Function,
        required: true
      },
      calcHeaderProgressDifferently: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      getNodeProgressBySum(node) {
        let processedCounts = 0;
        let totalCounts = 0;
        node.children.forEach(child => {
          if(child.processedCount) processedCounts += child.processedCount;
          if(child.totalCount) totalCounts += child.totalCount;
        });
        const result = (Math.floor(((processedCounts * 100) / totalCounts) * 100).toFixed(2) / 100).toString()
        if(isNaN(result)) {
          return this.$props.getNodeProgress(node.processedCount, node.totalCount);
        }
        return result;
      }
    }
  })
</script>

<template>
  <div class="app-flowexecution-value flex inline" style="margin: 0 .5rem;">
    <q-badge
        class="app-flow-status-badge justify-center"
        :class="{'hidden': !calcHeaderProgressDifferently}"
        :color="getNodeProgressBySum(prop.node) === null || getNodeProgressBySum(prop.node)?.toString() === '0' ? 'negative' : getNodeProgressBySum(prop.node) === '100' ? 'positive' : 'warning'"
        :label="getNodeProgressBySum(prop.node) === null ? '-' : + getNodeProgressBySum(prop.node) + '%'"
    />

    <q-badge
        class="app-flow-status-badge justify-center"
        :class="{'hidden': calcHeaderProgressDifferently}"
        :color="getNodeProgress(prop.node.processedCount, prop.node.totalCount) === null || getNodeProgress(prop.node.processedCount, prop.node.totalCount)?.toString() === '0' ? 'negative' : getNodeProgress(prop.node.processedCount, prop.node.totalCount) === '100' ? 'positive' : 'warning'"
        :label="getNodeProgress(prop.node.processedCount, prop.node.totalCount) === null ? '-' : + getNodeProgress(prop.node.processedCount, prop.node.totalCount) + '%'"
    />
    <q-badge
        class="app-flow-status-badge justify-center"
        style="margin: 0 .5rem;"
        :color="getNodeProgress(prop.node.processedCount, prop.node.totalCount) === null || getNodeProgress(prop.node.processedCount, prop.node.totalCount)?.toString() === '0' ? 'negative' : getNodeProgress(prop.node.processedCount, prop.node.totalCount) === '100' ? 'positive' : 'warning'"
        :label="getNodeItems(prop.node.processedCount, prop.node.totalCount) === null ? '-' : getNodeItems(prop.node.processedCount, prop.node.totalCount)"
    />
  </div>
</template>

<style lang="scss">
  .app-flow-status-badge.active {
    border: 1px solid $primary;
  }
</style>
