import { render } from "./FlowModal.vue?vue&type=template&id=5d9f8d71"
import script from "./FlowModal.vue?vue&type=script&lang=js"
export * from "./FlowModal.vue?vue&type=script&lang=js"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QForm,QInput});
