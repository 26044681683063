<script>
  import { ref, defineComponent } from 'vue'
  import { useQuasar } from 'quasar'
  import {flowService} from "src/services";

  import DateDisplay from 'components/DateDisplay.vue'
  import FlowItemDetails from './FlowItemDetails.vue'
  import DeleteFlowModal from './FlowModal/DeleteFlowModal'
  import FlowModal from './FlowModal/FlowModal'
  import EndFlowModal from './FlowModal/EndFlowModal'
  import ExportFlowModal from "./FlowModal/ExportFlowModal";

  export default defineComponent({
    name: 'FlowItem',

    props: ['flow', 'disabled', 'isLoading'],

    emits: ['running', 'refresh'],

    components: {
      FlowModal,
      DateDisplay,
      EndFlowModal,
      ExportFlowModal,
      DeleteFlowModal,
      FlowItemDetails
    },

    setup () {
      const $q = useQuasar();

      const showFlowModal = ref(false)
      const showDeleteFlowModal = ref(false)
      const showExportFlowModal = ref(false)
      const showEndFlowModal = ref(false)

      return {
        showFlowModal,
        showDeleteFlowModal,
        showExportFlowModal,
        showEndFlowModal,
        qInstance: $q,
        flowActionMenu: ref(false)
      }
    },
    methods: {
      goToDetail (flowId, newTab) {
        const url = '/flow/' + flowId + '/configuration';
        if(newTab) window.open('#' + url, '_blank').focus();
        else this.$router.push(url);
      },
      setFlowCardDisabled({flowId, disable}) {
        /*if(typeof this.$store.state?.items !== 'undefined') {
          this.$store.state.items.forEach((value, key) => {
            if (value.id === flowId) {
              this.$store.state.items[key]['disableListItem'] = disableState;
            }
          });
        }
        this.$store.state.isRefreshing = disableState; //When disabling => refreshing - When (re)enabling => refresh done*/
      },
      startFlow() {
        //this.$store.commit('activateFlowCheck');
        //this.setFlowCardDisabled({flowId: this.flow.id, disable: true})
        flowService.startFlow(this.flow.id, (data) => {
          if(data.success !== 0) {
            this.$store.dispatch('alert/success', "flow.flowStartedSuccess", { root: true });
            this.$emit('refresh');
          } else {
            this.$store.dispatch('alert/error', data.msg, { root: true });
          }
          //this.setFlowCardDisabled({flowId: this.flow.id, disable: false})
        })
      },

      handleFlowEnded(data) {
        this.showEndFlowModal = false

        this.$emit('refresh', data)
      },

      handleUpdated() {
        this.showFlowModal = false

        this.$emit('refresh')
      },

      handleDeleted() {
        this.showDeleteFlowModal = false

        this.$emit('refresh', {type: "delete"})
      }
    }
  })
</script>

<template>

  <div class="col-12 app-flow-item">

<!--
@click="this.flow.disableListItem ? false : goToDetail(flow.id)"
 -->
    <q-card
        class="col-12 app-flowcard q-mb-md"
        flat
        :disabled="disabled"
        v-bind:data-cy="'flowItem' + flow.id"
        @click="flow.disableListItem ? false : goToDetail(flow.id)"
        @click.middle="flow.disableListItem ? false : goToDetail(flow.id, true)"
    >
      <q-item>
        <q-item-section>
          <div class="flex row full-width justify-between items-center q-py-sm">
            <div class="full-width row justify-between items-center row">
              <q-item-label class="app-flowcard-label col">{{ flow.name }}</q-item-label>
              <q-card-actions class="flex justify-end q-pa-none col-auto app-flow-item-action-menu" @click.stop=""> <!-- empty click.stop necessary to prevent click event from parent card -->

                <q-btn v-if="!flow.isRunning"
                       flat dense
                       class="app-flowcard-btn-start"
                       :label="$t('flow.overview.start')"
                       :disable="isLoading"
                       @click="!flow.isRunning ? startFlow() : null"
                       v-bind:data-cy="'buttonQuickStartFlow' + flow.id"
                >
                </q-btn>
                <q-btn v-if="flow.isRunning"
                       flat dense
                       class="app-flowcard-btn-end"
                       :label="$t('flow.overview.end')"
                       :disable="isLoading"
                       @click="showEndFlowModal = true"
                       v-bind:data-cy="'buttonQuickEndFlow' + flow.id"
                >
                </q-btn>

                <q-btn-dropdown flat no-icon-animation :ripple="false"
                                class="app-flow-menu-dropdown"
                                v-model="flowActionMenu"
                                v-bind:data-cy="'buttonFlowActionMenu' + flow.id"
                                :disable="flow.disableListItem === true || isLoading"
                                :dropdown-icon="'img:' + 'assets/icons/menu.svg'"
                                :title="$t('flow.overview.showMore')"
                >
                  <q-list>
                    <q-item v-if="!flow.isRunning"
                            :clickable="!isLoading" v-close-popup
                            class="app-flowcard-btn-start"
                            @click="!flow.isRunning ? startFlow() : null"
                            v-bind:data-cy="'buttonStartFlow' + flow.id"
                    >
                      <q-item-section>
                        <q-item-label><q-icon name="arrow_right_alt" class="q-mr-sm" /> {{ $t('flow.overview.start') }}</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup
                            class="app-flowcard-btn-end"
                            v-if="flow.isRunning"
                            @click="showEndFlowModal = true"
                            v-bind:data-cy="'buttonEndFlow' + flow.id"
                    >
                      <q-item-section>
                        <q-item-label><q-icon name="do_disturb" class="q-mr-sm" /> {{ $t('flow.overview.end') }}</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup
                            class="app-flowcard-btn-view"
                            :disable="flow.disableListItem"
                            @click="flow.disableListItem ? false : goToDetail(flow.id)"
                            @click.middle="flow.disableListItem ? false : goToDetail(flow.id, true)"
                            v-bind:data-cy="'buttonView' + flow.id"
                    >
                      <q-item-section>
                        <q-item-label><q-icon name="search" class="q-mr-sm" /> {{ $t('flow.overview.view') }}</q-item-label>
                      </q-item-section>
                    </q-item>

                    <!--<q-item clickable v-close-popup
                            class="app-flowcard-btn-add-jdm"
                            @click="createJdmModal(flow.id)"
                            v-bind:data-cy="'buttonAddJdm' + flow.id"
                    >
                      <q-item-section>
                        <q-item-label><q-icon name="add" class="q-mr-sm" /> {{ $t('flow.overview.addJdm') }}</q-item-label>
                      </q-item-section>
                    </q-item>-->

                    <q-item
                      v-close-popup
                      v-bind:data-cy="'buttonExportFlow' + flow.id"
                      clickable
                      :disable="flow.isRunning"
                      class="app-flowcard-btn-export-flow"
                      @click="showExportFlowModal = true"
                    >
                      <q-item-section>
                        <q-item-label><q-icon name="file_upload" class="q-mr-sm" /> {{ $t('flow.overview.exportFlowSingle') }}</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item
                      v-close-popup
                      clickable
                      :disable="flow.isRunning"
                      v-bind:data-cy="'buttonEditFlow' + flow.id"
                      class="app-flowcard-btn-end"
                      @click="showFlowModal = true"
                    >
                      <q-item-section>
                        <q-item-label><q-icon name="edit" class="q-mr-sm" /> {{ $t('flow.overview.renameFlow') }}</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item
                      v-close-popup
                      clickable
                      :disable="flow.isRunning"
                      v-bind:data-cy="'buttonDeleteFlow' + flow.id"
                      class="app-flowcard-btn-end text-negative"
                      @click="!flow.isRunning ? showDeleteFlowModal = true : null"
                    >
                      <q-item-section>
                        <q-item-label><q-icon name="delete" class="q-mr-sm" /> {{ $t('flow.overview.deleteFlow') }}</q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </q-btn-dropdown>

              </q-card-actions>
            </div>
          </div>
          <q-separator />

          <div class="col row-sm justify-between items-center full-width q-mt-sm">
            <flow-item-details :flow="flow"></flow-item-details>
          </div>

        </q-item-section>
      </q-item>
    </q-card>

    <export-flow-modal
      v-model="showExportFlowModal"
      :flow="flow.id"
    />

    <flow-modal
      v-model="showFlowModal"
      :flow="flow"
      @updated="handleUpdated"
    />

    <delete-flow-modal
      v-model="showDeleteFlowModal"
      :flow="flow"
      @deleted="handleDeleted"
    />

    <end-flow-modal
      v-model="showEndFlowModal"
      page="flow"
      :flow-id="flow.lastFlowExecutionId"
      :flow-name="flow.name"
      @success="handleFlowEnded"
    />
  </div>
</template>

<style lang="scss">
.app-flow-item-action-menu {
  .q-btn .q-icon {
    font-size: 1rem;
  }
}
.app-jump-to-execution {
  .q-icon {
    width: .8rem;
    height: .8rem;
  }
}
.app-flow-execution-detail-page,
.app-flow-detail {
  .app-jump-to-execution {
    img.q-icon {
      filter: invert(65%) sepia(83%) saturate(382%) hue-rotate(121deg) brightness(92%) contrast(92%);
    }
  }
}

.app-flow-item {
  .app-jump-to-execution {
    color: $dark;
  }
}
body.body--dark {
  .app-jump-to-execution {
    color: $secondary;
    background-color: mix($dark,$secondary,95%);

    img {
      -webkit-filter: invert(77%) sepia(40%) saturate(823%) hue-rotate(115deg) brightness(90%) contrast(83%);
              filter: invert(77%) sepia(40%) saturate(823%) hue-rotate(115deg) brightness(90%) contrast(83%);
    }
  }
  .app-flow-menu-dropdown .q-icon {
    filter: invert(1);
  }
}
</style>
