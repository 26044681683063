import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'
import alert from './alert';
import authentication from './authentication'
import flows from './flows'
import flowExecution from './flow-executions'
import entities from './entities'
import heartbeats from './heartbeats'
import logs from './logs'
import messageQueues from './messageQueues'
import preferences from './preferences'
import supervisor from './supervisor'
import fileSystem from './file-system'
import fileManager from './file-manager'
import mongoStatistics from './mongo-statistics'
import modules from './modules'
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */
export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      authentication,
      flows,
      flowExecution,
      entities,
      heartbeats,
      logs,
      messageQueues,
      preferences,
      supervisor,
      fileSystem,
      fileManager,
      alert,
      mongoStatistics,
      modules
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})
