import { heartbeatService } from 'src/services'

export function getHeartbeat({ dispatch, commit, state }) {
  commit('startLoading');
  heartbeatService.getHeartbeat(function(data) {
    commit('receivedData', data['hydra:member'])
  })
}

export function startHeartbeat({ dispatch, commit, state }) {
  commit('startReloading');
  heartbeatService.startHeartbeat(function(data) {
    if(typeof data.status !== 'undefined') {
      if(data.status === 500) {
        dispatch('alert/error', data.data['hydra:description'], { root: true });
      }
    } else {
      dispatch('alert/success', 'dashboard.heartbeat.startSuccess', { root: true });
    }
    commit('freeComponent');
  })
}
