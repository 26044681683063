<script>
  import { defineComponent } from 'vue'
  import { useQuasar } from 'quasar'
  import { initialPageHelper, itemsPerPageHelper } from 'src/helpers/pagination-helper'
  import { userManagementService } from 'src/services'
  import LoadingIndicator from 'components/LoadingIndicator'
  import ItemsPerPage from 'components/ItemsPerPage.vue'
  import Pagination from 'components/Pagination.vue'
  import UserModal from './components/UserModal'
  import DeleteUserModal from './components/DeleteUserModal'
  import SqDialog from 'components/Common/SqDialog.vue'

  export default defineComponent({
    name: 'UserManagement',

    components: {
      SqDialog,
      LoadingIndicator,
      ItemsPerPage,
      Pagination,
      UserModal,
      DeleteUserModal
    },

    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q,
        columns: [
          {
            name: 'id',
            required: true,
            label: 'ID',
            align: 'left',
            field: row => row['@id'].split("/").pop(),
            format: val => `${val}`
          },
          {
            name: 'name',
            required: true,
            label: 'Name',
            align: 'left',
            field: row => row.display_name,
            format: val => `${val}`
          },
          {
            name: 'email',
            required: true,
            label: 'E-Mail',
            align: 'left',
            field: row => row.email,
            format: val => `${val}`
          },
          {
            name: 'action',
            required: true,
            label: '',
            align: 'left',
            field: 'action'
          }
        ]
      }
    },
    data () {
      return {
        showUserModal: false,
        showDeleteConfirmation: false,
        selectedUser: null,
        totalItems: 0,
        userManagementList: [],
        isLoading: true,
        requestedPage: null,
        requestedItemsPerPage: null,
        form:{
          code:""
        },
        user: [
          {
            name: 'id',
            required: true,
            label: 'ID',
            align: 'left',
            field: row => row['@id'].split("/").pop(),
            format: val => `${val}`
          },
          {
            name: 'name',
            required: true,
            label: 'Name',
            align: 'left',
            field: row => row.userName,
            format: val => `${val}`
          },
          {
            name: 'email',
            required: true,
            label: 'E-Mail',
            align: 'left',
            field: row => row.userMail,
            format: val => `${val}`
          },
          {
            name: 'action',
            required: true,
            label: '',
            align: 'left',
            field: 'action'
          }
        ],
      }
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.requestedItemsPerPage);
      }
    },
    mounted() {
      this.requestedPage = initialPageHelper(this.$route.query);
      this.requestedItemsPerPage = itemsPerPageHelper(this.$route.query);

      this.pageLoad();
    },
    methods: {
      changePage (newPage) {
        this.requestedPage = newPage;
      },

      handleSaved() {
        this.showUserModal = false

        if (!this.selectedUser) {
          if(this.totalItems > 0 && (this.totalItems % this.requestedItemsPerPage === 0)) {
            //  Creating first item or new page
            this.changePage(this.totalPages + 1);
          }
          else {
            if(!(this.totalItems === 0 || (this.requestedPage === this.totalPages))) {
              // Currently on any previous page, jump to last page, since new created item is there
              this.changePage(this.totalPages);
            }
          }
        }

        this.pageLoad()

        this.selectedUser = null
      },

      updateUser(evt, user) {
        this.selectedUser = user
        this.showUserModal = true
      },

      deleteUser(user) {
        if (user.email === this.$store.state.authentication.user.username) this.$store.dispatch('alert/error', this.$t('user.deleteUserSelfError'), { root: true });

        this.showDeleteConfirmation = true
        this.selectedUser = user
      },

      handleDeleted(data) {
        this.showDeleteConfirmation = false
        this.selectedUser = null

        if (data?.deleted === true) {
          // DELETE PROCESS
          if (this.userList.length === 1) {
            if (this.requestedPage > 1) {
              // No items on current page available anymore - go to previous page
              this.changePage(this.requestedPage - 1);
            } else {
              // Last available item deleted - reload page. "No items available" placeholder should show
              this.changePage(1);
            }
          }
        }
        this.pageLoad();
      },

      handleHide() {
        this.selectedUser = null
      },

      pageLoad() {
        this.isLoading = true;
        this.$router.push({query: {p: this.requestedPage, i: this.requestedItemsPerPage } });
        userManagementService.getUsers(this.requestedPage, this.requestedItemsPerPage, (data) => {
          // Update page
          if(data['hydra:member']?.length > 0 ) {
            this.userList = data['hydra:member'];
            this.totalItems = data['hydra:totalItems'];
            this.isLoading = false;
          } else {
            // There are no data available on current page anymore. Reload, jump to last one with data.
            if(data['hydra:totalItems'] > 0 && this.requestedPage !== 1) {
              this.totalItems = data['hydra:totalItems'];
              this.requestedPage = this.totalPages;
              this.pageLoad();
            } else {
              // There are no items at all. Nothing to do here.
              // Need to empty the list Array when deleting the last available item though
              this.userList = [];
              this.isLoading = false;
            }
          }
        })
      },

    }
  })

</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">
      <h1>{{ $t('user.headline') }}</h1>
      <p>{{ $t('user.desc') }}</p>

      <div class="full-width flex justify-end q-mb-sm">
        <q-btn
          v-if="!isLoading"
          flat
          dense
          icon-right="add"
          :label="$t('user.addUser')"
          :title="$t('user.addUser')"
          class="app-action-btn q-pa-sm"
          @click.capture.stop='showUserModal = true'
        />
      </div>

      <loading-indicator v-if="isLoading" wrapper />
      <div v-if="!isLoading">
        <q-card flat>
        <q-card-section>
        <!-- :row-key="userList.id" -->
        <div class="row q-pa-sm" v-if="!isLoading">
          <q-table class="app-user-management-table col-12 transparent"
                   :rows="userList"
                   :row-key="userList.id"
                   :columns="this.columns"
                   @row-click="this.updateUser"
                   color="primary"
                   :hide-bottom=true
                   :hide-pagination=true
                   :loading="isLoading"
                   :rows-per-page-options="[this.requestedItemsPerPage]"
                   flat
          >
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <div class="flex no-wrap">
                  <q-btn
                    icon-right="edit"
                    size="12px" flat dense
                    :label="$q.platform.is.mobile ? $t('user.updateUser') : null"
                    :title="$t('user.updateUser')"
                    :disable="isLoading"
                    @click.capture.stop="this.updateUser(null, props.row)"
                  />

                  <q-btn v-if="props.row"
                    icon-right="delete"
                    size="12px" no-caps flat dense
                    class="q-ml-sm"
                    :class="this.$store.state.authentication.user.username == props.row.email ? 'disabled' : 'negative-hover'"
                    :disable="isLoading"
                    :label="$q.platform.is.mobile ? $t('user.deleteUser') : null"
                    :title="this.$store.state.authentication.user.username == props.row.email ? $t('user.deleteUserSelfError'): $t('user.deleteUser')"
                    @click.capture.stop="this.deleteUser(props.row)"
                  />
                </div>
              </q-td>
            </template>
          </q-table>
        </div>
        </q-card-section>
        </q-card>
        <items-per-page
            v-model:modelValue="requestedItemsPerPage"
            @update:modelValue="pageLoad"
            label="user.itemsPerPage"
        />

        <pagination
            :pages="totalPages"
            v-model:modelValue="requestedPage"
            @update:modelValue="pageLoad"
        />

      </div>
    </div>

    <user-modal
      v-model="showUserModal"
      :user="selectedUser"
      :total-pages="totalPages"
      :items-per-page="requestedItemsPerPage"
      @saved="handleSaved"
      @hide="handleHide"
    />

    <delete-user-modal
      v-model="showDeleteConfirmation"
      :user="selectedUser"
      @deleted="handleDeleted"
    />
  </q-page>
</template>
