import { render } from "./ValidationInfoIndex.vue?vue&type=template&id=c09f7f7a"
import script from "./ValidationInfoIndex.vue?vue&type=script&lang=js"
export * from "./ValidationInfoIndex.vue?vue&type=script&lang=js"

import "./ValidationInfoIndex.vue?vue&type=style&index=0&id=c09f7f7a&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCard,QCardSection,QTable,QToggle,QBadge,QInput,QIcon,QTd,QInnerLoading});
