<script>
  import { defineComponent, ref } from 'vue'
  import JsonEditor from 'components/JsonEditor.vue'
  import DateDisplay from 'components/DateDisplay.vue'
  import SqDialog from 'components/Common/SqDialog.vue'

  export default defineComponent({
    name: 'EntityListingRow',
    components: {
      SqDialog,
      DateDisplay,
      JsonEditor
    },
    props: {
      row: {
        required: true,
        type: Object
      },
      totalItems: {
        required: true,
        type: Number
      },
      parentWidth: {
        required: true,
        type: String
      }
    },
    emits: ['deleted'],
    inject: ['rowDeleted'],
    data () {
      return {
        test: "",
        deleting: false,
        updating: false,
        isExpanded: this.$props.totalItems === 1,
        isInvalid: false,
        deleteRowModal: ref(false),
        localRow: {...this.row},
        editorHeight: 0,
        minHeight: 80, // in px. 1 row = ~20px
        _document: JSON.stringify(this.row.document, null, 2)
      }
    },
    computed: {
      document: {
        get() {
          return this._document;
        },
        set(value) {
          this._document = value;
        }
      }
    },
    methods: {
      async deleteRow() {
        try {
          this.deleting = true

          const { data } = await this.$api.entityAPI.deleteEntity(this.row.uuid)

          this.rowDeleted(data?.deletedDocuments ? this.row : false)
        } catch (error) {
          console.error('Failed to delete entity.', error)
        } finally {
          this.deleting = false
        }
      },
      async saveRow() {
        try {
          this.updating = true

          await this.$api.entityAPI.updateEntity(this.row.uuid, this.document)
        } catch (error) {
          console.error('Failed to update entity.', error)
        } finally {
          this.updating = false
        }
      },
      contextMenu(event) {
        event.preventDefault();
      },
      updateHeight() {
        this.editorHeight = this.$refs.editor.clientHeight;
      },
      quickSave(event) {
        if ((event.key === 's' || event.key === 'Enter') && (event.ctrlKey || event.metaKey)) {
          event.preventDefault()
          this.saveRow();
        }
      },
      collapse() {
        this.isExpanded = !this.isExpanded
      },
      handleValidChange(value) {
        this.isInvalid = !value
      }
    },
    watch: {
      // Trigger auto expand when filter returns only one item
      totalItems: function () {
        if(this.$props.totalItems === 1) this.isExpanded = true;
      }
    },
  })
</script>

<template>
  <div
      class="app-entity-listing-row-container row q-mb-md q-pa-sm justify-between items-center"
      :class="{ 'blocked': deleting || updating }"
      v-if="localRow?.document"
      @click='collapse'
  >
    <div class="col-6 col-md-3 q-px-sm q-mb-sm q-mb-md-none">
      <span v-if="localRow?.document?.identifier">
        {{ localRow.document.identifier }}
      </span>
    </div>
    <div class="col-6 col-md-3 q-px-sm q-mb-sm q-mb-md-none">
      <span v-if="localRow?.document['_id']['$oid']">
        {{ localRow.document['_id']['$oid'] }}
      </span>
    </div>
    <div class="col-6 col-md-3 q-px-sm q-mb-sm q-mb-md-none">
      <date-display
        v-if="localRow?.document?.createdAt"
        :time="localRow.document.createdAt"
        fixed
      />
    </div>

    <div class="col-6 col-md-3 q-px-sm q-mb-sm q-mb-md-none app-entity-listing-row-actions justify-md-end">
      <q-btn
        flat
        dense
        round
        icon="delete"
        :title="$t('general.delete')"
        :disabled="deleting"
        :loading="deleting"
        class="q-mr-xs q-pa-md app-action-btn"
        @click.capture.stop='deleteRowModal = true'
        v-bind:data-cy="'entityDelete' + row.document.identifier"
      />

      <q-btn
        flat
        dense
        round
        icon="save"
        :title="$t('general.save')"
        :disable="updating || isInvalid"
        :loading="updating"
        class="q-mr-xs q-pa-md app-action-btn"
        @click.capture.stop='saveRow'
        v-bind:data-cy="'entitySave' + row.document.identifier"
      />

      <q-btn
        flat
        dense
        round
        :icon="isExpanded ? 'expand_less' : 'expand_more'"
        :title="isExpanded ? $t('entities.buttonCollapse') : $t('entities.buttonExpand')"
        class="q-pa-md app-action-btn"
        @click.capture.stop='collapse'
        v-bind:data-cy="'entityExpand' + row.document.identifier"
      />
    </div>

    <hr v-if="isExpanded" class="full-width">

    <div
      ref="editor"
      v-if="isExpanded"
      class="app-entity-listing-editor-wrapper full-width"
      :class="{ expanded: isExpanded }"
      @click="$event.stopPropagation()"
    >
      <json-editor
        v-model="document"
        wrapped
        :disabled="updating"
        class="app-entity-listing-editor"
        @contextmenu="contextMenu($event)"
        @input="updateHeight($event)"
        @keydown="quickSave($event)"
        @is-valid-json="handleValidChange"
        :max-width="$props.parentWidth"
      />
      <div class="full-width flex inline justify-between items-center q-mt-sm">
        <q-btn
          v-if="isExpanded"
          flat
          dense
          color="primary"
          :label="$t('general.close')"
          :title="$t('general.close')"
          class="q-px-sm"
          @click.capture.stop='collapse'
        />
        <q-btn
          flat
          dense
          :label="$t('general.save')"
          :title="$t('general.save')"
          :disabled="updating || isInvalid"
          :loading="updating"
          class="app-action-btn q-px-lg"
          @click.capture.stop='saveRow'
        />
      </div>
    </div>
  </div>

  <sq-dialog
    v-model="deleteRowModal"
    type="delete"
    :save-button-label="$t('general.delete')"
    :loading="deleting"
    @save="deleteRow"
  >
    <template #title>
      {{ $t('entities.modal.deleteRow') }}
    </template>

    <template #content>
      <div class="q-my-md">
        <span class="q-ml-sm">{{ $t('entities.modal.deleteRowConfirm', {rowId: row.uuid}) }}</span>
      </div>
    </template>
  </sq-dialog>
</template>

<style lang="scss" scoped>
  .app-entity-listing-row-container {
    border: $layout-border;
    background-color: $background2;
    max-width: 100vw;
    word-break: break-word;
    &.blocked {
      cursor: not-allowed;
    }
    &:hover {
      background-color: mix($background2,$secondary,95%);
    }
    &>div:not(.app-entity-listing-row-actions) {
      display: inline-block;
      word-break: break-all;
      line-height: 1;
    }
    &>.app-entity-listing-row-actions {
      max-height: 4rem;
      flex-wrap: nowrap;
      position: sticky;
      top: 4rem;
      left: 3rem;
      z-index: 1;

      button.q-btn {
        padding: 1rem;
      }
    }
    hr {
      border-top: $background2;
      border-bottom: unset;
    }
    .app-entity-listing-editor-wrapper {
      max-height: 5rem;
      &.expanded {
        max-height: unset;
      }
    }
  }

  .app-placeholder-container {
    height: 100px;
    background-color: $background;
  }

  .app-entity-listing-row-actions {
    display: flex;
    flex-wrap: wrap;
  }

  body.body--dark {

    .app-entity-listing-row-container {
      background-color: $dark;
    }

    .app-placeholder-container {
      background-color: $dark-page;
    }
  }
</style>
