<script>
  export default {
    name: 'SqDialog',

    props: {
      model: {
        type: Boolean,
        default: false
      },

      isDraggable: {
        type: Boolean,
        required: false,
        default: false
      },

      type: {
        type: String,
        required: false
      },

      customClass: {
        type: String,
        required: false
      },

      saveButtonLabel: {
        type: String,
        required: false
      },

      loading: {
        type: Boolean,
        required: false,
        default: false
      },

      loaderText: {
        type: String,
        required: false
      }
    },

    emits: ['save', 'show', 'hide'],

    data() {
      return {

      }
    },

    computed: {
      modalConfiguration() {
        const config = {
          toolbarIcon: 'add',
          color: 'primary'
        }

        if (this.type === 'update') {
          config.toolbarIcon = 'edit'
        }

        if (this.type === 'delete') {
          config.toolbarIcon = 'delete'
          config.color = 'negative'
        }

        if (this.type === 'cancel') {
          config.toolbarIcon = 'cancel'
          config.color = 'negative'
        }

        if (this.type === 'import') {
          config.toolbarIcon = 'arrow_downward'
        }

        if (this.type === 'export') {
          config.toolbarIcon = 'arrow_upward'
        }

        if (this.type === 'viewFile') {
          config.toolbarIcon = 'insert_drive_file'
        }

        return config
      }

    },

    methods: {
      handleSave() {
        this.$emit('save')
      },

      handleShow() {
        this.$emit('show')
      },

      handleHide() {
        this.$emit('hide')
      },

      handleKeypress() {

      }
    }
  }
</script>

<template>
  <q-dialog
    ref="sqDialogRef"
    :persistent="loading"
    @show="handleShow"
    @hide="handleHide"
    @keydown="handleKeypress"
  >
    <q-card class="inner-container">
      <slot name="toolbar">
        <q-toolbar class="q-my-sm">
          <q-toolbar-title class="flex items-center">
            <q-avatar
              :icon="modalConfiguration.toolbarIcon"
              :color="modalConfiguration.color"
              class="q-mr-sm"
              text-color="white"
            />
            <slot name="title"></slot>
          </q-toolbar-title>

          <q-btn
            v-close-popup
            flat
            round
            dense
            icon="close"
          />
        </q-toolbar>
      </slot>

      <q-separator />

      <q-card-section class="q-mx-md">
        <slot name="content" />
      </q-card-section>

      <q-separator />

      <q-card-actions
        align="right"
        class="q-pt-md"
      >
        <slot name="actions">
          <q-btn
            v-close-popup
            :label="$t('general.cancel')"
            @click="handleHide"
          />

          <slot name="saveButton">
            <q-btn
              :color="modalConfiguration.color"
              :label="saveButtonLabel || $t('general.save')"
              @click="handleSave"
            />
          </slot>
        </slot>
      </q-card-actions>

      <q-inner-loading
        :showing="loading"
        color="primary"
      >
        <q-spinner
          color="primary"
          :thickness="3"
          size="38px"
        />

        <span v-if="loaderText" class="text-primary text-weight-medium">{{ loaderText }}</span>
      </q-inner-loading>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
  .inner-container {
    min-width: 40%;
  }
</style>
