import { render } from "./AddFileModal.vue?vue&type=template&id=134aef99"
import script from "./AddFileModal.vue?vue&type=script&lang=js"
export * from "./AddFileModal.vue?vue&type=script&lang=js"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QInput,QTabs,QTab,QTabPanels,QTabPanel});
