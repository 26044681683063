import { render } from "./Supervisor.vue?vue&type=template&id=45a70ccb"
import script from "./Supervisor.vue?vue&type=script&lang=js"
export * from "./Supervisor.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QIcon,QItem,QItemSection,QItemLabel,QCardSection,QTable,QBtn});
