<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { exportFile } from 'quasar'
import { flowService } from 'src/services'

import JsonEditor from 'components/JsonEditor.vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent ({
  name: 'ExportFlowModal',

  props: {
    flow: {},
    single: {}
  },

  components: {
    SqDialog,
    JsonEditor
  },

  emits: ['exported'],

  setup(props, { emit }) {
    const $router = useRouter()
    const $store = useStore()

    const isLoading = ref(false)
    const exportFlow = ref(null)

    const getExport = computed(() => {
      if (exportFlow.value) {
        return JSON.stringify(JSON.parse(exportFlow.value),null,2)
      }

      return null
    })

    function downloadExport() {
      const fileName = props.flow ? JSON.parse(getExport.value)[0].name : window.location.hostname
      const status = exportFile(fileName + '.json', getExport.value, {
        encoding: 'windows-1252',
        mimeType: 'text/csv;charset=windows-1252;'
      })

      if (status === true) {
        $store.dispatch('alert/success', 'flow.overview.modal.exportSuccess', { root: true });

        emit('exported')
      }
      else {
        $store.dispatch('alert/error', 'flow.overview.modal.exportFail', { root: true });
      }
    }

    function fetchFlows() {
      isLoading.value = true

      flowService.exportFlow(props.flow, (data) => {
        isLoading.value = false

        if (data?.['@type'] === "hydra:Error") {
          $router.push({
            name: 'ERROR',
            params: { n: true }
          })
        } else {
          exportFlow.value = data;
        }
      })
    }

    return {
      isLoading,
      exportFlow,
      getExport,

      downloadExport,
      fetchFlows
    }
  }
})

</script>

<template>
  <sq-dialog
    type="export"
    :save-button-label="$t('flow.overview.modal.exportButton')"
    :loading="isLoading"
    @save="downloadExport"
    @show="fetchFlows"
    @hide="exportFlow = null"
  >
    <template #title>
      {{ !single ? $t('flow.overview.modal.exportFlow') : $t('flow.overview.exportFlowSingle') }}
    </template>

    <template #content>
      <div class="text-h6">
        {{ $t('flow.overview.modal.flowsExportText') }}
      </div>

      <json-editor
        v-model="getExport"
        wrapped
        :toolbar-config="['copy']"
        max-height="70vh"
      />
    </template>
  </sq-dialog>
</template>
