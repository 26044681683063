<script>
import { defineComponent, ref, computed } from 'vue'
import { useQuasar } from 'quasar'
import { useStore } from "vuex";
import { flowService } from 'src/services'

import JsonEditor from 'components/JsonEditor.vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent ({
  name: 'ImportFlowModal',

  components: {
    JsonEditor,
    SqDialog
  },

  emits: ['imported'],

  setup (props, { emit }) {
    const $store = useStore()
    const $quasar = useQuasar()

    const importFile = ref(null)
    const importFlowText = ref(null)
    const isLoading = ref(false)

    const canSave = computed(() => importFlowText.value || importFile.value)

    function importFlow() {
      if (!canSave.value) {
        $quasar.notify({
          color: 'negative',
          message: 'No valid data provided.'
        })

        return
      }

      isLoading.value = true;

      flowService.importFlow(importFlowText.value, importFile.value, (data) => {
        isLoading.value = false;
        try {
          Object.entries(data.message).forEach(([key, name]) => {
            setTimeout(() => {
              $store.dispatch('alert/success', { message: 'Flow ' + name + ' successfully imported',  itemUrl: '/flow/' + key + '/configuration' }, { root: true });
            }, 10);
          });
        } catch (e) {
          console.error(e)
        }

        emit('imported', {
          imported: (importFlowText.value || importFile.value) !== null
        })
      });
    }

    function initialize() {
      importFile.value = null
      importFlowText.value = null
      isLoading.value = false
    }

    return {
      importFile,
      importFlowText,
      isLoading,

      initialize,
      importFlow
    }
  }
})
</script>

<template>
  <sq-dialog
    type="import"
    :save-button-label="$t('flow.overview.modal.importButton')"
    :loading="isLoading"
    @save="importFlow"
    @show="initialize"
  >
    <template #title>
      {{ $t('flow.overview.modal.importFlow') }}
    </template>

    <template #content>
      <q-form @submit="importFlow">
        <div class="text-h6 q-mt-md">{{ $t('flow.overview.modal.flowsImportText') }}</div>
        <json-editor
          v-model="importFlowText"
          :disabled="importFile!==null"
          max-height="55vh"
          wrapped
        />

        <div class="row flex-center q-my-md">
          <div class="col"><q-separator /></div>
          <div class="col-auto q-mx-md">{{ $t('general.or').toUpperCase() }}</div>
          <div class="col"><q-separator /></div>
        </div>
        <div class="text-h6 q-mt-md">{{ $t('flow.overview.modal.importFile') }} (.csv/.txt/.json)</div>
        <q-file clearable outlined v-model="importFile" accept=".txt, .csv, .json">
          <template v-slot:prepend>
            <q-icon name="attach_file" />
          </template>
        </q-file>
      </q-form>
    </template>
  </sq-dialog>
</template>
