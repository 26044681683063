import { Dark } from 'quasar'

/**
 * Sets the dark mode of the site
 *
 * @param mode {string}
 */
export function setDarkMode(mode) {
  if (mode === "auto") {
    Dark.set("auto");
  } else if(mode === "light") {
    Dark.set(false);
  } else if(mode === "dark") {
    Dark.set(true);
  }
}

/**
 * Retrieve user preferences from localStorage if there are any
 *
 * @returns {null|*}
 */
export function getUserPreferencesFromLocalStorage() {
  let user = localStorage.getItem('user')

  if (user) {
    return JSON.parse(user).user_preference
  }

  return null
}

/**
 * Let's check if we have something in our localStorage for the user's local
 * if we have, let's use that if nothing, we fallback to en-US
 *
 * @returns {string}
 */
export function resolveUserLocale() {
  let user = localStorage.getItem('user')

  if (user) {
    user = JSON.parse(user)
    return user.user_preference?.locale || 'en-US'
  }

  return 'en-US'
}

/**
 * Mutate a preference stored in the localStorage
 *
 * @param prefKey {string}
 * @param value {any}
 */
export function updatePrefInLocalStorage(prefKey, value) {
  let user = localStorage.getItem('user')

  if (user) {
    user = JSON.parse(user)
    if (Object.hasOwn(user, 'user_preference')) {
      user.user_preference[prefKey] = value;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }
}
