import { render } from "./PasswordRecoverRequest.vue?vue&type=template&id=5edaad85"
import script from "./PasswordRecoverRequest.vue?vue&type=script&lang=js"
export * from "./PasswordRecoverRequest.vue?vue&type=script&lang=js"

import "./PasswordRecoverRequest.vue?vue&type=style&index=0&id=5edaad85&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInput,QIcon,QBtn,QField});
