import { render } from "./CommonMessagesChart.vue?vue&type=template&id=a8d64988&scoped=true"
import script from "./CommonMessagesChart.vue?vue&type=script&lang=js"
export * from "./CommonMessagesChart.vue?vue&type=script&lang=js"

import "./CommonMessagesChart.vue?vue&type=style&index=0&id=a8d64988&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-a8d64988"

export default script
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading,QSpinner});
