import { render } from "./UserModal.vue?vue&type=template&id=0129397c&scoped=true"
import script from "./UserModal.vue?vue&type=script&lang=js"
export * from "./UserModal.vue?vue&type=script&lang=js"

import "./UserModal.vue?vue&type=style&index=0&id=0129397c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0129397c"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QField from 'quasar/src/components/field/QField.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QForm,QInput,QIcon,QTooltip,QToggle,QField,QBtn});
