//--- Helper Functions START ---------------------------------------
function msg(state, message) {
  if (typeof message === 'object' && message !== null) {
    state.message = message.message;
    state.itemUrl = message.itemUrl;
  } else {
    state.message = message;
    state.itemUrl = null;
  }
}
//--- Helper Functions END ---------------------------------------

export function success(state, message) {
  state.type = 'alert-success';
  state.color = 'positive';
  msg(state, message);
}

export function error(state, message) {
  state.type = 'alert-danger';
  state.color = 'negative';
  msg(state, message);

}

export function info(state, message) {
  state.type = 'alert-info';
  state.color = 'info';
  msg(state, message);
}

export function clear(state) {
  state.type = null;
  state.message = null;
  state.color = null;
  state.itemUrl = null;
}
