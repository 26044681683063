import { render } from "./DropdownMenu.vue?vue&type=template&id=de9079e4"
import script from "./DropdownMenu.vue?vue&type=script&lang=js"
export * from "./DropdownMenu.vue?vue&type=script&lang=js"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QList,QExpansionItem,QItem});
