import { render } from "./JsonEditor.vue?vue&type=template&id=5ff05fae"
import script from "./JsonEditor.vue?vue&type=script&lang=js"
export * from "./JsonEditor.vue?vue&type=script&lang=js"

import "./JsonEditor.vue?vue&type=style&index=0&id=5ff05fae&lang=scss"
script.render = render

export default script
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QEditor,QBtn,QTooltip});
