<script>
  import { defineComponent } from 'vue'
  import { initialPageHelper, itemsPerPageHelper } from 'src/helpers/pagination-helper'
  import { cronjobService } from 'src/services/cronjobService'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import ItemsPerPage from 'components/ItemsPerPage.vue'
  import Pagination from 'components/Pagination.vue'
  import CronjobModal from './components/CronjobModal'
  import DeleteCronjobModal from './components/DeleteCronjobModal'

  export default defineComponent({
    name: 'Cronjobs',

    components: {
      LoadingIndicator,
      ItemsPerPage,
      Pagination,
      CronjobModal,
      DeleteCronjobModal
    },

    data () {
      return {
        showCronJobModal: false,
        showDeleteConfirmation: false,
        selectedCronJob: null,
        totalItems: 0,
        cronjobList: [],
        isLoading: true,
        requestedPage: null,
        requestedItemsPerPage: null,
        columns: [
          {
            name: 'id',
            required: true,
            label: 'ID',
            align: 'left',
            field: row => row['@id'].split("/").pop(),
            format: val => `${val}`
          },
          {
            name: 'cronExpression',
            required: true,
            label: 'Cron Expression',
            align: 'left',
            field: row => row.cronExpression,
            format: val => `${val}`
          },
          {
            name: 'active',
            required: true,
            label: 'Active',
            align: 'left',
            field: row => this.getActiveStatus(row.active),
            format: val => `${val}`
          },
          {
            name: 'flowId',
            required: true,
            label: 'Flow ID',
            align: 'right',
            field: row => row.flow.id,
            format: val => `${val}`
          },
          {
            name: 'flowName',
            required: true,
            label: 'Flow Name',
            align: 'left',
            field: row => row.flow.name,
            format: val => `${val}`
          },
          {
            name: 'action',
            required: true,
            label: '',
            align: 'left',
            field: 'action'
          }
        ],
        form:{
          code:""
        }
      }
    },

    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.requestedItemsPerPage);
      }
    },

    methods: {
      pageLoad() {
        this.isLoading = true;
        this.$router.push({query: {p: this.requestedPage, i: this.requestedItemsPerPage } });
        cronjobService.getCronjobs(this.requestedPage, this.requestedItemsPerPage, (data) => {
          // Update page
          if(data['hydra:member']?.length > 0 ) {
            this.cronjobList = data['hydra:member'];
            this.totalItems = data['hydra:totalItems'];
            this.isLoading = false;
          } else {
            // There are no data available on current page anymore. Reload, jump to last one with data.
            if(data['hydra:totalItems'] > 0 && this.requestedPage !== 1) {
              this.totalItems = data['hydra:totalItems'];
              this.requestedPage = this.totalPages;
              this.pageLoad();
            } else {
              // There are no items at all. Nothing to do here.
              // Need to empty the list Array when deleting the last available item though
              this.cronjobList = [];
              this.isLoading = false;
            }
          }
        })
      },

      getActiveStatus(val) {
        if(val.toString() === "true") return this.$t('general.yes');
        return this.$t('general.no');
      },

      changePage (newPage) {
        this.requestedPage = newPage;
      },

      handleSaved() {
        this.showCronJobModal = false

        if (!this.selectedCronJob) {
          if (this.totalItems > 0 && (this.totalItems % this.requestedItemsPerPage === 0)) {
            //  Creating first item or new page
            this.changePage(this.totalPages + 1);
          }

          if (!(this.totalItems === 0 || (this.requestedPage === this.totalPages))) {
            // Currently on any previous page, jump to last page, since new created item is there
            this.changePage(this.totalPages);
          }
        }

        this.pageLoad();

        this.selectedCronJob = null
      },

      updateCronjob(evt, cronjob) {
        this.showCronJobModal = true
        this.selectedCronJob = cronjob
      },

      deleteCronjob(cronjob) {
        this.showDeleteConfirmation = true
        this.selectedCronJob = cronjob
      },

      handleDeleted(data) {
        this.showDeleteConfirmation = false
        this.selectedCronJob = null

        if (data?.deleted === true && this.cronjobList.length === 1) {
          this.changePage(this.requestedPage > 1 ? this.requestedPage - 1 : 1)
        }

        this.pageLoad();
      }
    },

    mounted() {
      this.requestedPage = initialPageHelper(this.$route.query);
      this.requestedItemsPerPage = itemsPerPageHelper(this.$route.query);
      this.pageLoad();
    },
  })
</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">
      <h1>{{ $t('cronjob.headline') }}</h1>
      <p>{{ $t('cronjob.desc') }}</p>

      <div class="full-width flex justify-end q-mb-sm">
        <q-btn
          v-if="!isLoading"
          flat
          dense
          icon-right="add"
          :label="$t('cronjob.addCronjob')"
          :title="$t('cronjob.addCronjob')"
          class="app-action-btn q-pa-sm"
          @click.capture.stop="showCronJobModal = true"
        />
      </div>

      <loading-indicator v-if="isLoading" wrapper />

      <div v-if="!isLoading">
        <!-- :row-key="cronjobList.id" -->
        <div v-if="(cronjobList && cronjobList.length > 0)">
        <q-card flat>
        <q-card-section>
          <q-table class="app-cronjobs-table col-12 transparent q-pa-sm"
                   :rows="cronjobList"
                   :row-key="cronjobList.id"
                   :columns="this.columns"
                   @row-click="this.updateCronjob"
                   color="primary"
                   :hide-bottom=true
                   :hide-pagination=true
                   :loading="isLoading"
                   :rows-per-page-options="[this.requestedItemsPerPage]"
                   flat
          >
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <div class="flex no-wrap" :class="{'column': $q.platform.is.mobile}">
                  <q-btn icon-right="edit"
                      size="12px" flat dense
                      :label="$q.platform.is.mobile ? $t('cronjob.updateCronjob') : null"
                      :title="$t('cronjob.updateCronjob')"
                      :disabled="isLoading"
                      @click.capture.stop="this.updateCronjob(null, props.row)"
                  />
                  <q-btn v-if="props.row"
                      icon-right="delete"
                      size="12px" no-caps flat dense
                      class="q-ml-sm negative-hover"
                      :label="$q.platform.is.mobile ? $t('cronjob.deleteCronjob') : null"
                      :title="$t('cronjob.deleteCronjob')"
                      @click.capture.stop="this.deleteCronjob(props.row)"
                  />
                </div>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
        </q-card>
          <items-per-page
              v-model:modelValue="requestedItemsPerPage"
              @update:modelValue="pageLoad"
              label="cronjob.itemsPerPage"
          />
          <pagination
              :pages="totalPages"
              v-model:modelValue="requestedPage"
              @update:modelValue="pageLoad"
          />
        </div>
        <div v-if="!(cronjobList && cronjobList.length > 0)" class="app-empty-list flex justify-center items-center text-gray">{{ $t('cronjob.emptyList') }}</div>
      </div>
    </div>

    <cronjob-modal
      v-model="showCronJobModal"
      :cronjob="selectedCronJob"
      :total-pages="totalPages"
      :items-per-page="requestedItemsPerPage"
      @saved="handleSaved"
      @hide="selectedCronJob = null"
    />

    <delete-cronjob-modal
      v-model="showDeleteConfirmation"
      :cronjob="selectedCronJob"
      @deleted="handleDeleted"
    />
  </q-page>
</template>

<style lang="scss">
  .app-empty-list {
    min-height: 100px;
  }
</style>
