import client from '../helpers/authenticated-axios'

export const flowService = {
  /**
   *
   * @param page {Integer}
   * @param itemsPerPage {Integer}
   * @param cancelToken {CancelToken}
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getFlows(page, itemsPerPage, cancelToken) {
    return await client().get(
      '/flows?page=' + page + '&itemsPerPage=' + itemsPerPage,
      {
        cancelToken
      }
    );
  },

  getFlowDetails(flowId, callback) {
    client().get('/flows/' + flowId).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
  updateFlowConfig(data, callback) {
    client().put('/flows/' + data.flowId, {"config": data.config}).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
  startFlow(flowId, callback) {
    client().post('/flows/' + flowId + '/start', {}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  exportFlow(flowId, callback) {
    flowId = flowId ?? '';

    client().get('/flows/export/' + flowId).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  importFlow(flowText, importFile, callback) {
    if (importFile !== null) {
      const reader = new FileReader();
      reader.onload = (content) => {
        this.sendImportFlow(content.target.result, callback);
      };
      reader.readAsText(importFile);
    } else {
      this.sendImportFlow(flowText, callback);
    }
  },
  sendImportFlow(flows, callback) {
    if (typeof flows === 'string') {
      client().post('/flows/import', {"flows": flows}).then((response) => {
        callback(response.data);
      }).catch((error) => {
        callback(error.response);
      })
    }
  },
  createFlow(flowName, flowJson, callback) {
    if (typeof flowJson === 'string') {
      try {
        flowJson = JSON.parse(flowJson)
      } catch (e) {
        // Errors already get fetched by validator
        // If an error still occurs, then it means the field was empty (which was a validator exception).
        // Defaulting flowJSON to '{}' in this case
        flowJson = {};
      }
    }
    client().post('/flows', {"name": flowName, "config": flowJson}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  editFlow(flowName, flowId, callback) {
    client().put('/flows/' + flowId, {"name": flowName, "id": flowId}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  endFlow(lastFlowExecutionId, callback) {
    client().put('/flow_executions/' + lastFlowExecutionId, {"active": false}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  deleteFlow(flowId, callback) {
    client().delete('/flows/' + flowId).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  createJobDispatcherMapping(jdm, callback) {
    var { dispatchCondition, configuration, messageFqcn, processStepIdentifier, flowId } = jdm;
    dispatchCondition = dispatchCondition;
    configuration = configuration;
    let apiRequest = {flow: flowId, processStepIdentifier, messageFqcn, dispatchCondition, configuration};
    client().post('/job_dispatcher_mappings', apiRequest).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  updateJobDispatcherMapping(jdmId, configuration, dispatchCondition, callback) {
    /* @TODO abstract handling! */
    if (!isNaN(jdmId)) {
      jdmId = '/job_dispatcher_mappings/' + jdmId;
    } else if (jdmId.startsWith('/api')) {
      jdmId = jdmId.slice(4)
    }
    let apiRequest = {configuration, dispatchCondition};
    client().patch(jdmId, apiRequest, {headers: {'Content-Type': 'application/merge-patch+json'}}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  },
  deleteJobDispatcherMapping(jdmId, callback) {
    client().delete('/job_dispatcher_mappings/' + jdmId).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    })
  }
}
