import { render } from "./DeleteFlowModal.vue?vue&type=template&id=06bb9091"
import script from "./DeleteFlowModal.vue?vue&type=script&lang=js"
export * from "./DeleteFlowModal.vue?vue&type=script&lang=js"

import "./DeleteFlowModal.vue?vue&type=style&index=0&id=06bb9091&lang=scss"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QForm,QInput});
