import { render } from "./FlowExecutionDetail.vue?vue&type=template&id=deb40e8a"
import script from "./FlowExecutionDetail.vue?vue&type=script&lang=js"
export * from "./FlowExecutionDetail.vue?vue&type=script&lang=js"

import "./FlowExecutionDetail.vue?vue&type=style&index=0&id=deb40e8a&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn,QIcon,QTabs,QTab,QTabPanels,QTabPanel});
