import { render } from "./ValidationStats.vue?vue&type=template&id=015dfa8a"
import script from "./ValidationStats.vue?vue&type=script&lang=js"
export * from "./ValidationStats.vue?vue&type=script&lang=js"

import "./ValidationStats.vue?vue&type=style&index=0&id=015dfa8a&lang=scss"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection});
