<script>
  import { defineComponent, defineAsyncComponent, ref } from 'vue'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import EndFlowModal from '../../Flow/Overview/components/FlowModal/EndFlowModal'
  import FlowMeta from './components/FlowMeta.vue'
  import FlowExecutionTree from "./components/FlowExecutionTree";
  import {flowExecutionService} from "src/services";
  import {checkRunningFlowLoop, clearLooping} from "src/helpers/auto-refresh";

  export default defineComponent({
    name: 'FlowExecutionDetail',

    components: {
      EndFlowModal,
      FlowExecutionTree,
      LoadingIndicator,
      FlowMeta,
      Logs: defineAsyncComponent(() => import('components/Logs/Logs.vue'))
    },

    setup () {
      return {
        tab: ref('list')
      }
    },
    data () {
      return {
        showEndFlowModal: false,
        isLoading: true,
        currentFlow: null,
        animationFixVar: false,
        autoRefreshLooping: null,
        runningFlow: null
      }
    },
    methods: {
      updateFlowStatus(flowDetails) {
        this.currentFlow = flowDetails;
      },

      animationFix() {
        this.animationFixVar = true;
        setTimeout(() => {
          this.animationFixVar = false;
        }, 500);
      },

      getFlowDetails() {
        flowExecutionService.getFlowDetails(this.$route.params.flowid, (data) => {
          let foundRunningFlow = false;
          // Update page
          if (data.active === true) foundRunningFlow = true;
          else clearLooping(this.autoRefreshLooping);

          this.currentFlow = data;
          this.isLoading = false;

          this.runningFlow = foundRunningFlow;
          if(this.runningFlow) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.getFlowDetails());
        })
      },

      handleFlowEnded() {
        this.showEndFlowModal = false
        this.getFlowDetails()
      },
    },
    created() {
      this.getFlowDetails();
    },
    beforeUnmount() {
      clearLooping(this.autoRefreshLooping);
    }
  })
</script>

<template>
  <q-page class="block app-flow-execution-detail-page">
    <div class="app-container-wrapper">
      <div class="app-headline-container q-pa-md flex">
        <loading-indicator v-if="isLoading" wrapper />
        <template v-if="!isLoading">
          <div class="flex row">
            <h1>{{ currentFlow.flowName }} #{{ currentFlow.id }}</h1>
          </div>
          <flow-meta :current-flow="currentFlow" :is-loading="isLoading" />

          <div class="row items-center q-mt-md">
            <q-btn v-if="this.currentFlow.active"
              flat dense
              class="app-action-btn q-px-sm q-mr-sm"
              :label="$t('flow.overview.detail.end')"
              :disable="isLoading"
              @click="showEndFlowModal = true"
              v-bind:data-cy="'buttonQuickEndFlow' + this.currentFlow.id"
            ><q-icon name="do_disturb" class="q-ml-sm" /><!-- v-if="this.currentFlow.isRunning" -->
            </q-btn>

            <q-btn
              flat dense
              :to="'/flow/'+currentFlow.flowId+'/configuration'"
              class="app-jump-to-execution app-action-btn q-px-sm"
              :label="$t('flow.openFlow')"><q-icon name="img:assets/icons/link.svg" class="q-ml-sm" /><!-- v-if="this.currentFlow.isRunning" -->
            </q-btn>
          </div>
        </template>
      </div>
    </div>
    <div class="q-pa-md">

      <div class="q-gutter-y-md">
        <q-tabs
          v-model="tab"
          dense
          inline-label
          align="left"
          class="app-flex-execution-detail-tabs app-wrap-overwrite q-mr-lg q-mb-sm"
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
        >
          <q-tab name="list" icon="list" :label="$t('flow.executions.detail.tabs.list')" @click="animationFix" class="q-pl-none" />
          <q-tab name="logs" icon="article" :label="$t('flow.executions.detail.tabs.logs')" @click="animationFix" />
        </q-tabs>

        <q-tab-panels class="app-flow-execution-detail-panel"
                      :class="{'app-tab-transitioning': animationFixVar}"
                      v-model="tab" animated>

          <q-tab-panel name="list">
            <div class="q-py-md q-gutter-sm q-pt-none">
              <flow-execution-tree v-if="currentFlow" :current-flow="currentFlow" @update-flow-details="updateFlowStatus" />
            </div>
          </q-tab-panel>

          <q-tab-panel name="logs">
            <logs :flow-execution-filter="this?.currentFlow?.id.toString()" />
          </q-tab-panel>

        </q-tab-panels>

      </div>
    </div>

    <end-flow-modal
      v-model="showEndFlowModal"
      :flow-id="currentFlow?.id"
      :flow-name="currentFlow?.flowName"
      @success="handleFlowEnded"
    />
  </q-page>
</template>

<style lang="scss">
  .app-flow-execution-detail-panel, .app-flow-execution-detail-panel .q-panel.scroll {
    overflow: unset; // required for sticky action bar to work
  }
  .app-flow-execution-detail-panel.app-tab-transitioning.q-panel-parent {
    overflow: hidden; // to prevent elements flying visually out of the box
  }
</style>
