<script>
  import { defineComponent } from 'vue'
  import { useQuasar } from 'quasar';
  import LoadingIndicator from 'components/LoadingIndicator';
  import ItemsPerPage from 'components/ItemsPerPage';
  import Pagination from 'components/Pagination';
  import FailedMessageModal from 'pages/FailedMessages/components/FailedMessageModal';
  import DateDisplay from 'components/DateDisplay';

  export default defineComponent({
    name: 'FailedMessages',

    components: {
      LoadingIndicator,
      ItemsPerPage,
      Pagination,
      DateDisplay,
      FailedMessageModal
    },

    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q,
      }
    },

    data () {
      return {
        showFailedMessageInfo: false,
        selectedFailedMessage: null,
        failedMessages: null,
        loading: true,
        totalPages: 1,
        searchString: '',
        query: {
          page: 1,
          itemsPerPage: 10,
          flowExecutionId: null,
          message: null,
          "order[created_at]": "desc"
        },
        startIndex: 0,
        hideSearch: false,

        columns: [
          {
            name: 'fqcn',
            required: true,
            label: 'FQCN',
            align: 'left',
            field: row => row.fqcn,
            format: val => `${val}`
          },
          {
            name: 'flowExecutionId',
            required: true,
            label: this.getTranslation("flowExecutionId"),
            align: 'left',
            field: row => row.flowExecutionId,
            format: val => `${val}`
          },
          {
            name: 'message',
            required: true,
            label: this.getTranslation("message"),
            align: 'left',
            field: row => row.message,
            format: val => `${val}`
          },
          {
            name: 'createdAt',
            required: true,
            label: this.getTranslation("createdAt"),
            align: 'left',
            field: row => row.created_at,
            format: val => `${val}`
          }
        ]
      }
    },

    created() {
      this.getFailedMessages(false);
    },

    methods: {
      getTranslation(key) {
        return this.$t('failedMessages.label.' + key);
      },

      getFailedMessages(fromSearchInput) {
        this.loading = true;
        this.$api.failedMessages.list(this.query)
          .then(res => {
            if (res.data["hydra:member"].length > 0) {
              this.failedMessages = res.data["hydra:member"];
              this.totalPages =  Math.ceil(res.data["hydra:totalItems"] / this.query.itemsPerPage);
              let index = this.query.page - 1;
              index = index > 0 ? index : 0;
              this.startIndex = index * this.query.itemsPerPage;
              this.hideSearch = false
            }

            if (res.data["hydra:member"].length === 0) {
              this.failedMessages = null;
              this.hideSearch = !fromSearchInput;
            }

          })
          .catch(err => {
            console.log(err);
          })
          .finally(_ => {
            this.loading = false;
          })
      },

      viewMessageDetails(evt, failedMessage) {
        this.showFailedMessageInfo = true
        this.selectedFailedMessage = failedMessage
      },

      updatePage() {
        this.getFailedMessages()
      },

      search(searchInput) {
        this.query.flowExecutionId = searchInput;
        this.query.message = null;
        if (isNaN(searchInput)) {
          this.query.flowExecutionId = null;
          this.query.message = searchInput;
        }
        this.getFailedMessages(true);
      }
    },
  })
</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">

      <h1>{{ $t('failedMessages.headline') }}</h1>
      <p>{{ $t('failedMessages.desc') }}</p>

      <div v-if="!hideSearch" class="row justify-end q-mb-md">
        <q-input
          dense
          color="primary"
          v-model="searchString"
          :placeholder="$t('failedMessages.searchString')"
          style="width:15rem"
          @update:model-value="search"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>

      <loading-indicator v-if="loading" wrapper />
      <template v-if="failedMessages && !loading">
        <q-card flat>
        <q-card-section>
        <div class="row q-pa-sm">
          <q-table class="col-12 transparent" flat
                   :rows="failedMessages"
                   :columns="columns"
                   color="primary"
                   :hide-bottom=true
                   :hide-pagination=true
                   :loading="loading"
                   :rows-per-page-options="[query.itemsPerPage]"
                   @row-click="viewMessageDetails"
          >
            <template #body-cell-message="scope">
              <q-td class="font-500 ellipsis" :props="scope" style="max-width: 30vw;">
                {{ scope.row.message }}
              </q-td>
            </template>

            <template #body-cell-createdAt="scope">
              <q-td class="font-500 ellipsis" :props="scope">
                <date-display :time="scope.row.created_at" fixed="fixed" />
              </q-td>
            </template>
          </q-table>
        </div>
        </q-card-section>
        </q-card>

        <items-per-page
          v-model:modelValue="query.itemsPerPage"
          @update:modelValue="updatePage"
          label="failedMessages.itemsPerPage"
        />

        <pagination
          :pages="totalPages"
          v-model:modelValue="query.page"
          @update:modelValue="updatePage"
        />
      </template>

      <template v-if="!failedMessages && !loading">
        <div class="app-empty-list flex justify-center items-center text-gray">{{ $t('failedMessages.emptyList') }}</div>
      </template>
    </div>

    <failed-message-modal
      v-model="showFailedMessageInfo"
      :failed-message="selectedFailedMessage"
    />
  </q-page>
</template>

<style scoped>
  .app-empty-list {
    min-height: 100px;
  }
</style>
