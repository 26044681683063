import { render } from "./FileSystem.vue?vue&type=template&id=696c854e"
import script from "./FileSystem.vue?vue&type=script&lang=js"
export * from "./FileSystem.vue?vue&type=script&lang=js"

import "./FileSystem.vue?vue&type=style&index=0&id=696c854e&lang=scss"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTree});
