import { render } from "./LocalePicker.vue?vue&type=template&id=4b876dbf"
import script from "./LocalePicker.vue?vue&type=script&lang=js"
export * from "./LocalePicker.vue?vue&type=script&lang=js"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QItem,QItemSection,QItemLabel});
