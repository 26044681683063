export default function () {
  const initialState = {
    isLoading: true,
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    items: [],
    configuration: "{}"
  }

  return initialState
}
