<script>
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import { defineComponent, ref } from 'vue'
  import { useQuasar } from 'quasar'

  export default defineComponent({
    name: 'Login',
    components: {
      LoadingIndicator
    },
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q
      }
    },
    data () {
      return {
        username: '',
        submitted: false,
        password: ref(''),
        passwordConfirm: ref(''),
        isPwd: ref(true),
        isPwd2: ref(true)
      }
    },
    created () {
      // reset login status
      this.$store.dispatch('authentication/logout');
    },
    methods: {
      handleSubmit (e) {
        this.submitted = true;
        const { password, passwordConfirm } = this;
        const { dispatch } = this.$store;
        let userId = this.$route.params.userId
        let hash = this.$route.params.hash
        dispatch('authentication/recover', { password, passwordConfirm, userId, hash });
      }
    }
  })
</script>

<template>
  <q-page class="app-page-password-reset psw-res justify-center">
    <div>
      <form @submit.prevent="handleSubmit">
        <div class="q-pa-md">
          <h1>{{ $t('publicPages.passwordReset.headline') }}</h1>
          <p>{{ $t('publicPages.passwordReset.desc') }}</p>
        </div>
        <!---->
        <div class="q-pa-md">
          <div class="app-login-input-wrapper q-gutter">
            <q-input required
                     class="app-login-input-password q-pa-sm"
                     v-model="password"
                     aria-autocomplete="none"
                     :label="$t('publicPages.passwordReset.password')"
                     :title="$t('publicPages.passwordReset.passwordTitle')"
                     :type="isPwd ? 'password' : 'text'"
            >
              <template v-slot:prepend>
                <q-icon class="q-pl-md" name="lock" />
              </template>
              <template v-slot:append>
                <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                />
                <q-tooltip anchor="top middle" self="bottom middle" class="app-tooltip-mobile" v-if='$q.platform.is.mobile'>{{ isPwd ? $t('general.showPassword') : $t('general.hidePassword') }}</q-tooltip>
              </template>
            </q-input>
            <q-input required
                     class="app-login-input-password-confirm q-pa-sm"
                     v-model="passwordConfirm"
                     :label="$t('publicPages.passwordReset.passwordConfirm')"
                     :title="$t('publicPages.passwordReset.passwordConfirmTitle')"
                     :type="isPwd2 ? 'password' : 'text'"
            >
              <template v-slot:prepend>
                <q-icon class="q-pl-md" name="lock" />
              </template>
              <template v-slot:append>
                <q-icon
                        :name="isPwd2 ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd2 = !isPwd2"
                />
                <q-tooltip anchor="top middle" self="bottom middle" class="app-tooltip-mobile" v-if='$q.platform.is.mobile'>{{ isPwd2 ? $t('general.showPassword') : $t('general.hidePassword') }}</q-tooltip>
              </template>
            </q-input>
          </div>
        </div>
        <div class="q-mt-lg q-pa-md">
          <q-btn flat class="app-action-btn" color="primary" :label="$t('publicPages.passwordReset.submit')" type="submit" />
          <!--<loading-indicator v-show="loggingIn" />-->
        </div>
      </form>
    </div>
    <div class="q-pa-md gt-sm">
      <img alt="" src="~assets/app-teaser.svg" class="app-logo-decoration">
    </div>
    <!-- Uses free Font Awesome icons | https://fontawesome.com/license/free -->
  </q-page>
</template>

<style lang="scss">
  .app-login-input-wrapper {
    margin: -1.25rem;
  }
  .app-login-input-name, .app-login-input-password, .app-login-input-password-confirm {
    max-width: 400px;
  }
  /*.q-field__native, .q-field__label {
    padding: .5rem 1rem;
  }
  .app-page-password-reset {
    .q-field__control:before, .q-field__control:after {
  }*/
  .psw-res {
    .q-field__control:before,
    .q-field__control:after {
      left: 1rem;
    }
  }
</style>
