import { render } from "./UserManagement.vue?vue&type=template&id=14458a24"
import script from "./UserManagement.vue?vue&type=script&lang=js"
export * from "./UserManagement.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn,QCard,QCardSection,QTable,QTd});
