import { render } from "./JobDispatcherMappingModal.vue?vue&type=template&id=740aec2a&scoped=true"
import script from "./JobDispatcherMappingModal.vue?vue&type=script&lang=js"
export * from "./JobDispatcherMappingModal.vue?vue&type=script&lang=js"

import "./JobDispatcherMappingModal.vue?vue&type=style&index=0&id=740aec2a&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-740aec2a"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCardSection,QTabs,QTab,QTabPanels,QTabPanel,QBtn});qInstall(script, 'directives', {ClosePopup});
