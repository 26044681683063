import { render } from "./EntityListing.vue?vue&type=template&id=339dc797"
import script from "./EntityListing.vue?vue&type=script&lang=js"
export * from "./EntityListing.vue?vue&type=script&lang=js"

import "./EntityListing.vue?vue&type=style&index=0&id=339dc797&lang=scss"
script.render = render

export default script
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QSeparator,QIcon,QPagination,QBtn});
