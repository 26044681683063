<script>
  import { defineComponent, ref } from 'vue'
  import { useQuasar } from 'quasar';
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import DateDisplay from 'components/DateDisplay.vue'
  import {flowExecutionService} from "src/services";
  import FlowExecutionActionBar from "./FlowExecutionActionBar";
  import FlowExecutionBadges from "./FlowExecutionBadges.vue";

  export default defineComponent({
    name: 'FlowExecutionTree',
    props: {
      currentFlow: {
        type: Object,
        required: true
      },
    },
    emits: ['updateFlowDetails'],
    components: {
      FlowExecutionBadges,
      FlowExecutionActionBar,
      LoadingIndicator,
      DateDisplay
    },
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q,
        tab: ref('list')
      }
    },
    data () {
      return {
        searchBox: '',
        filter: 'true',
        statusFilter: '',
        expansionStateInitialized: false,
        expandedTreeNodes: ref([]), // init
        expanded: true,
        hideCompleted: ref(false),
        hidePending: ref(false),
        refreshModel: ref(false),
        isLoading: true,
        tree: null,
        calcHeaderProgressDifferently: true
      }
    },
    methods: {
      getNodeProgressBySum(node) {
        let processedCounts = 0;
        let totalCounts = 0;
        node.children.forEach(child => {
          if(child.processedCount) processedCounts += child.processedCount;
          if(child.totalCount) totalCounts += child.totalCount;
        });
        const result = (Math.floor(((processedCounts * 100) / totalCounts) * 100).toFixed(2) / 100).toString()
        if(isNaN(result)) {
          return this.getNodeProgress(node.processedCount, node.totalCount);
        }
        return result;
      },
      getNodeProgress(processedCounts, totalCounts) {
        if(typeof processedCounts === "undefined" || processedCounts == null) return null;
        if(typeof totalCounts === "undefined" || totalCounts == null) return null;
        if(totalCounts === 0) return "100";
        return (Math.floor(((processedCounts * 100) / totalCounts) * 100).toFixed(2) / 100).toString();
      },
      getNodeItems(processedCounts, totalCounts) { // Handle fallback in case of missing variables
        let pC = processedCounts;
        let tC = totalCounts;
        if(typeof pC === "undefined" || pC == null) pC = '-';
        if(typeof tC === "undefined" || tC == null) tC = '-';
        return pC.toString() + ' / ' + tC.toString();
      },
      getBarProgress(processedCounts, totalCounts) {
        if(this.getNodeProgress(processedCounts, totalCounts) === null) return 0;
        return this.getNodeProgress(processedCounts, totalCounts);
      },
      refreshArray(tree) {
        let expandedTreeNodes = [];
        let treeExpandIteration = function(node) {
          node.forEach(branch => {
            /* uniqueLabel -> not part of API. Generated in Store mutation. Equals: branch.id + '-' + branch.processStepSubsection */
            expandedTreeNodes.push(branch.uniqueLabel);
            if(typeof branch.children !== "undefined") {
              branch.children.forEach(child => {
                expandedTreeNodes.push(child.uniqueLabel);
                // Fixed to two levels for now
              })
            }
          });
        };
        treeExpandIteration(tree);
        this.expandedTreeNodes = ref(expandedTreeNodes);
        this.expansionStateInitialized = true;
      },
      refreshTree() {
        this.isLoading = true;
        flowExecutionService.getFlowDetails(this.$route.params.flowid, (data) => {
          this.isLoading = false;
          this.updateTree(data);
        })
      },
      toggleAutoRefresh() {
        this.autoRefresh = !this.autoRefresh;
      },
      updateFilter(filter) {
        this.searchBox = this.filter = filter;
      },
      hidePendingFctn() {
        this.hidePending = !this.hidePending;
        this.statusFilter = this.hidePending;
      },
      hideCompletedFctn() {
        this.hideCompleted = !this.hideCompleted;
        this.statusFilter = this.hideCompleted;
      },
      expandOrCollapseAll() {
        if(this.expanded) {
          this.expandedTreeNodes = [];
        } else {
          let expandedTreeNodes = [];
          let treeExpandIteration = function(node) {
            node.forEach(branch => {
              /* uniqueLabel -> not part of API. Generated in Store mutation. Equals: branch.id + '-' + branch.processStepSubsection */
              expandedTreeNodes.push(branch.uniqueLabel);
              branch.children.forEach(child => {
                expandedTreeNodes.push(child.uniqueLabel);
                if(child.children) treeExpandIteration(child.children); //recursion for all further child levels
              })
            });
          };
          treeExpandIteration(this.tree);
          this.expandedTreeNodes = ref(expandedTreeNodes)
        }
        this.expanded = !this.expanded;
      },
      updateTree(data) {
        let treeArray = data.flowExecutionProgressInformation;
        let listToTree = function(list) {
          var map = {}, node, roots = [], i;

          for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // init
            list[i].children = []; // init
          }

          for (i = 0; i < list.length; i += 1) {
            node = list[i];
            node.uniqueLabel = node['id'] + '-' + node['processStepSubsection']; // required for expansion array
            if (node.parentId !== null && node.parentId !== 0) {
              // if there are dangling branches, check that map[node.parentId] exists
              const branch = list[map[node.parentId]];
              if (typeof branch !== "undefined" && typeof branch.children !== "undefined") branch.children.push(node);
            } else {
              roots.push(node);
            }
          }
          return roots;
        };
        this.tree = listToTree(treeArray);
        this.$emit('updateFlowDetails', data)

        this.isLoading = false;
        if(this.expansionStateInitialized === false) this.refreshArray(this.tree)
      },
      treeFilter (node, filter) {
        // The filter param is ignored in here.

        // Instead, we use the contents of the search box input ...
        const searchBoxText = this.searchBox.toLowerCase()

        const matchedNodeLabel = (node.processStepSubsection && node.processStepSubsection.toLowerCase().indexOf(searchBoxText) > -1)

        // And any status button that was clicked ....
        let matchedStatus = true;

        if (this.statusFilter !== '') {
          if(this.hidePending) {
            matchedStatus = !(
                (parseInt(this.getNodeProgress(node.processedCount, node.totalCount)) < 100) ||
                node.processedCount < node.totalCount ||
                this.getNodeItems(node.processedCount, node.totalCount) === null
            );
          }
          if(this.hideCompleted) {
            matchedStatus = matchedStatus && !(parseInt(this.getNodeProgress(node.processedCount, node.totalCount)) === 100);
          }
        }

        return (matchedNodeLabel && matchedStatus)
      },
      toggleProgressDisplay(val) {
        this.calcHeaderProgressDifferently = val;
      }
    },
    watch: {
      // Whenever the searchBox property changes,
      // use it to trigger the tree filter's reactivity
      // by assigning its value to the
      // filter property.
      // Actually, the value we assign here is irrelevant.
      searchBox: function (newVal, oldVal) {
        this.filter = newVal;
      }
    },
    /*mounted() {
      flowExecutionService.getFlowDetails(this.$route.params.flowid, (data) => {
        this.updateTree(data);
      })
    },*/
    created() {
      this.updateTree(this.$props.currentFlow);
    }
  })
</script>

<template>
  <flow-execution-action-bar v-if="tree" :tree-length="tree.length"
                             @update-filter="updateFilter"
                             @update-pending="hidePendingFctn"
                             @update-completed="hideCompletedFctn"
                             @refresh="refreshTree"
                             @expand="expandOrCollapseAll"
                             @update-progress-display="toggleProgressDisplay"
  />

  <loading-indicator v-if="(tree === null && isLoading)" wrapper />
  <q-tree v-if="!(tree === null && isLoading)"
          ref="projectTree"
          :nodes="tree"
          @load="expansionStateInitialized === false ? refreshArray(tree) : null"
          node-key="uniqueLabel"
          label-key="processStepSubsection"
          :filter="filter"
          :filter-method="treeFilter"
          class="flex justify-content-center column q-my-lg"
          :class="{ 'disabled': isLoading, 'hide-pending': this.hidePending === true, 'hide-completed': this.hideCompleted === true }"
          default-expand-all
          v-model:expanded="expandedTreeNodes"
  >
    <template v-slot:default-header="prop">
      <div class="app-flowexecution-top-label-wrapper" :title="prop.node.processStepSubsection">
        {{ prop.node.processStepSubsection }}

        <q-tooltip
          anchor="top middle" self="bottom middle"
          v-if='$q.platform.is.mobile'
          class="app-tooltip-mobile fixed-tooltip"
        >
          {{ prop.node.processStepSubsection }}

          <flow-execution-badges :prop="prop" :get-node-progress="getNodeProgress" :get-node-items="getNodeItems" :calc-header-progress-differently="calcHeaderProgressDifferently" />
        </q-tooltip>

        <flow-execution-badges :prop="prop" :get-node-progress="getNodeProgress" :get-node-items="getNodeItems" :calc-header-progress-differently="calcHeaderProgressDifferently" />

      </div>
      <div class="app-flowexecution-wrapper flex justify-center full-width"
           :class="{
           'q-mt-md': !prop.node.parentId
           }"
           style="height: .25rem;" :style="{
             backgroundImage: 'linear-gradient(90deg, #2B00B0, #9B60ff' + ' ' + getBarProgress(prop.node.processedCount, prop.node.totalCount) + '%, transparent 0)'
           }"
      >
      </div>
    </template>
  </q-tree>
  <div class="app-all-filtered-info hidden">
    {{ $t('flow.executions.detail.allFiltered') }}
  </div>
</template>

<style lang="scss">
  .q-tree {
    margin-top: 1rem;
    .q-tree__node {
      .q-tree__node--parent, .q-tree__node--child {
        padding-top: .25rem;
        &:first-of-type {
          padding-top: .75rem;
        }
        .q-tree__node-header {
          margin: unset;
          padding: 0.25rem 0;
          &:hover {
            background-color: darken($background2, 10%);
          }
        }
      }
      .app-flowexecution-top-label-wrapper {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
      }
      .q-tree__node-header-content {
        .app-flowexecution-top-label-wrapper {
          position: absolute;
          top: -.75rem;
        }
        .app-flowececution-wrapper {
          background-color: darken($background2, 10%);
          background-repeat: no-repeat;
        }
      }
    }
  }
  .q-tree > .q-tree__node > .q-tree__node-header .app-flowexecution-top-label-wrapper {
    top: 0;
  }
  .q-tree .q-tree__node .q-tree__node-header-content {
    margin-top: .25rem;
  }
  .q-tree__node {
    max-width: 1080px;
  }
  .q-tree__node {
    &:last-child {
      .q-tree__node-header::before {
        top: -2.25rem;
        bottom: .75rem;
        @media (min-width: $breakpoint-xs) {
          top: -1.5rem;
        }
      }
    }
  }
  .q-tree__children {
    width: 100%;
    padding-left: 8px;
    @media (min-width: $breakpoint-xs) {
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
      min-width: 480px; /* Might require extra adjustment for deep levels */
    }
  }
  .q-tree .q-badge {
    margin: .25rem .25rem;
    padding: 0 .25rem;
    max-height: 1rem;
    @media (min-width: $breakpoint-xs) {
      margin: unset;
    }
  }
  .app-flow-execution-refresh-btn {
    button:first-of-type {
      padding-right: .5rem;
    }
    .q-btn__content {
      font-weight: 600;
    }
    &.loading button:first-of-type i {
      animation: running-animation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      @keyframes running-animation {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(90deg);
        }
        50% {
          transform: rotate(180deg);
        }
        75% {
          transform: rotate(270deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .app-flex-execution-detail-tabs .q-tab {
    max-width: 552px;
  }

  .app-all-filtered-info {
    color: $gray;
  }

  .q-page-container .q-page .q-tab-panels.app-flow-execution-detail-panel {
    background-color: $background2;
    border-radius: 4px;
  }

  .app-tree-action-bar .q-icon {
    display: inline-block;
  }

  .app-flowexecution-wrapper {
    background-color: darken($background2, 10%);
  }

  .fixed-tooltip {
    // Overwrite quasar default behaviour of tooltips
    position: fixed;
    top: unset !important;
    bottom: 0;
    left: 0 !important;
    width: 100%;
  }

  body.body--dark {
    .q-tree {
      .q-tree__node {
        .q-tree__node--parent, .q-tree__node--child {
          .q-tree__node-header {
            &:hover {
              background-color: darken($dark, 10%);
            }
          }
        }
      }
    }
    .q-page-container .q-page .q-tab-panels.app-flow-execution-detail-panel {
      background-color: $dark;
    }
  }
</style>
