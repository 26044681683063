import { render } from "./Error.vue?vue&type=template&id=0497d3bc"
import script from "./Error.vue?vue&type=script&lang=js"
export * from "./Error.vue?vue&type=script&lang=js"

import "./Error.vue?vue&type=style&index=0&id=0497d3bc&lang=scss"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
